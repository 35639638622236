import { memo } from 'react'

import FormCheckBoxField, { TEST_ID } from './FormCheckBoxField'
import type { Props as FormCheckBoxFieldProps } from './FormCheckBoxField'

const FormCheckBoxFieldComponent = memo(FormCheckBoxField)

export { TEST_ID, FormCheckBoxFieldProps }

export default FormCheckBoxFieldComponent
