import React from 'react'

export interface Props {
	size?: number
}

const IconImage = (props: Props) => {
	return (
		<svg
			width={props.size || 48}
			height={props.size || 48}
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M38.04 21.98V36C38.04 37.1 37.14 38 36.04 38H12C10.9 38 10 37.1 10 36V12C10 10.9 10.9 10 12 10H26C27.1 10 28 9.1 28 8C28 6.9 27.1 6 26 6H10.04C7.84004 6 6.04004 7.8 6.04004 10V38C6.04004 40.2 7.84004 42 10.04 42H38C40.2 42 42 40.22 42 38V21.98C42 20.88 41.12 20 40.02 20C38.92 20 38.04 20.88 38.04 21.98ZM26.5 21.5L24.92 18C24.56 17.22 23.46 17.22 23.1 18L21.52 21.5L18 23.08C17.22 23.44 17.22 24.54 18 24.9L21.5 26.48L23.08 30C23.44 30.78 24.54 30.78 24.9 30L26.48 26.5L30 24.92C30.78 24.56 30.78 23.46 30 23.1L26.5 21.5ZM35.88 12.12L34.68 9.48C34.42 8.9 33.58 8.9 33.3 9.48L32.1 12.12L29.46 13.32C28.88 13.58 28.88 14.42 29.46 14.7L32.1 15.9L33.3 18.54C33.56 19.12 34.4 19.12 34.68 18.54L35.88 15.9L38.52 14.7C39.1 14.44 39.1 13.6 38.52 13.32L35.88 12.12Z"
				fill="#45657B"
			/>
		</svg>
	)
}
export default IconImage
