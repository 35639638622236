import React from 'react'

import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material'

import { Idea } from 'models/ideaModels'

import { dialogStyles, styles } from './PrototypeSubmittedIdeaModal.styles'

// TODO: this file needs to be re-written in the ModalProvider framework
// right now, it's operating on its own so it needs to be included in every page
// you want this rendered

export interface Props {
	handleClose: () => void
	idea: Idea
	open: boolean
}

export function stripHtmlTagsAndRetainNewlines(html: string): string {
	// Replace <br> tags with newline characters
	html = html.replace(/<br\s*\/?>/gi, '\n')

	// Replace block-level tags with newline characters
	html = html.replace(
		/<\/?(p|div|h[1-6]|li|ol|ul|blockquote|pre|table|tr|td|th)\b[^>]*>/gi,
		'\n'
	)

	// Create a temporary DOM element to use the browser's HTML parsing capability
	const div = document.createElement('div')
	div.innerHTML = html

	// Extract the text content from the temporary element
	const textContent = div.textContent || ''
	const innerText = div.innerText || ''
	let text = textContent || innerText

	// Decode HTML entities
	const textarea = document.createElement('textarea')
	textarea.innerHTML = text
	text = textarea.value

	// Normalize consecutive newlines (remove consecutive newlines except for 2)
	text = text.replace(/\n{3,}/g, '\n\n')

	// Trim leading and trailing whitespace/newlines
	text = text.trim()

	return text
}

const PrototypeSubmittedIdeaModal: React.FC<Props> = ({
	idea,
	open,
	handleClose,
}) => {
	const handlePaperClick = (event: React.MouseEvent) => {
		event.stopPropagation()
	}

	const handleLearnMoreClick = () => {
		window.open('https://go.slalom.com/arc', '_blank')
	}

	const handleRequestPocClick = () => {
		const subject = encodeURIComponent('POC Inquiry')
		const body = encodeURIComponent(
			`Hello, I’m interested in testing the following ideas: \n${stripHtmlTagsAndRetainNewlines(idea.authorIdeation)}`
		)
		window.open(
			`mailto:${process.env.REACT_APP_ARC_LABS_EMAIL as string}?subject=${subject}&body=${body}`,
			'_blank'
		)
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				'& .MuiBackdrop-root': {
					backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent backdrop
				},
			}}
			PaperProps={{
				style: {
					maxWidth: '1000px',
					backgroundColor: 'transparent', // Make the Paper background transparent
					boxShadow: 'none', // Remove the default shadow
				},
				onClick: handlePaperClick,
			}}
		>
			<Box sx={dialogStyles}>
				<DialogTitle sx={styles.title}>
					Request a Prototype to Test Your Ideas
				</DialogTitle>
				<DialogContent sx={styles.contentBox}>
					<Box sx={styles.paragraph}>
						<Typography sx={styles.typography}>
							GenAI can hypothesize on if an idea could work.
						</Typography>
						<Typography
							sx={{
								...styles.typography,
								marginTop: '32px',
								marginBottom: '32px',
							}}
						>
							Slalom’s ARC Labs can drive confidence on if your
							ideas actually work.
						</Typography>
						<Typography
							sx={{ ...styles.typography, marginBottom: '5px' }}
						>
							ARC builds real-world experiments to test your ideas
							around the following metrics, based on your unique
							stakeholders and constraints:
						</Typography>

						<List
							sx={{
								pl: '10px',
								pt: '0',
								pr: '0',
								pb: '0',
								margin: '-4px 0px 0px 0px',
							}}
						>
							<ListItem sx={styles.paragraph.flexItem}>
								<Box
									component="span"
									sx={{
										color: '#274A62',
										fontSize: '1.2em',
										marginRight: '8px',
										lineHeight: '1',
									}}
								>
									•
								</Box>
								<ListItemText
									primary={
										<>
											<Typography
												component="span"
												style={styles.boldText}
											>
												Feasibility{' '}
											</Typography>
											<Typography
												component="span"
												sx={styles.typography}
											>
												- is it possible?
											</Typography>
										</>
									}
									sx={{
										display: 'inline',
										lineHeight: '1.5',
									}}
								/>
							</ListItem>
							<ListItem sx={styles.paragraph.flexItem}>
								<Box
									component="span"
									sx={{
										color: '#274A62',
										fontSize: '1.2em',
										marginRight: '8px',
										lineHeight: '1',
									}}
								>
									•
								</Box>
								<ListItemText
									primary={
										<>
											<Typography
												component="span"
												style={styles.boldText}
											>
												Viability{' '}
											</Typography>
											<Typography
												component="span"
												sx={styles.typography}
											>
												- is it profitable?
											</Typography>
										</>
									}
									sx={{
										display: 'inline',
										lineHeight: '1.5',
									}}
								/>
							</ListItem>
							<ListItem sx={styles.paragraph.flexItem}>
								<Box
									component="span"
									sx={{
										color: '#274A62',
										fontSize: '1.2em',
										marginRight: '8px',
										lineHeight: '1',
									}}
								>
									•
								</Box>
								<ListItemText
									primary={
										<>
											<Typography
												component="span"
												style={styles.boldText}
											>
												Desirability{' '}
											</Typography>
											<Typography
												component="span"
												sx={styles.typography}
											>
												- is it preferable?
											</Typography>
										</>
									}
									sx={{
										display: 'inline',
										lineHeight: '1.5',
									}}
								/>
							</ListItem>
						</List>
					</Box>
				</DialogContent>
				<DialogContent sx={styles.titleBox}>
					<Typography
						variant="body1"
						paragraph
						sx={styles.partnerTitle}
					>
						Partner with Slalom’s ARC Labs to expedite validating
						your ideas.
					</Typography>
					<Typography
						variant="body1"
						paragraph
						sx={styles.experimentTitle}
					>
						1 month of live experimentation = confidence on if your
						idea is worth scaling.
					</Typography>
					<Checkbox component="span" />
					<Typography
						component="span"
						variant="body1"
						paragraph
						sx={styles.experimentTitle}
					>
						Share with Slalom the ideas I&#39;m interested in them
						testing
					</Typography>
				</DialogContent>
				<DialogActions sx={styles.actionBox}>
					<Button
						variant="contained"
						style={styles.primaryButton}
						onClick={handleRequestPocClick}
					>
						CONNECT WITH SLALOM
					</Button>
					<Button
						variant="outlined"
						style={styles.secondaryButton}
						onClick={handleLearnMoreClick}
					>
						LEARN MORE ABOUT ARC LABS
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	)
}

export default PrototypeSubmittedIdeaModal
