export const styles = {
	ActionLink: {
		':hover': { backgroundColor: 'transparent' },
		color: 'secondary.main',
		flexGrow: 1,
		flexShrink: 0,
		fontWeight: 700,
		padding: 0,
		fontSize: { xs: '1.2rem', lg: '1.4rem' },
	},
	CallToActionText: {
		lineHeight: '133%',
		margin: 0,
		marginRight: '2rem',
		fontSize: {
			lg: '1.4rem',
			xs: '1.2rem',
		},
	},
	CenterFooterDesktopGroup: {
		CallToActionContainer: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginLeft: 'auto',
			marginRight: 'auto',
			maxWidth: '45rem',
		},
		alignItems: 'center',
		display: 'flex',
	},
	CenterFooterMobileGroup: {
		CallToActionContainer: {
			' > *': {
				margin: '.33rem 0',
			},
			alignItems: 'center',
			textAlign: 'left',
		},
		alignItems: 'center',
		display: 'flex',
		maxWidth: '24rem',
		paddingLeft: '3rem',
	},
	CopyrightDesktopContainer: {
		alignItems: 'start',
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: '3rem',
	},
	CopyrightMobileContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		fontSize: '2px',
	},
	FooterContainer: {
		alignItems: 'center',
		backgroundColor: 'background.paper',
		color: 'text.secondary',
		justifyContent: 'space-between',
		paddingBottom: '2rem',
		paddingTop: '2rem',
		position: 'relative',
		zIndex: 2,
	},
	LogoImages: {
		marginLeft: '1rem',
		flexDirection: 'column',
	},
	LogosDesktopContainer: {
		MobileCopyrightText: { display: 'none' },
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'end',
		paddingRight: '3rem',
		flexDirection: 'row',
	},
	LogosMobileContainer: {
		' > *': {
			margin: '.5rem 0',
		},
		MobileCopyrightText: { display: 'none' },
		alignItems: 'center',
		justifyContent: 'end',
		paddingRight: '3rem',
		textAlign: 'center',
		flexDirection: 'column',
	},
	responsiveTextSize: {
		fontSize: {
			lg: '1.4rem',
			xs: '1.2rem',
		},
	},
	platformTypography: {
		fontSize: { xs: '1.2rem', lg: '1.4rem' },
	},
}
