import { IStyles } from 'styles/common.styles'

const styles: IStyles = {
	editorBox: {
		position: 'relative',
		width: '100%',
		minHeight: '15rem',
	},
	editorBoxFull: {
		position: 'relative',
		width: '100%',
		height: '100%',
	},
	menu: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '40px',
		margin: '0',
		padding: '0',
		background: '#F2E6E3',
		borderLeft: '1.5px solid #cf4f27',
		borderRight: '1.5px solid #cf4f27',
		borderTop: '1.5px solid #cf4f27',
		zIndex: 1,
	},
	linkModal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px transparent',
		borderRadius: '4px',
		boxShadow: 24,
		p: 4,
	},
}

styles.menuFocused = {
	...styles.menu,
	borderLeft: '2.5px solid #cf4f27',
	borderRight: '2.5px solid #cf4f27',
	borderTop: '2.5px solid #cf4f27',
}

export { styles }
