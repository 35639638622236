import React from 'react'

import { ThemeProvider, createTheme } from '@mui/material'

import { components } from './components'
import { palette } from './palette'
import { typography } from './typography'

declare module '@mui/material/styles' {
	interface Palette {
		disabledColor: Palette['primary']
		errorDisabledColor: Palette['primary']
	}

	interface PaletteOptions {
		disabledColor?: PaletteOptions['primary']
		errorDisabledColor?: PaletteOptions['primary']
	}
}

const ideationTheme = createTheme({
	components,
	palette,
	typography,
})

interface demo {
	children: JSX.Element
}
// App 'font-size' is scaled to 62.5% to allow for easy conversion of 10px === 1rem.  Actual font size default remains 16px for accessibility concerns.
const IdeationThemeProvider: React.FC<demo> = ({ children }) => {
	return <ThemeProvider theme={ideationTheme}>{children}</ThemeProvider>
}

export { IdeationThemeProvider }
