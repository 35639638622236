import React from 'react'

import { Typography } from '@mui/material'
import { Box } from '@mui/system'

import RectangleButtonComponent from 'components/RectangleButton'

import { cta } from 'assets/callToActionParams'
import ohNo from 'assets/images/ohNo.svg'
import { HOME_PAGE } from 'assets/routes'

import { styles } from './ExpiredPage.styles'

function ExpiredPage() {
	return (
		<Box sx={styles.mainBox} data-testid="expired__page">
			<Box
				data-testid="oh__no__image"
				component="img"
				src={ohNo as unknown as string}
			/>
			<Typography sx={styles.deletedText}>
				This game was deleted by the game admin
			</Typography>
			<Box sx={styles.btnContainer}>
				<RectangleButtonComponent
					data-testid="return__home__button"
					href={HOME_PAGE}
					text={'return home'}
				/>

				<RectangleButtonComponent
					data-testid="expired__page__request-button"
					href={`mailto:arclabs@slalom.com?${Object.entries(cta)
						.map(([k, v]) => `${k}=${v}`)
						.join('&')}`}
					target="_blank"
					text={'request help'}
				/>
			</Box>
			<Typography sx={styles.quoteText}>
				<i>&quot;Progress is not linear.&quot;&nbsp;</i> - Maria
				Montessori
			</Typography>
		</Box>
	)
}

export default ExpiredPage
