import React from 'react'

import {
	Button,
	ButtonProps,
	CircularProgress,
	Typography,
} from '@mui/material'

import { useMixpanelContext } from 'contexts/MixpanelProvider'

import { MIXPANEL_EVENTS } from 'enums/MixpanelEvents'

import { styles } from './SubmiIdeasButton.styles'

export const TEST_ID = 'submit-ideas-button'

interface SubmitIdeasButtonProps extends ButtonProps {
	'data-testid'?: string
	gameTypeId: number
	handleSubmit: VoidFunction
	isCustomGame: boolean
	isSubmitButtonLoading: boolean
}

const SubmitIdeasButton = ({
	isSubmitButtonLoading,
	isCustomGame,
	gameTypeId,
	handleSubmit,
	'data-testid': dataTestId,
	...buttonProps
}: SubmitIdeasButtonProps) => {
	const mixpanel = useMixpanelContext()

	const handleClick = () => {
		mixpanel?.track(MIXPANEL_EVENTS.SUBMIT_IDEAS, {
			isCustomGame,
			gameTypeId,
		})

		handleSubmit()
	}
	return (
		<Button
			{...buttonProps}
			onClick={handleClick}
			data-testid={dataTestId || TEST_ID}
		>
			{isSubmitButtonLoading ? (
				<CircularProgress sx={styles.circularProgressIcon} />
			) : (
				<Typography sx={{ textTransform: 'capitalize' }}>
					Submit Your Ideas
				</Typography>
			)}
		</Button>
	)
}

export default SubmitIdeasButton
