import { Game } from 'models/gameModels'

import { GameType } from 'enums/GameTypeEnum'

import BreadcrumbMenuV2 from './v2'

interface BreadcrumbProps {
	game?: Game
	gameDescription?: string
	gameTypeEnum?: GameType
}
const BreadcrumbMenu = ({ game, gameTypeEnum }: BreadcrumbProps) => {
	return <BreadcrumbMenuV2 game={game} gameTypeEnum={gameTypeEnum} />
}

export default BreadcrumbMenu
