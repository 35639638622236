import { Typography } from '@mui/material'
import { Box } from '@mui/system'

import { styles } from './SubPageHeader.styles'

export interface Props {
	backgroundGradient: string
	subtitle: string
	title: string
}

export default function SubPageHeader({
	backgroundGradient,
	title,
	subtitle,
}: Props) {
	return (
		<Box sx={{ ...styles.container, background: backgroundGradient }}>
			<Typography sx={styles.title}>{title}</Typography>
			<Typography sx={styles.subtitle}>{subtitle}</Typography>
		</Box>
	)
}
