export const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1.6rem',
		padding: {
			lg: '3.2rem 9.6rem',
			md: '3.2rem 6.4rem',
			sm: '3.2rem',
			xs: '3.2rem',
		},
		color: 'black',
	},
	title: { fontSize: '6rem', fontWeight: '300' },
	subtitle: { fontSize: { lg: '2rem', sm: '1.6rem' } },
}
