export const styles = {
	ProgressContainer: {
		display: 'flex',
		width: '100%',
		height: '40vh',
		justifyContent: 'center',
		alignItems: 'center',
	},
	CircularProgressIcon: {
		color: 'secondary.main',
		size: '2.8rem',
	},
	Divider: {
		color: 'secondary.main',
		mx: 4,
	},
	Divider2: {
		color: 'primary.main',
		mx: { lg: 40, md: '3.2rem', sm: '3.2rem', xs: '3.2rem' },
		borderBotttomWidth: 'thick',
	},
}
