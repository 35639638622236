import { Typography } from '@mui/material'

export type IntroTextProps = {
	text: string
}

export default function IntroText({ text }: IntroTextProps) {
	return (
		<Typography variant="body1" sx={{ fontSize: '12px' }}>
			{text}
		</Typography>
	)
}
