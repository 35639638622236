import { memo } from 'react'

import FormTextField, { TEST_ID } from './FormTextField'
import type { Props as FormTextFieldProps } from './FormTextField'

const FormTextFieldComponent = memo(FormTextField)

export { TEST_ID, FormTextFieldProps }

export default FormTextFieldComponent
