import { memo } from 'react'

import BaseDateTimePicker, {
	DEFAULT_INPUT_FORMAT,
	TEST_ID,
} from './BaseDateTimePicker'
import type { Props as BaseDateTimePickerProps } from './BaseDateTimePicker'

const BaseDateTimePickerComponent = memo(BaseDateTimePicker)

export { TEST_ID, DEFAULT_INPUT_FORMAT, BaseDateTimePickerProps }

export default BaseDateTimePickerComponent
