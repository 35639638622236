import React, { ReactNode } from 'react'

import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'

import { NumberedCircleIcon } from 'assets/images/NumberedCircle'

import { styles } from './SectionHeader.styles'

export interface Props {
	children: ReactNode
	number: string
	title: string
}

const SectionHeader = ({ children, number, title }: Props) => {
	return (
		<Box data-testid="section-header" sx={styles.sectionHeaders}>
			<Box sx={styles.sectionHeader}>
				<Box sx={styles.sectionHeaderContent}>
					<NumberedCircleIcon number={number} />
					<Typography variant="h5">{title}</Typography>
				</Box>
				<Typography sx={styles.sectionText} variant="body1">
					{children}
				</Typography>
			</Box>
		</Box>
	)
}

export default SectionHeader
