export const styles = {
	filterGrid: {
		pb: 2,
		pt: 4,
		width: '100%',
	},
	filterSection: {
		gap: '1rem',
		width: 'auto',
	},
	filterText: {
		alignSelf: 'center',
		color: 'secondary.main',
		fontSize: 18,
		ml: 4,
		textAlign: 'right',
	},
	searchBarStyle: {
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'secondary.main',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: 'secondary.main',
		},
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: 'secondary.main',
		},

		backgroundColor: 'background.paper',
		borderRadius: 20,
		height: 39,
		width: 313,
	},
	searchBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		width: '100%',
	},
}
