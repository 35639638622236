import React from 'react'

import FavoriteFilledIcon from '@mui/icons-material/Favorite'

export const FavoriteIconGradient = ({ ...props }) => {
	return (
		<>
			<svg width={0} height={0}>
				<linearGradient
					id="favoriteGradient"
					x1={1}
					y1={0}
					x2={1}
					y2={1}
				>
					<stop offset={0} stopColor="#FF6262" />
					<stop offset={1} stopColor="#E90000" />
				</linearGradient>
			</svg>
			<FavoriteFilledIcon
				{...props}
				sx={{ fill: 'url(#favoriteGradient)' }}
			/>
		</>
	)
}
