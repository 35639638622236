import React from 'react'

import { Game } from 'models/gameModels'

import TeeterTotterV2 from './v2'

function TeeterTotter({ game }: { game?: Game }) {
	return <TeeterTotterV2 game={game} />
}

export default TeeterTotter
