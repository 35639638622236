import React from 'react'

import { Box, Popper } from '@mui/material'
import type { PopperProps } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import blueInfoIcon from 'assets/images/infoIcon.svg'

import { styles } from './InfoPopper.styles'
import BulletedList from './components/BulletedList'
import IntroText from './components/IntroText'
import PopperHeader from './components/PopperHeader'

type BulletItem = {
	details: string
	title: string
}
type PopperContent = {
	bulletItems: BulletItem[]
	header: string
	introText: string
}

export type Props = {
	handleClick: (event: React.MouseEvent<HTMLElement>) => void
	id?: string
	popperContent: PopperContent
} & Pick<PopperProps, 'anchorEl'>

const InfoPopper = ({
	handleClick,
	anchorEl,
	id = 'leap-frog-info-popper',
	popperContent,
}: Props) => {
	const open = Boolean(anchorEl)

	const popperId = open ? id : undefined

	return (
		<>
			<IconButton
				aria-describedby={popperId}
				data-testid="popper-icon-button"
				onClick={handleClick}
			>
				<img
					data-testid="white-icon"
					alt="info-icon"
					src={blueInfoIcon as unknown as string}
				/>
			</IconButton>
			<Popper
				id={popperId}
				open={open}
				anchorEl={anchorEl}
				sx={styles.popperFont}
				data-testid="popper-content"
			>
				<Box
					sx={{
						border: 'none',
						p: 2,
						bgcolor: 'background.hover',
						maxWidth: '350px',
						zIndex: 99999,
						fontSize: '14px',
					}}
				>
					<PopperHeader text={popperContent.header} />
					<br />
					<IntroText text={popperContent.introText} />
					<BulletedList bulletItems={popperContent.bulletItems} />
				</Box>
			</Popper>
		</>
	)
}

export default InfoPopper
