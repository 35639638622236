import { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSearchParams } from 'react-router-dom'

import { Box, Container, Divider, Typography } from '@mui/material'
import { uniqBy } from 'lodash'
import { sanitizeQueryParams } from 'utils/queryParamUtils'

import { Idea, Ideas } from 'models/ideaModels'

import { deleteIdea, editIdea, getIdeas } from 'services/ideas.service'

import { CallToAction } from 'components/CallToAction/CallToAction'
import EmptySubPageContent from 'components/EmptySubPageContent/EmptySubPageContent'
import SubPageHeader from 'components/SubPageHeader/SubPageHeader'

import useApi from 'hooks/useApi'

import { HOME_PAGE } from 'assets/routes'

import { styles } from './SubmittedIdeas.styles'
import FilterSection from './components/FilterSection'
import IdeaRow from './components/IdeaRow'
import SynthesisSection from './components/SynthesisSection/SynthesisSection'

export const SubmittedIdeas = () => {
	const [ideas, setIdeas] = useState<Idea[]>([])
	const [selectedIdeas, setSelectedIdeas] = useState<Idea[]>([])
	const [metadata, setMetadata] = useState({
		totalCount: 0,
		page: 1,
		pageSize: 10,
	})
	const { data, requestApi, loading, success } = useApi(getIdeas)

	const [searchParams] = useSearchParams()

	const loadIdeasOnPage = useCallback(
		(urlSearchParams: URLSearchParams) => {
			requestApi(sanitizeQueryParams(urlSearchParams))
		},
		[requestApi]
	)

	const {
		data: updatedIdea,
		requestApi: requestEditIdea,
		loading: editIdeaLoading,
	} = useApi(editIdea)

	const {
		data: deletedIdea,
		requestApi: requestDeleteIdea,
		loading: deleteIdeaLoading,
	} = useApi(deleteIdea)

	useEffect(() => {
		setIdeas((ideas) =>
			ideas.map((idea) => {
				if (idea?.submittedId === updatedIdea?.submittedId) {
					return { ...idea, ...updatedIdea }
				} else {
					return idea
				}
			})
		)
	}, [updatedIdea])

	useEffect(() => {
		if (!deleteIdeaLoading && deletedIdea) {
			setIdeas([])
			loadIdeasOnPage(searchParams)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deletedIdea, deleteIdeaLoading])

	const loadNextPage = useCallback(async () => {
		if (ideas.length >= metadata.totalCount) return
		const page = Number(metadata.page) + 1
		searchParams.set('page', page.toString())
		loadIdeasOnPage(searchParams)
	}, [
		loadIdeasOnPage,
		searchParams,
		ideas.length,
		metadata?.page,
		metadata?.totalCount,
	])

	useEffect(() => {
		if (!loading && success) {
			const { metadata, records } = data as Ideas
			if (records) {
				setIdeas((ideas) =>
					uniqBy([...ideas, ...records], 'submittedId')
				)
				setMetadata(metadata)
			}
		}
	}, [loading, data, success])

	useEffect(() => {
		setIdeas([])
		loadIdeasOnPage(searchParams)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams])

	const handleIdeaSelect = (id: string, isSelected: boolean) => {
		const index = selectedIdeas.findIndex((idea) => idea.submittedId === id)
		if (isSelected && index < 0) {
			const selectedIdea = ideas.find((idea) => idea.submittedId === id)
			if (selectedIdea) {
				setSelectedIdeas((prev) => prev.concat(selectedIdea))
			}
		} else if (!isSelected && index >= 0) {
			setSelectedIdeas((prev) =>
				prev.filter((idea) => idea.submittedId !== id)
			)
		}
	}

	return (
		<Box>
			<SubPageHeader
				title="Submitted Ideas"
				subtitle="Track your idea submissions + others submitted ideas to your games"
				backgroundGradient="linear-gradient(90deg, #9DCDF2 0%, #BDDEF6 100%)"
			/>

			<Container
				maxWidth={'xl'}
				sx={{
					...styles.list,
				}}
			>
				<FilterSection
					ideas={ideas}
					displayCount={ideas.length}
					selectedIdeas={selectedIdeas}
					totalCount={metadata?.totalCount}
				/>

				<Divider sx={styles.Divider} />
				<SynthesisSection
					selectedIdeas={
						selectedIdeas.length > 0 ? selectedIdeas : ideas
					}
				/>
				{loading && ideas.length === 0 ? (
					<Typography
						sx={{
							my: 4,
							textAlign: 'center',
						}}
					>
						Loading ideas...
					</Typography>
				) : !loading && ideas.length === 0 ? (
					<EmptySubPageContent
						subtitle="No ideas submitted yet, play a game to start submitting ideas!"
						buttonText="VIEW ALL GAMES"
						buttonOnClick={() => window.location.assign(HOME_PAGE)}
					/>
				) : (
					<InfiniteScroll
						dataLength={ideas.length}
						next={loadNextPage}
						hasMore={ideas.length < metadata.totalCount}
						loader={
							<Typography textAlign="center" my={'4rem'}>
								Loading...
							</Typography>
						}
					>
						{ideas.length &&
							ideas.map((idea, index) => (
								<IdeaRow
									key={index}
									idea={idea}
									onSelect={handleIdeaSelect}
									editIdea={requestEditIdea}
									deleteIdea={requestDeleteIdea}
									isLoading={editIdeaLoading}
								/>
							))}
					</InfiniteScroll>
				)}
				<Divider sx={styles.Divider} />
			</Container>
			<CallToAction data-testid="submit-games" />
		</Box>
	)
}

export default SubmittedIdeas
