import { SubmittedIdeaCSV } from 'models/ideaModels'

export const IDEA_HEADER_MAPPING: Partial<
	Record<keyof SubmittedIdeaCSV, string>
> = {
	gameType: 'Game Type',
	gameTitle: 'Game Title',
	firstName: 'First Name',
	lastName: 'Last Name',
	companyName: 'Company Name',
	innovationTopic: 'Innovation Topic',
	innovationCompany: 'Innovation Company',
	promptTitle: 'Prompt Title',
	authorIdeation: 'Author Ideation',
	createdDate: 'Created Date',
	isFavorite: 'Is Favorite',
}
