import { memo } from 'react'

import Section3 from './Section3'
import type { Props as Section3Props } from './Section3'

const Section3Component = memo(Section3)

export { Section3Props }

export default Section3Component
