import React from 'react'
import ReactDOM from 'react-dom/client'

import { Amplify } from 'aws-amplify'
import {
	AWS_DOMAIN,
	AWS_IDENTITY_POOL,
	USER_POOL_ID,
	USER_POOL_WEB_CLIENT_ID,
} from 'config'

import App from './components/App'
import { ContextProviders } from './containers/ContextProviders/ContextProviders'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// configure amplify
Amplify.configure({
	Auth: {
		// mandatorySignIn: false,
		Cognito: {
			//  Amazon Cognito User Pool ID
			userPoolId: USER_POOL_ID as string,
			// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
			userPoolClientId: USER_POOL_WEB_CLIENT_ID as string,
			// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
			identityPoolId: AWS_IDENTITY_POOL as string,
			loginWith: {
				oauth: {
					domain: AWS_DOMAIN as string,
					redirectSignIn: [`${window.location.origin}/`],
					redirectSignOut: [`${window.location.origin}/`],
					responseType: 'code',
					scopes: ['openid'],
				},
			},
		},
	},
})

root.render(
	<ContextProviders>
		<App />
	</ContextProviders>
)
