export const styles = {
	circularProgressIcon: {
		color: 'white',
	},
	companyTextField: {
		'& .MuiInputBase-root': {
			height: '4rem',
			padding: 0,
			textAlign: 'center',
		},
		height: '4rem',
		width: '100%',
	},
	/*
	section: {
		display: 'flex',
		flexDirection: 'column',
		mb: '1.75rem',
	},
	*/
	sectionContainer: {
		padding: '2.8rem 4.8rem',
	},
	sectionBtwnInputText: {
		color: 'text.secondary',
		fontStyle: 'italic',
		marginTop: '1.5rem',
	},
	sectionForm: {
		width: '42rem',
	},

	container_column: {
		display: 'grid',
		gridTemplateRows: 'repeat(4, 1fr)',
	},
	genAiPreviewContent: {
		'&:hover': {
			backgroundColor: 'background.hover',
			borderColor: 'secondary.main',
			cursor: 'pointer',
		},
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		padding: '1.4rem',
		width: '100%',
	},
	genAiPreviewImg: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	genAiPreviewText: {
		color: 'secondary.main',
		fontWeight: 'fontWeightRegular',
		textAlign: 'center',
		textDecoration: 'underline',
		textUnderlineOffset: '.22em',
	},
	ideaTextField: {
		'& .MuiOutlinedInput-root': {
			'&:hover': {
				border: '1px solid',
				borderColor: 'primary.main',
			},
			border: '1px solid',
			borderColor: 'primary.main',
		},
	},
	infoIconContainer: {
		marginBottom: '15px',
	},
	inputContainer: {
		display: 'flex',
		gap: '1.7rem',
		width: '100%',
	},
	randomPromptContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	sectionText: {
		color: 'text.secondary',
		fontStyle: 'italic',
	},
	submitButton: {
		'&:disabled': {
			backgroundColor: 'primary.disabled',
			color: '#FFF',
		},
		'&:hover': {
			backgroundColor: 'primary.main',
			color: '#FFF',
		},
		backgroundColor: 'primary.main',
		borderRadius: 0,
		color: '#FFF',
		width: '15.5rem',
	},
	submitContainer: {
		alignItems: 'center',
		display: 'flex',
		gap: '1.4rem',
		paddingBottom: '2.5rem',
	},
	userResponseInput: {
		alignItems: 'stretch',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'end',
		width: '100%',
	},
	container: {
		padding: '2.8rem 4.8rem',
	},
	containerai: {
		columnCount: 4,
		columnWidth: '15rem',
	},
	dropdownContainer: {
		alignItems: 'center',
		display: 'flex',
		gap: '1.7rem',
		width: '100%',
	},
	genAiUnSelectableContent: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		padding: '1.4rem',
		width: '100%',
	},
	genAiResponseContainer: {
		border: '1px solid #45657B',
		display: 'flex',
		height: '27rem',
		justifyContent: 'center',
		overflow: 'auto',
	},
	genAiResponseContent: {
		padding: '1.4rem',
		width: '100%',
	},
	generateScenarioBtn: {
		'&:hover': {
			backgroundColor: 'secondary.main',
			color: '#FFF',
		},
		backgroundColor: 'secondary.main',
		borderRadius: 0,
		color: '#FFF',
		pl: '2.2rem',
		pr: '2.2rem',
	},
	promptContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '2.2rem',
	},
	scenarioContainer: {
		display: 'flex',
		gap: '6.9rem',
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		mb: '6.9rem',
	},
	sectionHeader: {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.6rem',
	},
	sectionHeaderContent: {
		color: 'primary.main',
		display: 'flex',
		flexDirection: 'row',
		gap: '1.2rem',
	},
	sectionHeaders: {
		display: 'flex',
		pb: '2.4rem',
	},
	solutionText: {
		color: 'text.secondary',
		fontStyle: 'italic',
		margin: 0,
		paddingBottom: '1.8rem',
	},
}
