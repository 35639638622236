import React from 'react'

import { Box, Link, Typography } from '@mui/material'

import RectangleButtonComponent from 'components/RectangleButton'

import { cta } from 'assets/callToActionParams'
import NotFoundIcon from 'assets/images/404.svg'
import LeftArrowIcon from 'assets/images/LeftArrow.svg'
import { HOME_PAGE } from 'assets/routes'

import { styles } from './PageNotFound.styles'

const PageNotFound = () => {
	return (
		<Box data-testid="page__not__found" sx={styles.root}>
			<Box sx={styles.IconContainer}>
				<img
					alt="not-found-icon"
					src={NotFoundIcon as unknown as string}
				/>
			</Box>
			<Box sx={styles.TextContainer}>
				<Typography sx={styles.TextField}>
					You don’t have access to this page, or the page is no longer
					active
				</Typography>
			</Box>

			<Box sx={styles.btnContainer}>
				<RectangleButtonComponent
					iconSvg={LeftArrowIcon}
					data-testid="return__home__button"
					href={HOME_PAGE}
					text={'go home'}
					color="secondary"
					sx={styles.GoHome}
					typographySx={styles.GoHome}
				/>

				<Link
					role="contactAdmin"
					color={'secondary'}
					underline="none"
					href={`mailto:arclabs@slalom.com?${Object.entries(cta)
						.map(([k, v]) => `${k}=${v}`)
						.join('&')}`}
					target="_blank"
				>
					<Typography sx={styles.ContactAnAdmin}>
						CONTACT AN ADMIN
					</Typography>
				</Link>
			</Box>
		</Box>
	)
}

export default PageNotFound
