import React from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import InformationModal from 'components/InformationModal/InformationModal'

import { brainstormText } from 'assets/brainstormText'

import { styles } from './PromptBox.styles'

export const TEST_ID = 'prompt-box'

export type Props = {
	contentText: string
	'data-testid'?: string
	headerText?: string
	hidePromptContainerStyle?: boolean
	iconButton?: boolean
	loading?: boolean
}

const PromptBox = ({
	contentText = brainstormText.slotMachine.generateScenario,
	headerText,
	loading = false,
	iconButton,
	hidePromptContainerStyle,
	...props
}: Props) => {
	return (
		<Box
			data-testid={props['data-testid'] ?? 'prompt-container'}
			sx={hidePromptContainerStyle ? null : styles.container}
		>
			<Card sx={styles.card} variant="outlined">
				<CardContent sx={styles.cardContentReset}>
					<Box
						data-testid="prompt-header-container"
						sx={
							headerText
								? styles.headerContainer
								: styles.bottomBorder
						}
					>
						<Typography sx={styles.headerText} variant="h5">
							{headerText}
						</Typography>
						{iconButton && <InformationModal defaultIcon={true} />}
					</Box>
					<Box
						data-testid="prompt-content-container"
						sx={
							headerText
								? styles.contentContainer
								: styles.secondaryContentContainer
						}
					>
						{loading ? (
							<CircularProgress
								sx={styles.circularProgressIcon}
							/>
						) : (
							<Typography sx={styles.contentText} variant="h6">
								{contentText}
							</Typography>
						)}
					</Box>
				</CardContent>
			</Card>
		</Box>
	)
}

export default PromptBox
