import React, { useState } from 'react'

import { Box, Button, Modal, Typography } from '@mui/material'

import BaseTextField from '../BaseTextField/BaseTextField'
import { styles } from './RichTextField.styles'
import './styles.css'

interface IProps {
	closeModal: () => void
	label: string
	onChangeLabel: (e: React.ChangeEvent<HTMLInputElement>) => void
	onChangeUrl: (e: React.ChangeEvent<HTMLInputElement>) => void
	onRemoveLink: (e: React.MouseEvent<HTMLButtonElement>) => void
	onSaveLink: (e: React.MouseEvent<HTMLButtonElement>) => void
	open: boolean
	url: string
}

export function LinkModal(props: IProps) {
	const {
		url = '',
		label = '',
		onChangeUrl,
		onChangeLabel,
		onSaveLink,
		onRemoveLink,
		closeModal,
		...rest
	} = props

	const [urlValid, setUrlValid] = useState<boolean>(true)

	const handleInsertLink = (ev: React.MouseEvent<HTMLButtonElement>) => {
		if (!/^https?:\/\//.test(url)) {
			setUrlValid(false)
		} else {
			setUrlValid(true)
			onSaveLink(ev)
		}
	}

	return (
		<Modal
			{...rest}
			sx={{ borderRadius: '0.5rem', borderColor: 'transparent' }}
			onClose={closeModal}
		>
			<Box sx={styles.linkModal}>
				<Typography
					variant="body2"
					component="h2"
					color="text.input"
					sx={{ paddingLeft: '1rem', fontStyle: 'italic' }}
				>
					URL to hyperlink
				</Typography>
				<BaseTextField
					autoFocus
					value={url}
					onChange={onChangeUrl}
					placeholder="Enter link"
					variant="outlined"
					fullWidth
					error={!urlValid}
					helperText={!urlValid && 'Please enter a valid URL'}
				/>
				<Typography
					variant="body2"
					component="h2"
					color="text.input"
					sx={{
						paddingLeft: '1rem',
						marginTop: '2rem',
						fontStyle: 'italic',
					}}
				>
					Text to display
				</Typography>
				<BaseTextField
					autoFocus
					value={label}
					onChange={onChangeLabel}
					variant="outlined"
					fullWidth
				/>

				<Box mt={4} display="flex" justifyContent="center">
					<Button
						onClick={onRemoveLink}
						sx={{
							marginRight: '2rem',
							color: 'action.selected',
							textDecoration: 'underline',
						}}
						size="large"
						focusRipple
					>
						Cancel
					</Button>
					<Button
						onClick={handleInsertLink}
						variant="contained"
						sx={{ backgroundColor: 'action.selected' }}
						disabled={!url}
						size="large"
						focusRipple
					>
						Insert Link
					</Button>
				</Box>
			</Box>
		</Modal>
	)
}
