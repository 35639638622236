import { CircularProgress, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { styles } from './Node.styles'

export const TEST_ID = 'node'

export type Props = {
	isEdit: boolean
	isLoading: boolean
	onInputChange: (oldValue: string, newValue: string) => void
	positions: string
	text: string
}

const Node = (props: Props) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.onInputChange(props.positions, event.target.value)
	}

	return (
		<>
			<Box
				display={'flex'}
				sx={{
					...styles.rectangle,
					border: props.isEdit ? '0px' : '1px solid #0000003B',
				}}
				data-testid={`${TEST_ID}-container`}
			>
				{props.isLoading ? (
					<Box display={'flex'} sx={styles.container}>
						<CircularProgress
							size={30}
							sx={styles.circularProgressIcon}
						/>
						<Typography sx={styles.loadingText}>Loading</Typography>
					</Box>
				) : props.isEdit ? (
					<TextField
						defaultValue={props.text}
						onChange={handleChange}
						inputProps={{
							'aria-label': 'prompt text',
							style: {
								fontWeight: '400',
								fontSize: '1.8rem',
							},
						}}
						multiline
						rows={2}
					/>
				) : (
					<Typography sx={styles.text}>{props.text}</Typography>
				)}
			</Box>
		</>
	)
}

export default Node
