export const styles = {
	card: {
		border: 'none',
		borderRadius: '0.4rem',
	},
	cardContentReset: {
		'&:last-child': {
			paddingBottom: 0,
		},
		height: '100%',
		margin: 0,
		overflowY: 'visible',
		padding: 0,
		position: 'relative',
	},
	circularProgressIcon: {
		color: 'secondary.main',
		size: '2.8rem',
	},
	container: {
		maxWidth: '100%',
		minHeight: '9.8rem',
		position: 'relative',
		width: '42rem',
	},

	contentText: {
		color: '#000000DE',
		textAlign: 'left',
		whiteSpace: 'pre-wrap',
	},
}
