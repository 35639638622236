import React from 'react'

export interface Props {
	color?: string
}

const IconUnderline = (props: Props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="9"
			height="12"
			viewBox="0 0 9 12"
			fill="none"
		>
			<path
				d="M4.5 9.33333C6.62786 9.33333 8.35714 7.54 8.35714 5.33333V0H6.75V5.33333C6.75 6.62 5.74071 7.66667 4.5 7.66667C3.25929 7.66667 2.25 6.62 2.25 5.33333V0H0.642857V5.33333C0.642857 7.54 2.37214 9.33333 4.5 9.33333ZM0 10.6667V12H9V10.6667H0Z"
				fill={props.color ? props.color : '#45657B'}
			/>
		</svg>
	)
}
export default IconUnderline
