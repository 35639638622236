export const styles = {
	CarouselName: {
		fontSize: '20px',
		fontWeight: 500,
		color: 'rgba(0, 0, 0, 0.87)',
	},
	CarouselDescription: {
		fontSize: '18px',
		marginTop: '3px',
		fontWeight: 400,
		color: 'rgba(0, 0, 0, 0.87)',
	},
	CarouselEditableDescription: {
		'.MuiInputBase-input': {
			fontWeight: '400',
		},
		'.MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'.MuiOutlinedInput-root': {
			paddingLeft: '0',
		},
	},
	prevButtonSet: {
		position: 'relative',
		overflow: 'visible',
	},
	nextButton: {
		position: 'absolute',
		right: '-30px',
		minWidth: '55px',
		borderRadius: '50px',
		backgroundColor: 'white',
		boxShadow: '0px 1px 18px 0px rgba(0, 0, 0, 0.12)',

		'@media (max-width: 1024px)': {
			position: 'absolute',
			right: '-30px',
		},
		'@media (max-width: 600px)': {
			position: 'absolute',
			right: '0px',
			top: '0px',
			backgroundColor: 'rgba(255, 232, 238, 1)',
			boxShadow: 'none',
		},
	},
	prevButton: {
		position: 'absolute',
		left: '-30px',
		borderRadius: '50px',
		backgroundColor: 'white',
		width: '62px',
		boxShadow: '0px 1px 18px 0px rgba(0, 0, 0, 0.12)',

		'@media (max-width: 1024px)': {
			position: 'absolute',
			left: '-30px',
		},
		'@media (max-width: 600px)': {
			position: 'absolute',
			left: '0px',
			top: '0px',
			backgroundColor: 'rgba(255, 232, 238, 1)',
			color: 'rgba(69, 101, 123, 1)',
			boxShadow: 'none',
		},
	},
}
