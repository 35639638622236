import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'

import { Idea } from 'models/ideaModels'

import { AuthContext } from 'contexts/AuthProvider'

import { getIdeas } from 'services/ideas.service'

import useApi from 'hooks/useApi'

interface IdeaStreakContextType {
	ideaStreak: number
	setStreakBegun: (value: boolean) => void
	streakBegun: boolean
	updateStreak: () => void
}

interface Metadata {
	page: number
	pageSize: number
	totalCount: number
}

interface ProviderData {
	metadata: Metadata
	records: Idea[]
}

const IdeaStreakContext = createContext<IdeaStreakContextType | undefined>(
	undefined
)

export const IdeaStreakProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [ideaStreak, setIdeaStreak] = useState<number>(0)
	const [ideasFromPastSessions, setIdeasFromPastSessions] = useState<
		number | null
	>(null)
	const [streakBegun, setStreakBegun] = useState<boolean>(false)
	const { isAuthenticated } = useContext(AuthContext)

	const {
		success: ideasSuccess,
		data: ideasData = {
			metadata: {
				page: 1,
				pageSize: 10,
				totalCount: 0,
			},
			records: [],
		} as ProviderData,
		requestApi: updateStreak,
	} = useApi(getIdeas)

	const memoizedUpdateStreak = useCallback(updateStreak, [updateStreak])

	useEffect(() => {
		if (isAuthenticated) {
			// memoizedUpdateStreak()
		} else {
			// Reset the ideasFromPastSessions, ideaStreak, and streakBegun when the user logs out
			setIdeasFromPastSessions(null)
			setIdeaStreak(0)
			setStreakBegun(false)
		}
	}, [isAuthenticated, memoizedUpdateStreak])

	useEffect(() => {
		if (!ideasSuccess) return

		/*
			The total number of ideas corresponds to the 
			total number of occurrences of `<p>` in the 
			authorIdeation fields of all records:
		*/
		let newIdeaStreak = 0
		ideasData?.records.forEach((record: Idea) => {
			const matches = record?.authorIdeation?.match(/<p>/g)?.length || 0
			newIdeaStreak += matches
		})

		if (ideasFromPastSessions === null) {
			// Memoize the initial streak value
			setIdeasFromPastSessions(newIdeaStreak)
			setIdeaStreak(0)
		} else {
			// Calculate the streak for the current session
			setIdeaStreak(newIdeaStreak - ideasFromPastSessions)
		}
	}, [ideasSuccess, ideasData, ideasFromPastSessions])

	return (
		<IdeaStreakContext.Provider
			value={{
				ideaStreak,
				updateStreak: memoizedUpdateStreak,
				streakBegun,
				setStreakBegun,
			}}
		>
			{children}
		</IdeaStreakContext.Provider>
	)
}

export const useIdeaStreakContext = () => {
	const context = useContext(IdeaStreakContext)
	if (context === undefined) {
		throw new Error(
			'useIdeaStreakContext must be used within an IdeaStreakProvider'
		)
	}
	return context
}
