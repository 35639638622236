export const styles = {
	textFieldContainer: {
		flexGrow: 1,
	},
	containerGrid: {
		pb: 2,
		pt: 1,
		width: '100%',
	},
	circularProgressIcon: {
		color: 'secondary.main',
		size: '2.8rem',
	},
	textField: {
		'& .MuiOutlinedInput-root': {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: '#93A2AF',
			},
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderColor: 'secondary.main',
			},
			'&:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: 'secondary.main',
			},
		},
	},
	inputLabel: {
		color: '#93A2AF',
	},
}
