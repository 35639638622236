import React, { CSSProperties } from 'react'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Typography } from '@mui/material'

export const TEST_ID = 'breadcrumb'

export interface BreadCrumbProps {
	active?: boolean
	label: string
	onClick?: () => void
}

export interface BreadCrumbsProps {
	breadcrumbs: BreadCrumbProps[]
}

const BreadCrumbs = ({ breadcrumbs }: BreadCrumbsProps) => {
	const getBreadcrumbStyle = (breadcrumb: BreadCrumbProps) => {
		let style: Record<string, string | CSSProperties> = {
			color: breadcrumb.active ? '#45657B' : 'black',
		}
		if (!breadcrumb.active) {
			style = {
				opacity: '60%',
				...style,
			}
		}
		if (breadcrumb.onClick) {
			style = {
				':hover': {
					textDecoration: 'underline',
				},
				...style,
			}
		}
		return style
	}
	return (
		<Breadcrumbs
			separator={<NavigateNextIcon fontSize="small" />}
			aria-label="breadcrumb"
		>
			{breadcrumbs.map((breadcrumb: BreadCrumbProps, i: number) => (
				<Typography
					onClick={breadcrumb.onClick || undefined}
					key={`breadcrumb-${i}`}
					data-testid={`${TEST_ID}-breadcrumb-${i}`}
					sx={getBreadcrumbStyle(breadcrumb)}
					fontWeight={breadcrumb.active ? 700 : 400}
					fontSize={'1.4rem'}
				>
					{breadcrumb.label}
				</Typography>
			))}
		</Breadcrumbs>
	)
}

export default BreadCrumbs
