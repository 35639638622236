import { useState } from 'react'

export function MusicalChairsModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string>('')
	const [businessProcess, setBusinessProcess] = useState<string>('')
	const [companyName, setCompanyName] = useState<string>('')
	const [gameId, setGameId] = useState<string | null>(null)
	const [termsAgreed, setTermsAgreed] = useState(false)

	const resetSoft = () => {
		setAuthorIdeation('')
	}

	return {
		authorIdeation,
		setAuthorIdeation,
		businessProcess,
		setBusinessProcess,
		companyName,
		setCompanyName,
		gameId,
		resetSoft,
		setGameId,
		termsAgreed,
		setTermsAgreed,
	}
}
