import React, { createContext, useContext, useEffect, useState } from 'react'

import { MIXPANEL_TOKEN, isLocalEnvironment } from 'config'
import Mixpanel, { OverridedMixpanel } from 'mixpanel-browser'

export const MixpanelContext = createContext<OverridedMixpanel | undefined>(
	undefined
)
export const useMixpanelContext = () => useContext(MixpanelContext)

interface MixpanelProviderProps {
	children: React.ReactNode
}

export const MixpanelProvider = ({ children }: MixpanelProviderProps) => {
	const [mixpanel, setMixpanel] = useState<OverridedMixpanel | undefined>()

	useEffect(() => {
		const initializeMixpanel = () => {
			if (!isLocalEnvironment && MIXPANEL_TOKEN) {
				Mixpanel.init(MIXPANEL_TOKEN)
				setMixpanel(Mixpanel)
			}
		}

		initializeMixpanel()
	}, [])

	return (
		<MixpanelContext.Provider value={mixpanel}>
			{children}
		</MixpanelContext.Provider>
	)
}
