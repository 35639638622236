import React, { FC, SVGProps } from 'react'

import { IconButton, Typography } from '@mui/material'

import { styles } from './ButtonWithIcon.styles'

export interface Props {
	Icon?: JSX.Element
	'data-testid'?: string
	disabled?: boolean
	iconSvg?: string | FC<SVGProps<SVGElement>>
	iconWidth?: string
	onClickAction: () => void
	sx?: Record<string, unknown>
	text?: string
}

export const ButtonWithIcon = ({
	Icon,
	iconSvg,
	text = '',
	onClickAction,
	iconWidth,
	disabled = false,
	...restProps
}: Props) => (
	<IconButton
		data-testid={restProps['data-testid'] ?? 'ButtonWithIcon'}
		disabled={disabled}
		onClick={onClickAction}
		sx={{ ...styles.IconButton, ...restProps.sx }}
	>
		{Icon}
		{iconSvg && (
			<img
				alt={text + ' icon'}
				src={iconSvg as string}
				style={iconWidth ? { width: iconWidth } : {}}
			/>
		)}
		<Typography sx={styles.ButtonText}>{text}</Typography>
	</IconButton>
)
