import { Box, Typography } from '@mui/material'

import RectangleButtonComponent from 'components/RectangleButton'

import GroupOfPeople from '../../assets/images/groupOfPeople.svg'
import { styles } from './CallToAction.styles'

export interface Props {
	'data-testid': string
	homeVariant?: boolean
}

export const CallToAction = ({
	'data-testid': dataTestId,
	homeVariant,
}: Props) => {
	return (
		<Box
			data-testid={`${dataTestId}__cta-container`}
			sx={{
				...styles.ctaContainer,
				...(homeVariant
					? { justifyContent: 'left' }
					: { marginTop: '3.2rem', justifyContent: 'center' }),
			}}
		>
			{homeVariant && (
				<img
					src={GroupOfPeople as unknown as string}
					style={styles.ctaImage}
				/>
			)}
			<Box
				sx={{
					...{ margin: 'auto' },
					...(homeVariant
						? { textAlign: 'left' }
						: { textAlign: 'center' }),
				}}
			>
				<Typography
					data-testid={`${dataTestId}__cta-text1`}
					sx={{
						...styles.callToActionText,
						...(homeVariant
							? { textAlign: 'left' }
							: { fontSize: '1.6rem', textAlign: 'center' }),
					}}
				>
					Want help finding your next great idea?
				</Typography>
				<Typography
					data-testid={`${dataTestId}__cta-text2`}
					sx={{
						...styles.ctaCaption,
						...(homeVariant
							? { textAlign: 'left' }
							: { fontSize: '1.6rem', textAlign: 'center' }),
					}}
				>
					Schedule a live brainstorming session with our expert
					facilitators.
				</Typography>

				<Box
					data-testid={`${dataTestId}__cta`}
					sx={{
						...styles.btnConnect,
						textAlign: homeVariant ? 'left' : 'center',
					}}
				>
					<RectangleButtonComponent
						color="secondary"
						data-testid={`${dataTestId}__cta_button`}
						href={'https://go.slalom.com/brainstorm'}
						target="_blank"
						text={"Let's Connect!"}
						variant={homeVariant ? 'contained' : 'outlined'}
					/>
				</Box>
			</Box>
		</Box>
	)
}
