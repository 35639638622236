import { Game } from 'models/gameModels'

import { MODAL_TYPES, useModalContext } from 'contexts/ModalProvider'

function useCreateCustomGameModal() {
	const { showModal } = useModalContext()
	const getPathname = (label: string) => {
		return `/games/${label.toLowerCase().replace(/ /g, '-')}`
	}
	const setIsShowCreateCustomGameModal = (value: boolean) => {
		sessionStorage.setItem('isShowCreateCustomGameModal', value.toString())
	}
	const showCreateCustomGameModal = ({
		gameLabel,
		gameState,
	}: {
		gameLabel: string
		gameState: Partial<Game>
	}) => {
		if (sessionStorage.getItem('isShowCreateCustomGameModal') === 'false')
			return
		if (window.location.pathname === getPathname(gameLabel)) {
			showModal(MODAL_TYPES.CREATE_CUSTOM_GAME_MODAL, {
				gameState: {
					gameTypeId: gameState.gameTypeId,
					innovationCompany: gameState.innovationCompany,
					innovationTopic: gameState.innovationTopic,
					companyProblem: gameState.companyProblem,
				},
			})
		}
	}
	return {
		setIsShowCreateCustomGameModal,
		showCreateCustomGameModal,
	}
}
export default useCreateCustomGameModal
