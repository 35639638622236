import React, { useState } from 'react'

import { Box, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'

import { brainstormText } from 'assets/brainstormText'
import whiteInfoIcon from 'assets/images/infoIcon-white.svg'
import blueInfoIcon from 'assets/images/infoIcon.svg'

import { styles } from './InformationModal.styles'

export type Props = {
	defaultIcon: boolean
}

// defaultIcon in this case is the white info icon
// TODO: either include this in the Modal architecture
// OR rework it as a Tooltip component since that's what it needs to be
const InformationModal = ({ defaultIcon }: Props) => {
	const [isOpen, setIsOpen] = useState(false)

	const handleOpen = () => {
		setIsOpen(true)
	}

	const handleClose = () => {
		setIsOpen(false)
	}
	const iconText = () => {
		return (
			<Box sx={styles.dialogContainer}>
				<Typography variant="h6">
					{brainstormText.slotMachine.randomElementTooltip.header1}
				</Typography>
				<br />
				<Typography variant="body1">
					{brainstormText.slotMachine.randomElementTooltip.body1}
				</Typography>
				<br />
				<Typography variant="body1">
					{brainstormText.slotMachine.randomElementTooltip.body2}
				</Typography>
				<br />
				<Typography variant="h6">
					{brainstormText.slotMachine.randomElementTooltip.header2}
				</Typography>
				<br />
				<Typography variant="body1">
					{brainstormText.slotMachine.randomElementTooltip.body3}
				</Typography>
				<br />
				<Typography component="div">
					<ul>
						<li>
							{
								brainstormText.slotMachine.randomElementTooltip
									.bullet1
							}
						</li>
						<li>
							{
								brainstormText.slotMachine.randomElementTooltip
									.bullet2
							}
						</li>
						<li>
							{
								brainstormText.slotMachine.randomElementTooltip
									.bullet3
							}
						</li>
					</ul>
				</Typography>
				<br />
				<Typography variant="body1">
					{brainstormText.slotMachine.randomElementTooltip.body4}
				</Typography>
			</Box>
		)
	}
	return (
		<Box data-testid="info-modal-container">
			<IconButton data-testid="icon-button" onClick={() => handleOpen()}>
				{defaultIcon ? (
					<img
						data-testid="white-icon"
						alt="info-icon"
						src={whiteInfoIcon as unknown as string}
					/>
				) : (
					<img
						data-testid="blue-icon"
						alt="info-icon"
						src={blueInfoIcon as unknown as string}
					/>
				)}
			</IconButton>
			<Dialog
				data-testid="random-text-dialog"
				onClose={handleClose}
				open={isOpen}
			>
				{iconText()}
			</Dialog>
		</Box>
	)
}

export default InformationModal
