import { memo } from 'react'

import BaseTextField, { TEST_ID } from './BaseTextField'
import type { Props as BaseTextFieldProps } from './BaseTextField'

const BaseTextFieldComponent = memo(BaseTextField)

export { TEST_ID, BaseTextFieldProps }

export default BaseTextFieldComponent
