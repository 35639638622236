import { memo } from 'react'

import Section1 from './Section1'
import type { Props as Section1Props } from './Section1'

const Section1Component = memo(Section1)

export { Section1Props }

export default Section1Component
