import React from 'react'

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Button, Paper, TextField, Typography } from '@mui/material'

import { styles } from './PromptCarousel.styles'

export const TEST_ID = 'prompt-carousel'

const buttonStyle = {
	fontSize: '45px',
	color: 'rgba(69, 101, 123, 1)',
}

interface Item {
	description: string
	name: string
}

interface Button {
	label: string
}

const CarouselItem: React.FC<{ item: Item }> = ({ item }) => {
	return (
		<Box sx={styles.prevButtonSet}>
			<Paper
				sx={{
					padding: '2rem',
					backgroundColor: 'rgba(255, 232, 238, 1)',
					paddingX: {
						xs: '3rem',
						sm: '5rem',
					},
				}}
			>
				<Typography sx={styles.CarouselName}>{item.name}</Typography>
				<Typography
					sx={styles.CarouselDescription}
					variant="h6"
					whiteSpace="pre-wrap"
				>
					{item.description}
				</Typography>
			</Paper>
		</Box>
	)
}

const CarouselEditableItem: React.FC<{
	item: Item
	setPromptValue: React.Dispatch<string>
}> = ({ item, setPromptValue }) => {
	return (
		<Box sx={styles.prevButtonSet}>
			<Paper
				sx={{
					padding: '2rem',
					backgroundColor: '#FFFFFF',
					paddingX: {
						xs: '3rem',
						sm: '5rem',
					},
				}}
			>
				<Typography sx={styles.CarouselName}>{item.name}</Typography>
				<TextField
					multiline
					fullWidth
					inputRef={(input) => input && input.focus()}
					sx={styles.CarouselEditableDescription}
					value={item.description}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setPromptValue(event.target.value)
					}}
				/>
			</Paper>
		</Box>
	)
}

const Carousel: React.FC<{
	currentIndex: number
	editable: boolean
	editableItems: Item[]
	items: Item[]
	setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
	setPromptValue: React.Dispatch<string>
}> = ({
	items,
	editableItems,
	currentIndex,
	editable,
	setCurrentIndex,
	setPromptValue,
}) => {
	const handlePrev = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex === 0 ? items.length - 1 : prevIndex - 1
		)
	}

	const handleNext = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex === items.length - 1 ? 0 : prevIndex + 1
		)
	}

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Box sx={{ width: '100%', height: '100%' }}>
				{editable ? (
					<CarouselEditableItem
						item={editableItems[currentIndex]}
						setPromptValue={setPromptValue}
					/>
				) : (
					<CarouselItem item={items[currentIndex]} />
				)}
			</Box>
			<Box
				sx={{
					...styles.prevButton,
					display: {
						xs: 'none',
						sm: 'block',
					},
				}}
			>
				<Button
					data-testid="prev-button"
					sx={{
						'&.MuiButtonBase-root:hover': {
							backgroundColor: 'rgba(207, 79, 39, 0.0)',
						},
					}}
					style={{ height: '60px', minWidth: '60px' }}
					onClick={handlePrev}
				>
					<NavigateBeforeIcon style={buttonStyle} />
				</Button>
			</Box>
			<Box
				sx={{
					...styles.nextButton,
					display: {
						xs: 'none',
						sm: 'block',
					},
				}}
			>
				<Button
					data-testid="next-button"
					sx={{
						'&.MuiButtonBase-root:hover': {
							backgroundColor: 'rgba(207, 79, 39, 0.0)',
						},
					}}
					onClick={handleNext}
					style={{ height: '60px', width: '60px' }}
				>
					<NavigateNextIcon style={buttonStyle} />
				</Button>
			</Box>
		</Box>
	)
}

const PromptCarousel: React.FC<{
	currentIndex: number
	currentPrompts: string[]
	editable: boolean
	promptsEditMode: string[]
	setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
	setPromptValue: React.Dispatch<string>
}> = ({
	currentPrompts,
	promptsEditMode,
	currentIndex,
	editable,
	setCurrentIndex,
	setPromptValue,
}) => {
	const names = [
		'Industry Context',
		'Potential Challenges',
		'Testing Ideas',
		'Scale Opportunities',
	]
	const items = currentPrompts.map((currentPrompt, i) => {
		return {
			name: names[i],
			description: currentPrompt,
		}
	})
	const editableItems = promptsEditMode.map((currentPrompt, i) => {
		return {
			name: names[i],
			description: currentPrompt,
		}
	})
	return (
		<Box>
			<Carousel
				items={items}
				editableItems={editableItems}
				currentIndex={currentIndex}
				setCurrentIndex={setCurrentIndex}
				setPromptValue={setPromptValue}
				editable={editable}
			/>
		</Box>
	)
}
export default PromptCarousel
