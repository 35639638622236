export const styles = {
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background: 'linear-gradient(180deg, #E65C00 0%, #F9D423 100%)',
	},
	submitContainer: {
		alignItems: 'center',
		display: 'flex',
		gap: '1.4rem',
	},
	submitButton: {
		'&:disabled': {
			backgroundColor: 'primary.disabled',
			color: '#FFF',
		},
		'&:hover': {
			backgroundColor: 'primary.main',
			color: '#FFF',
		},
		backgroundColor: 'primary.main',
		borderRadius: 0,
		color: '#FFF',
		width: '15.5rem',
	},
	sectionText: {
		color: 'text.secondary',
		fontStyle: 'italic',
		maxHeight: '25rem',
	},
}
