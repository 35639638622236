import { SxProps, Theme } from '@mui/material/styles'

export const styles = {
	italics: {
		fontStyle: 'italic',
	},
	boldText: {
		color: '#274A62',
		fontStyle: 'bold',
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: '400',
	},
	title: {
		paddingY: '0',
		display: 'flex',
		color: '#274A62',
		alignSelf: 'center',
		fontFamily: 'Roboto',
		fontSize: '25px',
		fontStyle: 'normal',
		fontWeight: '700',
		lineHeight: '133.4%' /* 33.35px */,
	},
	contentBox: {
		fontFamily: 'Roboto',
		color: '#666',
		padding: '0',
		overflowX: 'hidden',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '150%',
		letterSpacing: '0.15px',
		marginBottom: '-10px',
	},
	typography: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '400',
	},
	paragraph: {
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '400',
		padding: '0',
		display: 'flex',
		flexDirection: 'column',
		flexItem: {
			display: 'flex',
			alignItems: 'center',
			padding: 0,
			'&:not(:first-child)': {
				marginTop: '0px', // Adjust the value as needed
			},
			'&:not(:last-child)': {
				marginBottom: '0px', // Adjust the value as needed
			},
		},
	},
	titleBox: {
		padding: '0',
	},
	partnerTitle: {
		display: 'flex',
		color: '#274A62',
		alignSelf: 'center',
		fontFamily: 'Roboto',
		fontSize: '20px',
		fontStyle: 'normal',
		fontWeight: '500',
		lineHeight: '133.4%' /* 33.35px */,
	},
	experimentTitle: {
		fontFamily: 'Roboto',
		color: '#666',
		padding: '0',
		overflowX: 'hidden',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: '400',
		lineHeight: '150%',
		letterSpacing: '0.15px',
		margin: '0',
	},
	actionBox: {
		display: 'flex',
		gap: '24px',
		padding: '0',
		margin: '0',
		'@media (max-width: 600px)': {
			flexDirection: 'column',
			width: '100%',
			'& > *': {
				width: '100%', // Ensure children take the full width
			},
		},
	},
	primaryButton: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: '500',
		lineHeight: '26px' /* 173.333% */,
		letterSpacing: '0.46px',
		borderRadius: '4px',
		backgroundColor: '#45657B',
		color: '#FFFFFF',
		margin: '0',
		boxShadow:
			'0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
	},
	secondaryButton: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: '500',
		lineHeight: '26px' /* 173.333% */,
		letterSpacing: '0.46px',
		borderRadius: '4px',
		backgroundColor: '#FFFFFF',
		color: '#45657B',
		margin: '0',
		border: 'none',
	},
}
export const dialogStyles: SxProps<Theme> = {
	display: 'flex',
	maxWidth: '822px',
	width: '100%', // Ensure the box takes the full width of its container
	overflowX: 'hidden', // Prevent horizontal scrolling
	padding: '32px',
	gap: '32px',
	flexDirection: 'column',
	alignItems: 'flex-start',
	borderRadius: '32px',
	background: '#FFFFFF',
	boxShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.25)',

	'@media (max-width: 1024px)': {
		// Tablet styles
		width: '706px',
		alignItems: 'center',
	},

	'@media (max-width: 600px)': {
		// Mobile styles
		width: '320px', // Ensure the dialog takes the full width of the screen
		height: '89vh', // Set custom height
		alignItems: 'center',
		justifySelf: 'center',
		gap: '16px',
		padding: '24px 32px',
		position: 'fixed',
		bottom: '0', // Align to the bottom of the screen
		borderRadius: '32px 32px 0 0', // Rounded corners at the top
		left: '0',
		right: '0',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}
