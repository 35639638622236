import React, { forwardRef } from 'react'

import LockRounded from '@mui/icons-material/LockRounded'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import {
	DateTimePicker,
	DateTimePickerProps,
	DateTimePickerSlotsComponentsProps,
} from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export const TEST_ID = 'default-test-id-date-time-picker'
export const DEFAULT_INPUT_FORMAT = 'dd/MM/yyyy'

function threeMonthMaxDateFunc() {
	const nearestFifth = 1000 * 60 * 5
	const currentDate = new Date()
	const currentDateMonth = currentDate.getMonth()
	const threeMonthMax = currentDateMonth + 3
	const maxDate = currentDate.setMonth(threeMonthMax)
	const finalDateSeconds = new Date(
		Math.round(maxDate / nearestFifth) * nearestFifth
	).setSeconds(0, 0)
	return finalDateSeconds
}

export type Props = {
	'data-testid'?: string
	disabled?: boolean
	error?: boolean
	helperText?: string
	inputFormat?: string
	readOnly?: boolean
	// note: we're omitting value from the
	// DateTimePickerProps interface because it allows
	// for (Date & number), but in our implementation,
	// we are forcing Date every time
	value?: Date | null
} & Omit<DateTimePickerProps<number>, 'value'>

type CustomSlotProps = DateTimePickerSlotsComponentsProps<number> & {
	textField?: {
		'data-testid'?: string
		error?: boolean
		helperText?: string
	}
}

const BaseDateTimePicker = forwardRef<HTMLInputElement, Props>(
	(
		{
			inputFormat,
			'data-testid': dataTestId,
			readOnly,
			helperText,
			error,
			disabled,
			onChange,
			...props
		},
		ref
	) => (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DateTimePicker
				inputRef={ref}
				format={inputFormat}
				sx={{
					'& .MuiInputLabel-root': {
						fontWeight: 'normal', // Set the fontWeight to normal for the label
					},
				}}
				readOnly={readOnly}
				{...props}
				value={props.value && props.value.getTime()}
				slotProps={
					{
						textField: {
							helperText,
							'data-testid': dataTestId || TEST_ID,
							error,
						},
					} as CustomSlotProps
				}
				slots={{
					...(readOnly ? { openPickerIcon: LockRounded } : {}),
				}}
				maxDate={threeMonthMaxDateFunc()}
				disabled={disabled}
				onChange={onChange}
			/>
		</LocalizationProvider>
	)
)

BaseDateTimePicker.displayName = 'BaseDateTimePicker'

export default BaseDateTimePicker
