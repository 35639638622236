import { memo } from 'react'

import { GeneratedExampleDropdown } from './GeneratedExampleDropdown'
import type { Props as GeneratedExampleDropdownProps } from './GeneratedExampleDropdown'

const GeneratedExampleDropdownComponent = memo(GeneratedExampleDropdown)

export { GeneratedExampleDropdownProps }

export default GeneratedExampleDropdownComponent
