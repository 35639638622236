import React from 'react'

export interface Props {
	color?: string
}

const IconOrderList = (props: Props) => {
	return (
		<svg
			width="16"
			height="13"
			viewBox="0 0 16 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 10.5625H1.68421V10.9688H0.842105V11.7812H1.68421V12.1875H0V13H2.52632V9.75H0V10.5625ZM0.842105 3.25H1.68421V0H0V0.8125H0.842105V3.25ZM0 5.6875H1.51579L0 7.39375V8.125H2.52632V7.3125H1.01053L2.52632 5.60625V4.875H0V5.6875ZM4.21053 0.8125V2.4375H16V0.8125H4.21053ZM4.21053 12.1875H16V10.5625H4.21053V12.1875ZM4.21053 7.3125H16V5.6875H4.21053V7.3125Z"
				fill={props.color ? props.color : '#45657B'}
			/>
		</svg>
	)
}
export default IconOrderList
