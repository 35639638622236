import { memo } from 'react'

import Hero from './Hero'
import type { Props as HeroProps } from './Hero'

const HeroComponent = memo(Hero)

export { HeroProps }

export default HeroComponent
