import { memo } from 'react'

import BreadcrumbMenu, { TEST_ID } from './BreadcrumbMenu'
import type { Props as BreadcrumbMenuProps } from './BreadcrumbMenu'

const BreadcrumbComponent = memo(BreadcrumbMenu)

export { TEST_ID, BreadcrumbMenuProps }

export default BreadcrumbComponent
