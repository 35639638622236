export const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '1rem',
		// boxShadow: '4px 5px 4px 0px rgba(0, 0, 0, 0.12)',
		// '&:hover': {
		// 	boxShadow: '4px 5px 4px 2px rgba(0, 0, 0, 0.24)',
		// },
		border: '1px solid #DBDBDB',
	},
	gradientBar: { height: '.8rem', borderRadius: '3rem 3rem 0 0' },
	topRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '1.2rem',
	},
	middleRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '1.2rem',
	},
	bottomRow: {
		margin: '1.2rem 1.2rem 2.4rem 2.4rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	chip: {
		fontSize: '1.3rem',
		height: '2.4rem',
		borderRadius: '10rem',
		marginRight: '1.9rem',
	},
	stats: {
		display: 'flex',
		flexDirection: 'row',
		gap: '2rem',
		'& > div > p': {
			fontSize: '1.2rem',
		},
	},
	iconButton: {
		padding: 0,
	},
	icons: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: '1.2rem',
	},
	link: {
		textDecoration: 'none',
	},
	title: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: '1',
		WebkitBoxOrient: 'vertical',
		fontSize: '1.6rem',
		maxWidth: '30rem',
		fontWeight: '700',
		color: '#45657B',
		textDecoration: 'none',
	},
	chevron: {
		color: '#45657B',
	},
}
