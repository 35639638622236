export const sanitizeQueryParams = (urlSearchParams: URLSearchParams) => {
	// urlSearchParams === UrlSearchParams object
	let params = {}
	if (urlSearchParams.toString()) {
		for (const [key, value] of urlSearchParams.entries()) {
			// remove for idea/search payload
			if (key !== 'genAIQuestion') {
				if (key === 'page') {
					params = {
						...params,
						[key]: parseInt(value),
					}
				} else {
					params = {
						...params,
						[key]: value,
					}
				}
			}
		}
	}
	return params
}
