import React from 'react'

export interface Props {
	color?: string
}

const IconItalics = (props: Props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="9"
			height="11"
			viewBox="0 0 9 11"
			fill="none"
		>
			<path
				d="M6.39575 1.24394L4.17447 9.75606H6.47234L6.14681 11H0L0.325532 9.75606H2.60426L4.80638 1.24394H2.52766L2.85319 0H9L8.67447 1.24394H6.39575Z"
				fill={props.color ? props.color : '#45657B'}
			/>
		</svg>
	)
}
export default IconItalics
