export const styles = {
	textField: {
		'& .MuiOutlinedInput-root': {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'black',
				opacity: '23%',
			},
		},
	},
	chip: {
		paddingX: '0.5rem',
		color: 'black',
	},
	inputLabel: {
		color: 'black',
		opacity: '23%',
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background: 'linear-gradient(180deg, #12BBF182 51%, #12BBF182 100%)',
	},
}
