import React from 'react'

import { createFeatureDecisions } from 'config/featureDecisions'

import { Game } from 'models/gameModels'

import BattleShipsV1 from './v1'
import BattleShipsV2 from './v2'

function BattleShips({ game }: { game?: Game }) {
	if (createFeatureDecisions().enableRedesignedGamePages()) {
		return <BattleShipsV2 game={game} />
	} else {
		return <BattleShipsV1 game={game} />
	}
}

export default BattleShips
