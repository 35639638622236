import { FC, SVGProps } from 'react'

import { Button, ButtonProps, Typography } from '@mui/material'

import { styles } from './RectangleButton.styles'

export type Props = {
	fontSize?: string
	iconSvg?: string | FC<SVGProps<SVGElement>>
	invertBackground?: boolean
	isPrimary?: boolean
	onClickAction?: () => void
	text: string
	type?: string
	typographySx?: object
} & ButtonProps & { target?: string }

const RectangleButton = ({
	color = 'primary',
	disabled,
	iconSvg,
	fullWidth = false,
	href,
	onClickAction,
	text,
	type,
	fontSize,
	invertBackground = false,
	sx: customSx = {},
	...restProps
}: Props) => {
	const baseSx = invertBackground ? styles.invertBackgroundColor : {}
	return (
		<Button
			color={color}
			disabled={disabled}
			fullWidth={fullWidth}
			href={href}
			onClick={onClickAction}
			type={type}
			variant={'contained'}
			sx={{ ...baseSx, ...customSx }}
			{...restProps}
		>
			{iconSvg && typeof iconSvg === 'string' && (
				<img src={iconSvg} alt="icon" style={styles.svg} />
			)}

			<Typography fontSize={fontSize}>{text}</Typography>
		</Button>
	)
}

export default RectangleButton
