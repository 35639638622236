import React from 'react'

export interface Props {
	color?: string
}

const IconBullets = (props: Props) => {
	return (
		<svg
			width="15"
			height="12"
			viewBox="0 0 15 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.21622 4.8C0.543243 4.8 0 5.336 0 6C0 6.664 0.543243 7.2 1.21622 7.2C1.88919 7.2 2.43243 6.664 2.43243 6C2.43243 5.336 1.88919 4.8 1.21622 4.8ZM1.21622 0C0.543243 0 0 0.536 0 1.2C0 1.864 0.543243 2.4 1.21622 2.4C1.88919 2.4 2.43243 1.864 2.43243 1.2C2.43243 0.536 1.88919 0 1.21622 0ZM1.21622 9.6C0.543243 9.6 0 10.144 0 10.8C0 11.456 0.551351 12 1.21622 12C1.88108 12 2.43243 11.456 2.43243 10.8C2.43243 10.144 1.88919 9.6 1.21622 9.6ZM3.64865 11.6H15V10H3.64865V11.6ZM3.64865 6.8H15V5.2H3.64865V6.8ZM3.64865 0.4V2H15V0.4H3.64865Z"
				fill={props.color ? props.color : '#45657B'}
			/>
		</svg>
	)
}
export default IconBullets
