import React, { ReactElement, useState } from 'react'

import { Box, Button, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import arrowDown from 'assets/images/arrowDown.svg'
import arrowUp from 'assets/images/arrowUp.svg'

import { GameType } from 'enums/GameTypeEnum'

import { styles } from './GeneratedExampleDropdown.styles'
import { generatedExampleDropdownData } from './generatedExampleDropdownData'

export type Props = {
	currentResponses?: string[]
	customResponse?: ReactElement
	gameTypeEnum: GameType
	headerText: string
	isLoading?: boolean
}

const TEST_ID = 'generated-example-dropdown'

export const GeneratedExampleDropdown = ({
	gameTypeEnum,
	headerText,
	currentResponses,
	customResponse,
	isLoading,
}: Props) => {
	const [showAIexampleIdea, setShowAIexampleIdea] = useState(false)

	const gameId = gameTypeEnum.value as number

	const gameHeroData = generatedExampleDropdownData[gameId]

	const olStyle = {
		background: gameHeroData.bg,
		paddingTop: 0,
		margin: 0,
	}

	const loadingElement = () => (
		<Box
			data-testid={`${TEST_ID}-loading`}
			display="flex"
			flexDirection="column"
			justifyItems="center"
			alignItems="center"
			sx={{ marginTop: '1em' }}
		>
			<CircularProgress color="secondary" />
		</Box>
	)

	const buildResponseItem = (solution: string[]) => {
		return (
			<ol data-testid="gen-ai-sub-response-list" style={olStyle}>
				{solution.map((sol: string, idx: number) => (
					<Typography
						fontSize={16}
						color="black"
						component="li"
						key={`ai-sub-solution-${idx}`}
						sx={styles.solutionText}
						style={{
							margin: '10px 0 10px 0',
						}}
						variant="body1"
					>
						{sol}
					</Typography>
				))}
			</ol>
		)
	}

	return (
		<Box>
			<Typography>{headerText}</Typography>
			<Box borderBottom={2} borderColor={gameHeroData.bg}>
				<Button
					fullWidth
					color="inherit"
					data-testid={`${TEST_ID}-show-ai-example-button`}
					onClick={() => setShowAIexampleIdea(!showAIexampleIdea)}
				>
					<Box
						display={'flex'}
						justifyContent="space-between"
						width="100%"
					>
						<Typography
							color="black"
							sx={{ opacity: 0.87, fontSize: '12px' }}
						>
							View AI-Generated Example Ideas
						</Typography>
						<img
							alt="arrow"
							data-testid={`${TEST_ID}-arrow`}
							src={
								(showAIexampleIdea
									? arrowUp
									: arrowDown) as unknown as string
							}
						/>
					</Box>
				</Button>
			</Box>

			{showAIexampleIdea &&
				(isLoading
					? loadingElement()
					: currentResponses?.length && !customResponse
						? buildResponseItem(currentResponses)
						: customResponse)}
		</Box>
	)
}
