import { Typography } from '@mui/material'

export type Props = {
	text: string
}

export default function PopperHeader({ text }: Props) {
	return (
		<Typography
			variant="body1"
			sx={{
				fontSize: '14px',
				fontWeight: 500,
				fontColor: 'black',
			}}
		>
			{text}
		</Typography>
	)
}
