export interface ModalInputText {
	chipsQuestion?: string
	inputPlaceholder: string
	inputQuestion: string
}

export interface GeneratedExampleDropdownData {
	bg: string
	title: string
}

export interface GeneratedExampleDropdownCollection {
	[key: number]: GeneratedExampleDropdownData
}

export const generatedExampleDropdownData: GeneratedExampleDropdownCollection =
	{
		1: {
			title: 'Simon Says',
			bg: '#EDE7F6CC',
		},
		3: {
			title: 'Battle Ships',
			bg: '#FFE9C9',
		},
		4: {
			title: 'Dress Up',
			bg: '#E7F8FF',
		},
		5: {
			title: 'Leap Frog',
			bg: '#E4F4D8',
		},
		6: {
			title: 'Round Robin',
			bg: '#FFE8EE',
		},
		7: {
			title: 'Teeter Totter',
			bg: '#FFE8EE',
		},
	}
