import axios, { AxiosResponse } from 'axios'

import { CreateGame, Game, GetGames } from '../models/gameModels'
import { getSignedRequest } from '../utils/authUtils'

export const postGames = async (
	data: CreateGame
): Promise<AxiosResponse<Game>> => {
	const signed = await getSignedRequest('POST', 'games', JSON.stringify(data))

	if (signed) {
		return await axios({
			...signed,
			url: (process.env.REACT_APP_AWS_API_URL as string) + 'games',
			data,
			method: 'POST',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const getGames = async (
	gameSearch: GetGames
): Promise<AxiosResponse<Game[]>> => {
	let params = ''
	if (gameSearch.gameTitle != null) {
		params += `gameTitle=${gameSearch.gameTitle}`
	}
	if (gameSearch.gameTypeId != null) {
		if (params !== '') {
			params += '&'
		}
		params += `gameTypeId=${gameSearch.gameTypeId}`
	}

	const signed = await getSignedRequest('GET', `games?${params}`)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				`games?${params}`,
			method: 'GET',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const getGame = async (gameId: string): Promise<AxiosResponse<Game>> => {
	const signed = await getSignedRequest('GET', `games/${gameId}`)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				`games/${gameId}`,
			method: 'GET',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const editGame = async (
	// gameId: string,
	data: Game
): Promise<AxiosResponse<Game>> => {
	const signed = await getSignedRequest(
		'PUT',
		`games/${data.gameId}`,
		JSON.stringify(data)
	)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				`games/${data.gameId}`,
			data,
			method: 'PUT',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}

export const deleteGame = async (
	gameId: string
): Promise<AxiosResponse<Game>> => {
	const signed = await getSignedRequest('DELETE', `games/${gameId}`)

	if (signed) {
		return await axios({
			...signed,
			url:
				(process.env.REACT_APP_AWS_API_URL as string) +
				`games/${gameId}`,
			method: 'DELETE',
		})
	} else {
		throw Error('Unable to get signed request')
	}
}
