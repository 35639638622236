export const styles = {
	ButtonText: {
		'&:hover': {
			fontWeight: 'fontWeightBold',
		},
		textDecoration: 'none',
		fontSize: '1.4rem',
	},
	IconButton: {
		display: 'flex',
		flexDirection: 'row',
		gap: '0.25em',
		paddingLeft: 0,
	},
}
