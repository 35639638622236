export const styles = {
	keysImageBoxStyles: {
		width: '400px',
		marginBottom: '7rem',
	},
	peopleImageBoxStyles: {
		width: '800px',
	},
	keysImageStyles: {
		width: '100%',
	},
	peopleImageStyles: {
		width: '100%',
	},
	pageContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '5rem 0rem 5rem 0rem',
	},
	mainText: {
		color: 'text.primary',
		fontWeight: '600',
	},
	divider: {
		'&.MuiDivider-root': {
			borderWidth: '1px',
			margin: '4rem 0rem 4rem 0rem',
			width: '41rem',
			color: 'primary.main',
		},
	},
	btnConnect: {
		a: {
			borderRadius: 1,
			boxShadow: 'none',
			margin: '1.5rem auto 5rem',
			width: '30rem',
			fontSize: '28px',
			height: '65px',
		},
		textAlign: 'center',
	},
	subText: {
		color: 'text.primary',
		fontSize: '30px',
		fontWeight: 350,
		margin: '0rem 0rem 4rem 0rem',
	},
	BackArrowButton: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		gap: '0.25rem',
		whiteSpace: 'nowrap',
		textDecoration: 'none',
		marginBottom: '5rem',
	},
	backArrowText: {
		color: 'text.primary',
	},
}
