/* eslint-disable no-alert, no-console */
import React, { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, CircularProgress } from '@mui/material'

import { useGameContext } from 'contexts/GameProvider'

import BattleShips from 'pages/BattleShips'
import DressUp from 'pages/DressUp'
import ConnectFour from 'pages/LeapFrog/v1/ConnectFour'
import LeapFrog from 'pages/LeapFrog/v2/LeapFrog'
import MusicalChairs from 'pages/MusicalChairs/v2'
import RoundRobinPage from 'pages/RoundRobin'
import SimonSays from 'pages/SimonSays'
import TeeterTotter from 'pages/TeeterTotter'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import { LOCAL_STORAGE_KEYS } from '../../assets/constants'
import { MODAL_TYPES, useModalContext } from '../../contexts/ModalProvider'
import useAuth from '../../hooks/useAuth'
import { styles } from './PublishedGame.styles'

export const TEST_ID = 'published-game'

const GAME_TYPE_MAPPING = {
	[GAME_TYPE.SIMON_SAYS.value]: SimonSays,
	[GAME_TYPE.MUSICAL_CHAIRS.value]: MusicalChairs,
	[GAME_TYPE.BATTLE_SHIPS.value]: BattleShips,
	[GAME_TYPE.DRESS_UP.value]: DressUp,
	[GAME_TYPE.CONNECT_FOUR.value]: ConnectFour,
	[GAME_TYPE.LEAP_FROG.value]: LeapFrog,
	[GAME_TYPE.ROUND_ROBIN.value]: RoundRobinPage,
	[GAME_TYPE.TEETER_TOTTER.value]: TeeterTotter,
}

const PublishedGame = () => {
	const {
		game,
		requestGame,
		requestGameLoading,
		requestGameError,
		clearRequestGame,
	} = useGameContext()

	const { isAuthenticated, isAuthenticating } = useAuth()

	const { showModal } = useModalContext()

	const { gameId } = useParams()
	const navigate = useNavigate()

	const openLoginModal = useCallback(() => {
		showModal(MODAL_TYPES.SIGN_UP_MODAL)
	}, [showModal])

	// fetch game if id is present
	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (gameId && requestGame) {
				requestGame(gameId)
			}
		}, 500)

		return () => clearTimeout(delayDebounceFn)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gameId])

	useEffect(() => {
		if (!isAuthenticated && !isAuthenticating) {
			openLoginModal()
			localStorage.setItem(
				LOCAL_STORAGE_KEYS.REDIRECT_PATH,
				window.location.pathname
			)
		} else if (!gameId || requestGameError) {
			// if there is no game id or if there is an error fetching game, redirect
			navigate('/game-not-found')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		gameId,
		requestGameLoading,
		requestGameError,
		isAuthenticated,
		isAuthenticating,
	])

	useEffect(() => {
		if (game?.gameId && !game?.isAuthor && !game?.isParticipant) {
			navigate('/game-not-found')
		}
	}, [game?.gameId, game?.isAuthor, game?.isParticipant, navigate])

	// whenever we leave the PubishedGame page, we need to reset game data
	// because we are sharing the game context across the app
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => () => clearRequestGame && clearRequestGame(), [])

	// pick which page type we're loading
	const GamePage =
		!requestGameLoading && game && game.gameId === gameId
			? GAME_TYPE_MAPPING[game.gameTypeId]
			: null

	return (
		(GamePage && (
			<>
				<GamePage game={game} />
			</>
		)) || (
			<Box sx={styles.progressContainer}>
				<CircularProgress
					data-testid={`${TEST_ID}-loading`}
					sx={styles.circularProgressIcon}
				/>
			</Box>
		)
	)
}

export default PublishedGame
