import React from 'react'

import { Grid } from '@mui/material'
import Box from '@mui/material/Box'

import RectangleButtonComponent from 'components/RectangleButton'

import { styles } from './FilterRow.styles'

export const TEST_ID = 'published-ideas-filter'

export type Props = {
	buttonOnClick: () => void
}

export default function FilterRow(props: Props) {
	return (
		<Grid container item sx={styles.filterGrid}>
			<Box
				data-testid="published-ideas-search-input"
				sx={styles.searchBox}
			>
				<RectangleButtonComponent
					data-testid="create-custom-game-button"
					color="secondary"
					text={'CREATE CUSTOM GAME'}
					onClick={props.buttonOnClick}
				/>
			</Box>
		</Grid>
	)
}
