import React from 'react'

import { Box, Chip } from '@mui/material'

import { BusinessTopic, BusinessTopicType } from 'enums/TopicTypeEnum'

export const TEST_ID = 'business-topic-chip'

export interface Props {
	backgroundColor: string
	backgroundImage?: string
	borderRadius?: number
	editable: boolean
	height?: number
	innovationTopic: string | undefined
	setInnovationTopic: (val: BusinessTopicType) => void
}

const BusinessTopicChips = ({
	height,
	borderRadius,
	innovationTopic,
	setInnovationTopic,
	backgroundColor,
	backgroundImage,
	editable,
}: Props) => {
	return (
		<Box display="flex" columnGap="2rem" rowGap="1rem" flexWrap="wrap">
			{Object.values(BusinessTopic).map((value, i: number) => (
				<Chip
					disabled={!editable}
					key={i}
					label={value}
					data-testid={`${TEST_ID}__chip-topic-${i}`}
					variant={innovationTopic === value ? 'filled' : 'outlined'}
					clickable={true}
					onClick={(e) => {
						setInnovationTopic(
							(e.target as HTMLDivElement)
								.textContent as BusinessTopicType
						)
					}}
					sx={{
						paddingX: '0.5rem',
						color: 'black',
						'&.MuiChip-root:hover': {
							bgcolor:
								innovationTopic === value
									? backgroundColor
									: 'transparent',
						},
						backgroundColor:
							innovationTopic === value
								? backgroundColor
								: 'white',
						height,
						borderRadius,
						backgroundImage:
							innovationTopic === value
								? backgroundImage
								: 'white',
					}}
				/>
			))}
		</Box>
	)
}

export default BusinessTopicChips
