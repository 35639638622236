import React from 'react'
import { Control, FieldValues, useController } from 'react-hook-form'

import BaseDateTimePicker from 'components/Form/BaseDateTimePicker'
import type { BaseDateTimePickerProps } from 'components/Form/BaseDateTimePicker'

export const TEST_ID = 'default-test-id-form-date-time-picker'

export type Props = {
	control: Control<FieldValues>
	'data-testid'?: string
	label: string
	name: string
	required?: boolean
} & BaseDateTimePickerProps

const FormDateTimePicker = ({
	name,
	control,
	required,
	disabled,
	readOnly,
	'data-testid': dataTestid,
	...props
}: Props) => {
	const {
		field: { value, ...fieldProps },
		fieldState: { error, isTouched },
	} = useController({
		name,
		control,
		rules: { required },
		disabled,
	})

	return (
		<BaseDateTimePicker
			helperText={error && isTouched ? error.message : ''}
			error={error && isTouched}
			inputFormat="MM/dd/yyyy hh:mm a"
			// https://github.com/orgs/react-hook-form/discussions/8068
			// typically we could just set valueAsDate: true if we used
			// the register method, but the useController hook
			// doesn't support us passing in that parameter so we
			// have to manually set the date in the onChange handler
			value={value !== null ? new Date(value) : null}
			readOnly={readOnly}
			{...fieldProps}
			{...props}
			data-testid={dataTestid || TEST_ID}
		/>
	)
}

export default FormDateTimePicker
