export const styles = {
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background: 'linear-gradient(180deg, #F84BB2 0%, #FC6767 100%)',
	},
	CarouselName: {
		backgroundColor: 'rgba(245, 245, 245, 1)',
		padding: '15px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	PromptsCarouselName: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: 50,
		width: 'max-content',

		'@media (max-width: 600px)': {
			border: 'none',
			flexWrap: 'wrap',
		},
	},
	PromptsName: {
		fontSize: '14px',
		fontWeight: 500,
		color: 'rgba(0, 0, 0, 0.87)',
		padding: '10px',
		textTransform: 'uppercase',
		'@media (max-width: 1200px)': {
			fontSize: '12px',
		},
		'@media (max-width: 600px)': {
			borderRadius: 50,
			border: '1px solid rgba(0, 0, 0, 0.23)',
			marginTop: '5px',
			marginBottom: '5px',
		},
	},
	divider: {
		margin: '0px',
		display: 'block',
		'@media (max-width: 600px)': {
			display: 'none',
		},
	},
}
