export const styles = {
	invertBackgroundColor: {
		backgroundColor: '#FFF',
		outline: 'none',
		border: 'solid 2px',
		'&:hover': {
			backgroundColor: '#FFF',
			outline: 'none',
			border: 'solid 2px',
		},
	},
	menuRoot: {
		minWidth: '25vw',
	},
}
