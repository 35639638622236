import React, { forwardRef, useCallback } from 'react'

import LockRounded from '@mui/icons-material/LockRounded'
import { InputAdornment, TextField, TextFieldProps } from '@mui/material'

export const TEST_ID = 'default-test-id-text-field'

const readOnlyInputProps = {
	readOnly: true,
	endAdornment: (
		<InputAdornment position="end">
			<LockRounded />
		</InputAdornment>
	),
}

export type Props = {
	'data-testid'?: string
	multiline?: boolean
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	onChangeValue?: (val: string) => void
	readOnly?: boolean
} & TextFieldProps

const BaseTextField = forwardRef<HTMLInputElement, Props>(
	(
		{
			multiline,
			readOnly,
			'data-testid': dataTestId,
			onChangeValue,
			onChange,
			...props
		},
		ref
	) => {
		const onChangeValueHandler = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				onChangeValue && onChangeValue(e.target.value)
			},
			[onChangeValue]
		)
		return (
			<TextField
				inputRef={ref}
				fullWidth
				inputProps={{ 'data-testid': dataTestId || TEST_ID }}
				multiline={multiline}
				size="small"
				variant="filled"
				InputProps={{
					...((readOnly && readOnlyInputProps) ||
						props.InputProps ||
						{}),
				}}
				onChange={onChangeValue ? onChangeValueHandler : onChange}
				onKeyDown={(e) =>
					e.key === 'Enter' &&
					!(e.target instanceof HTMLTextAreaElement) &&
					e.preventDefault()
				}
				{...props}
			/>
		)
	}
)

BaseTextField.displayName = 'BaseTextField'

export default BaseTextField
