const textStyle = {
	color: 'secondary.main',
	fontWeight: '400',
	width: '100%',
	fontSize: '1.6rem',
}

export const styles = {
	btnConnect: {
		a: {
			borderRadius: 1,
			boxShadow: 'none',
			marginTop: '1.5rem',
		},
	},
	callToActionText: {
		...textStyle,
		fontSize: '3.4rem',
		'@media (max-width: 600px)': {
			marginTop: '57px',
			fontSize: '2.4rem',
		},
	},
	ctaCaption: {
		...textStyle,
		fontSize: '2.4rem',
		'@media (max-width: 600px)': {
			fontSize: '1.4rem',
		},
	},
	ctaContainer: {
		display: 'flex',
		flexDirection: 'row',
		margin: '0 auto',
		gap: '14rem',
		'@media (max-width: 1024px)': {
			flexDirection: 'column',
			alignItems: 'center',
			gap: '6.6rem',
		},
		'@media (max-width: 600px)': {
			margin: '1rem auto',
			gap: '0rem',
			svg: {
				width: '100%',
			},
		},
	},
	ctaImage: {
		maxWidth: '100%',
	},
}
