import { memo } from 'react'

import PromptBox, { TEST_ID } from './PromptBox'
import type { Props as PromptBoxProps } from './PromptBox'

const PromptBoxComponent = memo(PromptBox)

export { TEST_ID, PromptBoxProps }

export default PromptBoxComponent
