import React from 'react'
import { Control, FieldValues, useController } from 'react-hook-form'

import BaseCheckBoxField from 'components/Form/BaseCheckBoxField'
import type { BaseCheckboxFieldProps } from 'components/Form/BaseCheckBoxField'

export const TEST_ID = 'default-test-id-form-check-box'

export type Props = {
	control: Control<FieldValues>
	'data-testid'?: string
	disabled?: boolean
	label: string
	name: string
	required?: boolean
} & BaseCheckboxFieldProps

const FormCheckBoxField = ({
	name,
	control,
	required,
	disabled,
	label,
	'data-testid': dataTestid = TEST_ID,
	...props
}: Props) => {
	const {
		field: { value, ...rest },
	} = useController({
		name,
		control,
		rules: { required },
		disabled,
	})

	return (
		<BaseCheckBoxField
			label={label}
			checked={value}
			{...props}
			data-testid={dataTestid}
			{...rest}
		/>
	)
}

export default FormCheckBoxField
