import React from 'react'

import { Box, Divider, Grid, Link, Typography } from '@mui/material'

import RectangleButtonComponent from 'components/RectangleButton'

import { cta } from 'assets/callToActionParams'
import twoKeysIcon from 'assets/images/2keys.svg'
import peopleIcon from 'assets/images/People.svg'
import backArrow from 'assets/images/backArrow.svg'

import { styles } from './LoggedOutPage.styles'

const LoggedOutPage = () => {
	const testid = 'logged__out__page'
	return (
		<>
			<Grid data-testid={`${testid}`} sx={styles.pageContainer}>
				<Box
					data-testid={`${testid}__key__image`}
					style={styles.keysImageBoxStyles}
				>
					<img
						alt="2-keys"
						style={styles.keysImageStyles}
						src={twoKeysIcon as unknown as string}
					></img>
				</Box>
				<Typography
					data-testid={`${testid}__heading__text__pt1`}
					sx={styles.mainText}
					variant="h3"
				>
					Schedule a Slalom facilitator to host a
				</Typography>
				<Typography
					data-testid={`${testid}__heading__text__pt2`}
					sx={styles.mainText}
					variant="h3"
				>
					Brainstorm workshop for access to this page
				</Typography>
				<Divider variant="middle" sx={styles.divider}></Divider>
				<Typography
					data-testid={`${testid}__subheading__text`}
					sx={styles.subText}
				>
					Unlocking the full innovative potential of your team starts
					here{' '}
				</Typography>
				<Box data-testid={`${testid}__cta`} sx={styles.btnConnect}>
					<RectangleButtonComponent
						data-testid={`${testid}__cta__button`}
						href={`mailto:arclabs@slalom.com?${Object.entries(cta)
							.map(([k, v]) => `${k}=${v}`)
							.join('&')}`}
						target="_blank"
						text={"Let's Connect"}
					/>
				</Box>
				<Link
					color="inherit"
					data-testid={`${testid}_back_arrow_button`}
					href="/"
					sx={styles.BackArrowButton}
				>
					<img
						alt="Back To Home Arrow"
						src={backArrow as unknown as string}
					/>
					<Typography sx={styles.backArrowText}>
						Back to previous page
					</Typography>
				</Link>
				<Box
					data-testid={`${testid}__people__image`}
					style={styles.peopleImageBoxStyles}
				>
					<img
						style={styles.peopleImageStyles}
						alt="logged-out-page-icon"
						src={peopleIcon as unknown as string}
					></img>
				</Box>
			</Grid>
		</>
	)
}

export default LoggedOutPage
