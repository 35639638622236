import features, { FEATURE_FLAGS } from './featureFlags'

export function createFeatureDecisions() {
	return {
		allowDebugLevelLogging(): boolean {
			return features[FEATURE_FLAGS.ALLOW_DEBUG_LEVEL_LOGGING]
		},
		enableRedesignedGamePages(): boolean {
			return features[FEATURE_FLAGS.SHOW_REDESIGNED_GAMEPAGES]
		},
	}
}
