export const BusinessTopic = {
	Marketing: 'Marketing',
	Sales: 'Sales',
	CustomerExperience: 'Customer Experience',
	Operations: 'Operations',
} as const
export type BusinessTopicType =
	| (typeof BusinessTopic)[keyof typeof BusinessTopic]
	| ''

export const CustomerJourneyTopic = {
	ConsideringPurchase: 'Considering a Purchase',
	CompletingPurchase: 'Completing a Purchase',
	UtilizingPurchase: 'Utilizing a Purchase',
	ReturningPurchase: 'Returning a Purchase',
	ReferringPurchase: 'Referring a Purchase',
} as const
export type CustomerJourneyTopicType =
	| (typeof CustomerJourneyTopic)[keyof typeof CustomerJourneyTopic]
	| ''

export const TOPIC_DROPDOWN_VALUES = Object.values(CustomerJourneyTopic)

export type TopicType =
	| (typeof BusinessTopic)[keyof typeof BusinessTopic]
	| (typeof CustomerJourneyTopic)[keyof typeof CustomerJourneyTopic]
	| ''
