export const styles = {
	list: {
		width: '100%',
	},
	Divider: {
		color: 'primary.main',
		borderBotttomWidth: 'thick',
		my: 4,
	},
}
