import { memo } from 'react'

import BaseDropdown, { CLEAR_VALUE_OPTION, TEST_ID } from './BaseDropdown'
import type { Props as BaseDropdownProps } from './BaseDropdown'

const BaseDropdownComponent = memo(BaseDropdown)

export { CLEAR_VALUE_OPTION, TEST_ID, BaseDropdownProps }

export default BaseDropdownComponent
