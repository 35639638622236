import { SxProps, Theme } from '@mui/material'

const styles: Record<string, SxProps<Theme>> = {
	companyTextField: {
		'& .MuiInputBase-root': {
			height: '4rem',
			padding: 0,
			textAlign: 'center',
		},
		height: '4rem',
		width: '100%',
	},
	container: {
		padding: '2.8rem 4.8rem',
	},
	containerLayout: {
		display: 'grid',
		gridAutoColumns: 'columns',
		gridAutoFlow: '1fr',
	},
	dropdownContainer: {
		alignItems: 'center',
		display: 'flex',
		gap: '1.7rem',
		width: '100%',
		mt: '1rem',
	},
	genAiUnSelectableContent: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		padding: '1.4rem',
		width: '100%',
	},
	genAiPreviewContent: {
		'&:hover': {
			backgroundColor: 'background.hover',
			cursor: 'pointer',
		},
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		padding: '1.4rem',
		width: '100%',
	},
	genAiPreviewImg: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	genAiPreviewText: {
		color: 'secondary.main',
		fontWeight: 'fontWeightRegular',
		textAlign: 'center',
		textDecoration: 'underline',
		textUnderlineOffset: '.22em',
	},
	genAiResponseContainer: {
		borderColor: 'secondary.main',
		border: '1px solid',
		display: 'flex',
		justifyContent: 'center',
		marginTop: '.5rem',
		width: '100%',
		height: '30rem',
		overflowX: 'visible',
		overflowY: 'scroll',
	},
	genAiResponseContent: {
		width: '100%',
		height: '30rem',
	},
	generateScenarioBtn: {
		'&:hover': {
			backgroundColor: 'secondary.main',
			color: '#FFF',
		},
		backgroundColor: 'secondary.main',
		borderRadius: 0,
		color: '#FFF',
		pl: '2.2rem',
		pr: '2.2rem',
	},
	ideationResponseContainer: {
		justifyContent: 'space-between',
		mt: '1rem',
		width: 0.85,
	},
	ideationResponseTitle: {
		color: 'primary.main',
		pb: '1.1rem',
		fontWeight: 'bold',
	},
	promptContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '2.2rem',
	},
	scenarioContainer: {
		display: 'flex',
		gap: '6.9rem',
	},
	scenarioDesktop: {
		justifyContent: 'space-between',
		mainPromptContainer: {
			width: 'calc(20% - 2rem)',
		},
		marginBottom: '2rem',
		prompts: {
			flexWrap: 'nowrap',
			justifyContent: 'space-between',
			marginRight: 0,
			overflowX: 'visible',
			position: 'relative',
			promptContainer: {
				img: {
					marginTop: '1rem',
				},
				textAlign: 'center',
				width: 'calc(25% - 2rem)',
			},
			width: 'calc(80% - 2rem)',
		},
	},
	scenarioMobile: {
		mainPromptContainer: {
			margin: '0 2rem 4rem',
			minWidth: '100%',
		},
		prompts: {
			flexWrap: 'nowrap',
			overflowX: 'scroll',
			position: 'relative',
			promptContainer: {
				img: {
					marginTop: '1rem',
				},
				margin: '0 0 0 2rem',
				minWidth: '22rem',
				textAlign: 'center',
				width: 'calc(25% - 2rem)',
			},
		},
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		mb: '2rem',
	},
	sectionHeader: {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.6rem',
	},
	sectionHeaderContent: {
		color: 'primary.main',
		display: 'flex',
		flexDirection: 'row',
		gap: '1.2rem',
	},
	sectionHeaders: {
		display: 'flex',
		pb: '2.4rem',
	},
	sectionText: {
		color: 'text.secondary',
		fontStyle: 'italic',
		maxHeight: '25rem',
	},
	solutionText: {
		color: 'text.secondary',
		fontStyle: 'italic',
		margin: 0,
		paddingBottom: '1.8rem',
	},
	submitButton: {
		'&:disabled': {
			backgroundColor: 'primary.disabled',
			color: '#FFF',
		},
		'&:hover': {
			backgroundColor: 'primary.main',
			color: '#FFF',
		},
		backgroundColor: 'primary.main',
		borderRadius: 0,
		color: '#FFF',
		width: '15.5rem',
	},
	submitContainer: {
		alignItems: 'center',
		display: 'flex',
		gap: '1.4rem',
	},
	userResponseContainer: {
		display: 'flex',
		gap: '4.6rem',
		width: '100%',
	},
	userResponseInput: {
		alignItems: 'stretch',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'end',
		width: '100%',
	},
}

styles.genAiPreviewTextDisabled = {
	...styles.genAiPreviewText,
	color: 'text.disabled',
}

styles.genAiPreviewContentDisabled = { ...styles.genAiPreviewContent }
if ('&:hover' in styles.genAiPreviewContentDisabled) {
	delete styles.genAiPreviewContentDisabled['&:hover']
}

export { styles }
