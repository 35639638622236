import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import Search from '@mui/icons-material/Search'
import {
	Box,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Typography,
	useTheme,
} from '@mui/material'
import { debounce, isEmpty, omitBy } from 'lodash'

import { Idea, SubmittedIdeaCSV } from 'models/ideaModels'

import { getInitialIdeas } from 'services/ideas.service'

import { ButtonWithIcon } from 'components/ButtonWithIcon/ButtonWithIcon'
import { FormDropdown } from 'components/Form'

import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'

import ExportButton from '../ExportButton/ExportButton'
import { styles } from './FilterSection.styles'

export const TEST_ID = 'gen-ai-question-and-filter-section'

type Props = {
	displayCount?: number
	ideas?: Idea[]
	selectedIdeas?: Idea[]
	totalCount?: number
}

export default function FilterSection({
	ideas,
	displayCount,
	selectedIdeas,
	totalCount,
}: Props) {
	const [filters, setFilters] = useState<
		Record<
			string,
			{
				label: string
				options: string[]
			}
		>
	>({})
	const [viewSearch, setViewSearch] = useState(false)

	const { data, requestApi, loading } = useApi(getInitialIdeas)
	const { isAuthenticated, user } = useAuth()

	const [searchParams, setSearchParams] = useSearchParams()

	const theme = useTheme()

	type FormSchema = {
		author?: (string | undefined)[]
		authorIdeation?: string
		company?: (string | undefined)[]
		gameTitle?: (string | undefined)[]
		genAIQuestion?: string
	}

	const { control, setValue, watch } = useForm<FormSchema>({
		defaultValues: {
			company: searchParams.getAll('company') ?? [],
			gameTitle: searchParams.getAll('gameTitle') ?? [],
			author: searchParams.getAll('author') ?? [],
			genAIQuestion: searchParams.get('genAIQuestion') ?? '',
			authorIdeation: searchParams.get('authorIdeation') ?? '',
		},
		mode: 'onChange',
		reValidateMode: 'onChange',
	})

	useEffect(() => {
		const subscription = watch(
			debounce((data) => {
				setSearchParams(omitBy(data, isEmpty), { replace: true })
			}, 500)
		)
		return () => subscription.unsubscribe()
	}, [watch, setSearchParams])

	useEffect(() => {
		requestApi()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const { gameTitles = [], authors = [] } = data ?? {}
		const updatedAuthors =
			!authors?.length && isAuthenticated && user?.name
				? [{ authorName: `${user.name}` }]
				: authors

		setFilters({
			// TODO: Implement filtering submitted ideas by company
			// https://slalom.atlassian.net/browse/BRAIN-817
			//
			// company: {
			// 	label: 'Company',
			// 	options: Object.values(companies).map(({ company }) => {
			// 		if (company.includes(' ')) {
			// 			const splitCompany: string[] = company.split(' ')
			// 			return splitCompany
			// 				.map(
			// 					(cmpny) =>
			// 						cmpny.charAt(0).toUpperCase() +
			// 						cmpny.slice(1)
			// 				)
			// 				.join(' ')
			// 		}

			// 		return company.charAt(0).toUpperCase() + company.slice(1)
			// 	}),
			// },
			author: {
				label: 'Author',
				options: Object.values(updatedAuthors).map(
					({ authorName }) => authorName
				),
			},
			gameTitle: {
				label: 'Custom Game',
				options: Object.values(gameTitles).map(
					({ gameTitle }) => gameTitle
				),
			},
		})
	}, [data, isAuthenticated, user])

	// TODO: Complete search filter implementation for submitted ideas
	// https://slalom.atlassian.net/browse/BRAIN-1115
	const searchInput = debounce((search) => {
		setValue('authorIdeation', search)
	}, 500)

	return (
		<Box component="form" sx={styles.gridContainer}>
			<Grid container sx={styles.filterGrid} maxWidth={'xl'}>
				{viewSearch && (
					<Grid container item>
						<Box
							data-testid={`${TEST_ID}-search-input-container`}
							sx={styles.searchBox}
						>
							<OutlinedInput
								name="authorIdeation"
								defaultValue={searchParams.get(
									'authorIdeation'
								)}
								startAdornment={
									<InputAdornment position="start">
										<IconButton>
											<Search />
										</IconButton>
									</InputAdornment>
								}
								inputProps={{
									'data-testid': `${TEST_ID}-search-input`,
								}}
								placeholder="Search..."
								onChange={({ target }) =>
									searchInput(target.value)
								}
								sx={styles.searchBarStyle}
							/>
						</Box>
					</Grid>
				)}
				<Grid container item sx={styles.filterSection}>
					<ButtonWithIcon
						onClickAction={() => setViewSearch(!viewSearch)}
						Icon={
							viewSearch ? (
								<CloseIcon sx={styles.iconButtonSvg} />
							) : (
								<Search sx={styles.iconButtonSvg} />
							)
						}
						sx={{
							...styles.iconButton,
							border: `${theme.palette.secondary.main} 1px solid`,
						}}
					/>
					{!loading &&
						Object.entries(filters).map(([name, filter], index) => {
							return (
								<Box key={name}>
									<FormDropdown
										control={control}
										data-testid={`${TEST_ID}-dropdown-${index}`}
										name={name}
										label={filter.label}
										required={false}
										options={[...filter.options]}
										readOnly={false}
									/>
								</Box>
							)
						})}
					{displayCount && totalCount && (
						<Grid container item sx={styles.itemCountSection}>
							<Typography
								data-testid={`${TEST_ID}-idea-count`}
								sx={styles.itemCountText}
							>
								{`${selectedIdeas?.length ? 'Selected' : 'Visible'} Ideas: ${selectedIdeas?.length || displayCount} of ${totalCount}`}
							</Typography>
						</Grid>
					)}
					<ExportButton
						data={
							(selectedIdeas?.length
								? selectedIdeas
								: ideas) as SubmittedIdeaCSV[]
						}
					/>
				</Grid>
			</Grid>
		</Box>
	)
}
