import React from 'react'

export interface Props {
	height?: number
	number: string
	width?: number
}

export const NumberedCircleIcon = ({ number, height, width }: Props) => {
	return (
		<>
			<svg
				fill="none"
				height={height ?? '26'}
				viewBox={`0 0 ${height ?? 26} ${width ?? 26}`}
				width={width ?? '26'}
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="13" cy="13" r="12.5" stroke="#CF4F27" />
				<g
					fontFamily="sans-serif"
					fontSize="2"
					fontWeight="bold"
					textAnchor="middle"
				>
					<text
						dy=".35em"
						fill="#CF4F27"
						fontFamily="sans-serif"
						fontSize="17"
						textAnchor="middle"
						x="50%"
						y="50%"
					>
						{number ?? 'X'}
					</text>
				</g>
			</svg>
		</>
	)
}
