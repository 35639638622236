export const styles = {
	clearValueOption: {
		fontWeight: '700',
		color: 'error.main',

		'&:hover': {
			backgroundColor: 'error.disabled',
		},
	},
}
