export const styles = {
	ideaCheckbox: {
		color: '#6A6A6A !important',
		'&.Mui-checked': {
			color: '#6A6A6A !important',
		},
	},
	actionIcon: {
		color: 'primary.dark',
	},
	basicChip: { color: '#000000', bgcolor: '#EBEBEB' },
	ideaRowHeader: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '1rem',
	},
	prototypeButton: {
		marginLeft: 'auto',
	},
}
