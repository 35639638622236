import { JSONContent } from 'html-to-json-parser/dist/types'

import { SubmittedIdeaCSV } from 'models/ideaModels'

import { ModalType } from 'contexts/ModalProvider'
import { ToastContents } from 'contexts/ToastProvider'

import { createBoardAndStickies } from 'services/miroService'

import { ToastSeverity } from 'enums/ToastSeverityEnum'

const BATTLE_SHIPS_PROMPTS = [
	'Your Response to the Disruption',
	'Competitor Response to Your Disruption',
	'Your Response to Competitors',
]

export const removeHTMLFormatting = (html: string) => {
	// strip out italics and underlines from html
	return html
		.replaceAll('<em>', '')
		.replaceAll('</em>', '')
		.replaceAll('<u>', '')
		.replaceAll('</u>', '')
}

export const parseJSONToCSV = (json: JSONContent, skipBolds = true) => {
	let csv = ''
	if (!json.content) return ''

	json.content.forEach((tree: JSONContent | string) => {
		if (typeof tree === 'string') {
			if (json.type === 'a') {
				// put links in format "[label](url)"
				// eslint-disable-next-line  @typescript-eslint/no-explicit-any
				csv += `\r\n[${tree}](${(json.attributes as any).href})`
			} else if (tree !== '\n') {
				// add string content to csv
				csv += `\r\n${tree}`
			}
			// } else if (tree.type !== 'b' && tree.type !== 'br') {
		} else if (
			(tree.type !== 'br' && tree.type !== 'b') ||
			(tree.type === 'b' && !skipBolds)
		) {
			// skip bolds and breaks, then make recursive call
			csv += parseJSONToCSV(tree)
		}
	})

	return csv
}

export const splitIdeasIntoSeparateRows = (
	ideas: SubmittedIdeaCSV[]
): SubmittedIdeaCSV[] => {
	const expandedIdeas: SubmittedIdeaCSV[] = []
	for (let i = 0; i < ideas.length; i++) {
		let promptTitle = ''
		const idea = ideas[i]
		const ideationArray = idea.authorIdeation.split('\r\n')
		ideationArray.shift()
		ideationArray.forEach((ideationLine) => {
			// Detect if the current line is a battle ships prompt
			const newPromptTitle =
				BATTLE_SHIPS_PROMPTS.indexOf(ideationLine) !== -1
					? ideationLine
					: ''

			if (newPromptTitle) {
				// If we are on a prompt line, reassign the variable to the current line but don't add a row
				promptTitle = newPromptTitle
			} else {
				// create new "idea" row with identical values except for the ideation line
				expandedIdeas.push({
					...idea,
					promptTitle,
					authorIdeation: ideationLine,
				})
			}
		})
	}

	return expandedIdeas
}

export function getCsvString(
	headers: string[],
	transformedIdeas: SubmittedIdeaCSV[]
): string {
	let newTransformedCSV = ''
	for (const key in transformedIdeas) {
		const obj = transformedIdeas[key] as SubmittedIdeaCSV
		// takes an object type and produces a string or numeric literal union of its keys
		type ObjectKey = keyof typeof obj
		const row = []

		for (const key of headers) {
			const objKey = key as ObjectKey
			let field = obj[objKey]
			if (typeof obj[objKey] !== 'undefined') {
				field = `${obj[objKey]}` === 'null' ? '' : `${obj[objKey]}`
				field = field.replaceAll('"', "'")

				// quote the fields in case there's a "," within the field.
				field = '"' + field + '"'
				row.push(field)
			} else {
				row.push('""')
			}
		}
		const line = row.join(',')
		newTransformedCSV = newTransformedCSV + '\n' + line
	}

	const csv = headers + newTransformedCSV

	return csv
}

export const downloadToMiro = async (
	tokenToUse: string,
	setMiroExporting: (value: React.SetStateAction<boolean>) => void,
	headers: string[],
	transformedIdeas: SubmittedIdeaCSV[],
	showAlert?: ({
		title,
		bodyText,
		severity,
		objectType,
	}: ToastContents) => void,
	showModal?: (modalType: ModalType, modalProps?: object) => void
) => {
	setMiroExporting(true)
	try {
		const board = await createBoardAndStickies(
			tokenToUse,
			transformedIdeas,
			headers
		)
		if (showModal) showModal(board.viewLink)
	} catch (err) {
		if (showAlert) {
			showAlert({
				title: 'Error',
				severity: ToastSeverity.ERROR,
				bodyText:
					'Some or all of your ideas were not saved to Miro. Please try again.',
			})
		}
	} finally {
		setMiroExporting(false)
	}
}

export const exportIdeas = (
	itemIndex: number,
	headers: string[],
	transformedIdeas: SubmittedIdeaCSV[],
	getAuth?: () => () => void
): string => {
	const num = +itemIndex

	if (num === 0 && transformedIdeas) {
		const csv = getCsvString(headers, transformedIdeas)

		const blob = new Blob([csv], {
			type: 'text/csv',
		})
		const url = window.URL.createObjectURL(blob)
		// creating an anchor to export seems weird to me.
		const anchor = document.createElement('a')
		anchor.setAttribute('href', url)
		anchor.setAttribute('download', 'exportedIdeas.csv')
		anchor.click()
		return 'csv'
	} else {
		if (getAuth) getAuth()
		return 'miro'
	}
}
