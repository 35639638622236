import React from 'react'

import { Typography } from '@mui/material'

const Section2Title = () => {
	return (
		<Typography marginY={'2rem'} variant={'overline'}>
			2. GENAI PROMPTS
		</Typography>
	)
}

export default Section2Title
