import { memo } from 'react'

import RectangleButton from './RectangleButton'
import type { Props as RectangleButtonProps } from './RectangleButton'

const RectangleButtonComponent = memo(RectangleButton)

export { RectangleButtonProps }

export default RectangleButtonComponent
