export const styles = {
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background:
			'linear-gradient(180deg, rgba(0, 37, 230, 0.9) -12.94%, rgba(39, 35, 249, 0.5) 100%)',
	},
	editPromptsMode: {
		'.MuiInputBase-input': {
			fontWeight: '500',
			color: 'rgba(0, 0, 0, 0.87)',
			fontSize: {
				xs: 16,
				sm: 20,
			},
		},
		'.MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'.MuiOutlinedInput-root': {
			borderRadius: '0',
			borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
			backgroundColor: '#FFFFFF',
		},
	},
	promptsText: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontWeight: 500,
		fontSize: {
			xs: 16,
			sm: 20,
		},
	},
}
