import { Game } from 'models/gameModels'

export const requestGameLogic = (
	gameId: string,
	games: Game[] | undefined,
	setGame: (game: Game) => void,
	requestGameIfNecessary: (...args: string[]) => Promise<void>
): Promise<void> => {
	if (games) {
		const matchingGame = games.find((game) => game.gameId === gameId)
		if (matchingGame) {
			setGame(matchingGame)
			return Promise.resolve()
		} else {
			return requestGameIfNecessary(gameId)
		}
	} else {
		return requestGameIfNecessary(gameId)
	}
}
