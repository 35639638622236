export const styles = {
	mainBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		py: '6rem',
	},
	deletedText: {
		color: 'secondary.main',
		fontSize: '4.5rem',
		mt: 4,
		fontStyle: 'italic',
	},
	btnContainer: {
		a: {
			borderRadius: 0,
			boxShadow: 'none',
			margin: '4.5rem auto 4rem',
		},
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'row',
		gap: '30px',
	},
	ButtonSecondary: {
		width: '316px',
		height: '55px',
		fontSize: '24px',
	},
	quoteText: {
		color: 'secondary.main',
		display: 'flex',
		fontSize: '25px',
		justifyContent: 'center',
		textAlign: 'center',
		width: '100%',
	},
}
