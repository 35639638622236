import React, { ReactNode } from 'react'

import { SubmitIdeasProvider } from 'submitideasproviders/SubmitIdeasContext'
import { IdeationThemeProvider } from 'theme'

import { AuthProvider } from 'contexts/AuthProvider'
import GameProvider from 'contexts/GameProvider'
import { IdeaStreakProvider } from 'contexts/IdeaStreakProvider'
import { MixpanelProvider } from 'contexts/MixpanelProvider'
import ModalProvider from 'contexts/ModalProvider'
import { ToastProvider } from 'contexts/ToastProvider'

export const ContextProviders = ({ children }: { children: ReactNode }) => {
	return (
		<div data-testid="ContextProviders">
			<ToastProvider>
				<SubmitIdeasProvider>
					<AuthProvider>
						<MixpanelProvider>
							<IdeationThemeProvider>
								<GameProvider>
									<IdeaStreakProvider>
										<ModalProvider>
											{children}
										</ModalProvider>
									</IdeaStreakProvider>
								</GameProvider>
							</IdeationThemeProvider>
						</MixpanelProvider>
					</AuthProvider>
				</SubmitIdeasProvider>
			</ToastProvider>
		</div>
	)
}
