export const styles = {
	rectangle: {
		width: '176px',
		border: '1px solid #0000003B',
		borderRadius: '4px',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '8px',
		flex: 1,
		'@media (max-width: 1000px)': {
			width: '100%',
			flex: 1,
		},
	},
	container: {
		alignItems: 'center',
	},
	circularProgressIcon: {
		color: 'secondary.main',
	},
	loadingText: {
		paddingLeft: '10px',
	},
	text: {
		padding: '10px',
	},
}
