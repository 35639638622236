import React from 'react'
import {
	Control,
	FieldValues,
	RegisterOptions,
	useController,
} from 'react-hook-form'

import BaseTextField from 'components/Form/BaseTextField'
import type { BaseTextFieldProps } from 'components/Form/BaseTextField'

export const TEST_ID = 'default-test-id-form-text-field'

export type Props = {
	control: Control<FieldValues>
	'data-testid'?: string
	disabled?: boolean
	label?: string
	name: string
	readOnly?: boolean
	required?: boolean
	rules?: RegisterOptions<FieldValues>
} & Omit<BaseTextFieldProps, 'value' | 'ref'>

const FormTextField = ({
	name,
	label,
	helperText,
	control,
	required,
	disabled,
	readOnly,
	rules = {},
	'data-testid': dataTestid,
	...props
}: Props) => {
	const {
		field: fieldProps,
		fieldState: { error, isTouched },
	} = useController({
		name,
		control,
		rules: {
			required:
				(required && {
					value: true,
					message: 'This field is required',
				}) ||
				undefined,
			...rules,
		},
		disabled,
		defaultValue: '',
	})

	return (
		<BaseTextField
			label={label}
			helperText={
				(!readOnly && error && isTouched ? error.message : null) ||
				helperText
			}
			error={!readOnly && error && isTouched}
			readOnly={readOnly}
			{...fieldProps}
			{...props}
			data-testid={dataTestid || TEST_ID}
			sx={{
				'& .MuiInputLabel-root': {
					fontWeight: 'normal', // Set the fontWeight to normal for the label
				},
			}}
		/>
	)
}

export default FormTextField
