import { memo } from 'react'

import FormDropdown, { TEST_ID } from './FormDropdown'
import type { Props as FormDropdownProps } from './FormDropdown'

const FormDropdownComponent = memo(FormDropdown)

export { TEST_ID, FormDropdownProps }

export default FormDropdownComponent
