import * as React from 'react'
import { useRef, useState } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Typography } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { Box } from '@mui/system'

import { styles } from './SplitButton.styles'

// there's no text on this button to grab it by
export const LITTLE_BUTTON_TEST_ID = 'arc-split-button-little-button'
// so we don't have to worry about the text when we test
export const MENU_ITEM_TEST_ID_PREFIX = 'arc-split-button-menu-item-prefix'

export type Props = {
	// if the big button is clicked,
	// the default item index
	// will be passed to the onClickAction
	defaultItemIndex: number
	invertBackground?: boolean
	// drop-down list.
	// when an item is clicked,
	// its index will be passed to the onClickAction
	itemList?: Array<string>
	onClickAction: (itemIndex: number) => void
	text?: string
} & ButtonProps & { target?: string }

export const SplitButton = ({
	color = 'primary',
	disabled = false,
	fullWidth = false,
	text,
	invertBackground = false,
	itemList,
	defaultItemIndex = 0,
	onClickAction,
	sx: customSx = {},
}: Props) => {
	const baseSx = invertBackground ? styles.invertBackgroundColor : {}
	const [open, setOpen] = useState(false)
	const anchorRef = useRef<HTMLDivElement>(null)

	if (itemList === undefined || !itemList || itemList.length === 0) {
		disabled = true
	}

	const handleClick = () => {
		onClickAction(defaultItemIndex)
	}

	const handleMenuItemClick = (index: number) => {
		setOpen(false)
		onClickAction(index)
	}

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event: Event) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return
		}

		setOpen(false)
	}

	return (
		<div>
			<ButtonGroup
				variant="contained"
				ref={anchorRef}
				color={color}
				fullWidth={fullWidth}
				disabled={disabled}
				sx={{ ...baseSx, ...customSx }}
			>
				<Button onClick={handleClick} fullWidth={fullWidth}>
					<Typography sx={{ textTransform: 'capitalize' }}>
						{text}
					</Typography>
				</Button>
				<Button
					data-testid={LITTLE_BUTTON_TEST_ID}
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1,
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom'
									? 'center top'
									: 'center bottom',
						}}
					>
						<Paper>
							<Box
								style={{
									width: anchorRef?.current?.clientWidth,
								}}
							>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList
										id="split-button-menu"
										data-testid="split-button-menu"
										autoFocusItem
									>
										{itemList?.map((option, index) => (
											<MenuItem
												key={option}
												data-testid={`${MENU_ITEM_TEST_ID_PREFIX}-${index}`}
												onClick={() =>
													handleMenuItemClick(index)
												}
											>
												{option}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Box>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	)
}
