export const styles = {
	bottomBorder: {
		backgroundColor: 'secondary.main',
		borderRadiusBottomLeft: '.3rem',
		borderRadiusBottomRight: '.3rem',
		bottom: 0,
		height: '.3rem',
		left: '-.1rem',
		position: 'absolute',
		width: 'calc(100% + .2rem)',
	},
	card: {
		border: '.1rem solid',
		borderColor: 'secondary.main',
		borderRadius: '0.4rem 0.4rem 0 0',
		borderStyle: 'solid',
		borderWidth: '.1rem',
	},
	cardContentReset: {
		'&:last-child': {
			paddingBottom: 0,
		},
		height: '100%',
		margin: 0,
		overflowY: 'visible',
		padding: 0,
		position: 'relative',
	},
	circularProgressIcon: {
		color: 'secondary.main',
		size: '2.8rem',
	},
	container: {
		maxWidth: '100%',
		minHeight: '9.8rem',
		position: 'relative',
		width: '42rem',
	},
	contentContainer: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'center',
		padding: '0.8rem 1.6rem',
		width: '100%',
	},
	contentText: {
		color: 'secondary.main',
		fontStyle: 'italic',
		textAlign: 'center',
		whiteSpace: 'pre-wrap',
	},
	dialogContainer: {
		padding: '2.4rem',
	},
	headerContainer: {
		alignItems: 'center',
		backgroundColor: 'secondary.main',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		padding: '0.8rem 0',
		width: '100%',
	},
	headerText: {
		color: '#FFF',
		textAlign: 'center',
	},
	infoIconContainer: {
		fontSize: '2rem',
		height: '2rem',
		padding: '0 0.8rem',
	},
	secondaryContentContainer: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		minHeight: '8.8rem',
		padding: '0.8rem 1.6rem',
		width: '100%',
	},
}
