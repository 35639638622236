import { memo } from 'react'

import AudioTranscriptionButton from './AudioTranscription'
import type { Props as AudioTranscriptionProps } from './AudioTranscription'

const AudioTranscription = memo(AudioTranscriptionButton)

export { AudioTranscriptionProps }

export default AudioTranscription
