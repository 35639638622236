export const styles = {
	copyContainer: {
		display: 'flex',
		marginLeft: '0.5rem',
	},
	copy: {
		marginLeft: '0.5rem',
		color: 'rgba(0,0,0,0.6)',
		fontSize: '1.6rem',
	},
	flame: {
		margin: '0 0.4rem',
	},
	ideaCount: {
		fontWeight: 500,
		fontSize: '1.5rem',
		lineHeight: '2.6rem',
	},
	motivator: {
		display: 'flex',
		alignItems: 'center',
	},
}
