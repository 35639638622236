import { ReactElement, useCallback, useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { throttle } from 'lodash'

import FlameExtinguished from 'assets/images/flameExtinguished.svg'

import FlameIgnited, { GameName } from './FlameIgnited'
import { styles } from './IdeaFlameMotivator.styles'

interface MotivationCopyProps {
	ideaRange: string
	motivationCopy: string
}
const MotivationCopy = ({ ideaRange, motivationCopy }: MotivationCopyProps) => (
	<Box sx={styles.copyContainer}>
		<Typography sx={styles.ideaCount} variant="body1">
			{ideaRange}
		</Typography>{' '}
		<Typography sx={styles.copy}>{motivationCopy}</Typography>
	</Box>
)

type MotivatonLevel = 0 | 1 | 2 | 3
type MotivationCopies = {
	[index in MotivatonLevel]: ReactElement
}

const motivationCopies: MotivationCopies = {
	0: (
		<MotivationCopy
			ideaRange="0 IDEAS"
			motivationCopy="Add your first idea above"
		/>
	),
	1: (
		<MotivationCopy
			ideaRange="1 - 5 IDEAS"
			motivationCopy="Nice! What other ideas can you document?"
		/>
	),
	2: (
		<MotivationCopy
			ideaRange="6 - 10 IDEAS"
			motivationCopy="Now you're on a roll - don't stop now!"
		/>
	),
	3: (
		<MotivationCopy
			ideaRange="+11 IDEAS"
			motivationCopy="You're an innovation superstar"
		/>
	),
}

const levelFromCount = (n: number): MotivatonLevel => {
	if (n <= 0) {
		return 0
	}
	if (n <= 5) {
		return 1
	}
	if (n <= 10) {
		return 2
	}
	return 3
}
interface FlameProps {
	light: boolean
	variant: GameName
}
const Flame = ({ light, variant }: FlameProps) => {
	if (!light) {
		return (
			<img
				alt="Flame extinguished"
				src={FlameExtinguished as unknown as string}
				style={styles.flame}
			/>
		)
	} else {
		return (
			<Box sx={styles.flame}>
				<FlameIgnited variant={variant} title="Flame ignited" />
			</Box>
		)
	}
}

interface IdeaFlameMotivatorProps {
	ideaCount: number
	variant: GameName
}
const IdeaFlameMotivator = ({
	ideaCount,
	variant,
}: IdeaFlameMotivatorProps) => {
	const level = levelFromCount(ideaCount)
	const motivationCopy = useMemo(() => motivationCopies[level], [level])

	const flames = [1, 2, 3].map((flamePos) => (
		<Flame
			light={level >= flamePos}
			variant={variant}
			key={`flame-${flamePos}`}
		/>
	))
	return (
		<Box sx={styles.motivator}>
			{flames}
			{motivationCopy}
		</Box>
	)
}

export const richTextToIdeas = (htmlString: string): number => {
	const container = document.createElement('div')
	container.innerHTML = htmlString

	const nonEmptyParagraphs = Array.from(
		container.querySelectorAll('p')
	).filter((p) => (p.textContent ?? '').trim().length !== 0)

	return nonEmptyParagraphs.length
}

export const useRichTextToIdeas = () => {
	const throttledFn = useMemo(() => throttle(richTextToIdeas, 500), [])
	return useCallback(
		(htmlString: string) => {
			return throttledFn(htmlString) ?? 0
		},
		[throttledFn]
	)
}

export default IdeaFlameMotivator
