import React, { useState } from 'react'

import { Button, CircularProgress, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { useToastContext } from 'contexts/ToastProvider'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import GeneratedExampleDropdown from 'components/GeneratedExampleDropdown'
import Section2Title from 'components/Section2Title'

import editIcon from 'assets/images/editIcon.svg'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { styles } from './Section2.styles'

export const TEST_ID = 'dress-up-section2'

export type Props = {
	businessComparisons: string[]
	businessComparisonsHasChanged: boolean[]
	createPrompt: () => void
	crossCompanyName: string
	currentResponses: string[]
	loading: boolean
	reloadIdeas: () => Promise<void>
}

const buildResponseItem = (solution: string) => {
	const stringSol = solution
		.split(/(\d.)/)
		?.splice(1)
		.filter((x) => isNaN(parseInt(x)))
	return (
		<Box bgcolor={'rgba(231, 248, 255, 1)'} padding={'1rem'}>
			<ol data-testid="gen-ai-response-list">
				{stringSol.map((el: string, i: number) => (
					<Typography
						fontSize={16}
						color="black"
						component="li"
						sx={{ opacity: 0.87 }}
						key={`ai-solution-${i}`}
						variant="body1"
						style={{
							margin: '10px 0 10px 0',
						}}
					>
						{el}
					</Typography>
				))}
			</ol>
		</Box>
	)
}
const Section2 = (props: Props) => {
	const { showAlert } = useToastContext()
	const [businessComparisonIndex, setBusinessComparisonIndex] = useState(0)
	const [editPromptMode, setEditPromptMode] = useState(false)
	const [isGenAiLoading, setIsGenAiLoading] = useState(false)

	const onClickBusinessComparison = (index: number) => {
		if (editPromptMode) {
			const editedIdea = document.getElementsByTagName('textarea')

			if (editedIdea && editedIdea.length >= 1) {
				props.businessComparisons[businessComparisonIndex] =
					editedIdea[0].value
			}
		}
		setBusinessComparisonIndex(index)
	}
	const onClickRefresh = () => {
		setBusinessComparisonIndex(0)
		props.createPrompt()
	}

	const onClickEditPrompt = () => {
		setEditPromptMode(true)
	}

	const onClickCancelEditPrompt = () => {
		setEditPromptMode(false)
	}

	const onClickSaveEditPrompt = async () => {
		const lastEditedIdea = document.getElementsByTagName('textarea')
		if (lastEditedIdea && lastEditedIdea.length >= 1) {
			if (
				props.businessComparisons[businessComparisonIndex] !==
				lastEditedIdea[0].value
			) {
				props.businessComparisons[businessComparisonIndex] =
					lastEditedIdea[0].value
				props.businessComparisonsHasChanged[businessComparisonIndex] =
					true
			}
			setEditPromptMode(false)
			await props.reloadIdeas()
			if (showAlert) {
				showAlert({
					objectType: 'editPrompt',
					severity: ToastSeverity.SUCCESS,
				})
			}
		}
	}

	return (
		<>
			<Section2Title />
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display="flex"
					flexDirection="column"
					gap="2rem"
					flexGrow="1"
					paddingY={{ xs: '0', sm: '1rem' }}
				>
					<Box
						display={'flex'}
						flexDirection={{
							xs: 'column',
							sm: 'row',
						}}
						justifyContent={'space-between'}
						alignItems={{
							xs: 'flex-start',
							sm: 'center',
						}}
						gap={'1rem'}
					>
						<Typography>
							How can you draw cross-industry inspiration from the
							following company?
						</Typography>
						<Box
							display={'flex'}
							flexDirection={'row'}
							justifyContent={'center'}
							alignItems={'center'}
							gap={'1rem'}
						>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={refreshIcon}
								text="REFRESH PROMPT"
								data-testid={`${TEST_ID}__refresh-button`}
								onClickAction={onClickRefresh}
							/>
						</Box>
					</Box>
					<Box
						display={'flex'}
						width="100%"
						flexDirection={{
							xs: 'column',
							sm: 'row',
						}}
					>
						<Box
							width={{
								xs: '100%',
								sm: '20%',
							}}
							minHeight="8rem"
							paddingRight={{
								xs: '0',
								sm: '1rem',
							}}
							paddingTop={{
								xs: '1rem',
								sm: '1rem',
							}}
							paddingBottom={{
								xs: '2rem',
								sm: '1rem',
							}}
							borderRight={{
								xs: 0,
								sm: 1,
							}}
							borderBottom={{
								xs: 1,
								sm: 0,
							}}
						>
							<Box
								padding={'1rem'}
								height="100%"
								display="flex"
								justifyContent="center"
								borderRadius="4px"
							>
								{props.loading ? (
									<Box display="flex">
										<CircularProgress
											color="secondary"
											sx={{
												padding: '1rem',
											}}
										/>
									</Box>
								) : (
									<Typography alignSelf="center">
										{props.crossCompanyName}
									</Typography>
								)}
							</Box>
						</Box>
						<Box
							width="100%"
							display={'flex'}
							paddingTop={{
								xs: '2rem',
								sm: '1rem',
							}}
							paddingBottom={{
								xs: '1rem',
								sm: '1rem',
							}}
							paddingLeft={{
								xs: '0',
								sm: '1rem',
							}}
							gap={{ xs: '2rem', sm: '1rem' }}
							flexDirection={{
								xs: 'column',
								sm: 'row',
							}}
							id={`business-comparison-ideas`}
						>
							{props.businessComparisons.map(
								(businessComparison, i) =>
									editPromptMode &&
									businessComparisonIndex === i ? (
										<TextField
											key={`edit-business-comparison-${i}`}
											data-testid={`${TEST_ID}__edit-business-comparison-${i}`}
											defaultValue={businessComparison}
											multiline
											fullWidth
											inputRef={(input) =>
												input && input.focus()
											}
											sx={{
												'.MuiInputBase-root': {
													height: '100%',
												},
											}}
										/>
									) : (
										<Box
											flexGrow={1}
											border={1}
											borderRadius="4px"
											padding={'1rem'}
											key={`business-comparison-${i}`}
											width="100%"
											onClick={() => {
												if (props.loading) return
												onClickBusinessComparison(i)
											}}
											sx={{
												cursor: props.loading
													? 'default'
													: 'pointer',
												backgroundColor:
													businessComparisonIndex ===
													i
														? '#E7F8FF'
														: 'white',
											}}
										>
											{props.loading ? (
												<Box textAlign="center">
													<CircularProgress
														color="secondary"
														sx={{
															padding: '1rem',
														}}
													/>
												</Box>
											) : (
												<Box
													data-testid={`${TEST_ID}__business-comparison-${i}`}
												>
													<Typography
														sx={{
															color: 'black',
															opacity: 0.87,
															fontWeight:
																businessComparisonIndex ===
																i
																	? 700
																	: 400,
														}}
													>
														{businessComparison}
													</Typography>
												</Box>
											)}
										</Box>
									)
							)}
						</Box>
					</Box>
					{editPromptMode ? (
						<Box
							display="flex"
							flexDirection={{
								xs: 'column',
								sm: 'row',
							}}
							gap="2rem"
						>
							<Button
								color="secondary"
								variant="contained"
								data-testid={`${TEST_ID}__edit-save-button`}
								onClick={async () => {
									setIsGenAiLoading(true)
									await onClickSaveEditPrompt()
									setIsGenAiLoading(false)
								}}
							>
								<Typography>SAVE CHANGES</Typography>
							</Button>
							<Button
								variant="text"
								color="secondary"
								data-testid={`${TEST_ID}__edit-cancel-button`}
								onClick={onClickCancelEditPrompt}
							>
								<Typography>CANCEL</Typography>
							</Button>
						</Box>
					) : (
						<Box
							display={'flex'}
							alignItems={'center'}
							justifyContent={'end'}
						>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={editIcon}
								text="EDIT PROMPT"
								data-testid={`${TEST_ID}-edit-prompt`}
								onClickAction={onClickEditPrompt}
							/>
						</Box>
					)}
					<Typography>
						Unsure of how to apply the prompt to your innovation
						topic?
					</Typography>
					<GeneratedExampleDropdown
						gameTypeEnum={GAME_TYPE.DRESS_UP}
						headerText={''}
						isLoading={isGenAiLoading}
						customResponse={buildResponseItem(
							props.currentResponses[businessComparisonIndex]
						)}
					/>
				</Box>
			</Box>
		</>
	)
}
export default Section2
