import { Theme } from '@mui/material'

const drawerWidth = 250

export const styles = {
	arcLabsLogoContainer: {
		display: 'grid',
		maxHeight: '100px',
	},
	arcLabsLogoLink: (theme: Theme) => ({
		svg: {
			paddingLeft: theme.spacing(5),
		},
	}),
	AppBar: {
		alignItems: 'center',
		backgroundColor: 'background.paper',
		justifyContent: 'space-between',
		zIndex: 3,
	},
	AvatarIcon: {
		cursor: 'pointer',
	},
	BrandingContainer: {
		alignItems: 'center',
		cursor: 'pointer',
		direction: 'row',
		justifyItems: 'space-between',
		margin: '2.5rem 0 2.5rem 0rem',
	},
	ButtonGroupContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: '3.3rem',
		justifyContent: 'flex-end',
	},
	drawer: (theme: Theme) => ({
		display: { xs: 'block', md: 'none' },

		'& .MuiDrawer-paper': {
			backgroundColor: theme.palette.secondary.main,
			boxSizing: 'border-box',
			color: theme.palette.common.white,
			padding: theme.spacing(15, 3, 3),
			width: drawerWidth,
		},
	}),
	drawerCloseButton: (theme: Theme) => ({
		display: 'block',
		color: theme.palette.common.white,
		position: 'absolute',
		right: '0',
		top: '0',

		svg: {
			fontSize: '4rem',
		},
	}),
	logoutButton: () => ({
		backgroundColor: 'background.paper',
		borderRadius: '2px !important',
		borderColor: 'secondary.main',
		fontWeight: '400',
		'&:hover': {
			backgroundColor: 'background.paper',
			fontWeight: '700',
		},
	}),
	loginOrSalutations: (theme: Theme) => ({
		display: 'inline-block',
		margin: { xs: theme.spacing(1, 0), md: theme.spacing(2, 5) },
	}),
	NavBarGridContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
	},
	navList: (theme: Theme) => ({
		display: 'inline-flex',

		'.MuiListItem-root:last-child': {
			marginRight: theme.spacing(2),
		},

		'& .MuiListItemButton-root': {
			'&:hover': {
				backgroundColor: 'transparent',

				'.MuiTypography-root': {
					WebkitTextStroke: `1px ${theme.palette.primary.main}`,
				},
			},
		},
	}),
	navListMobile: (theme: Theme) => ({
		'.MuiTypography-root': {
			color: theme.palette.common.white,
			fontSize: '2.25rem',
		},
		'& .MuiListItem-root': {
			padding: theme.spacing(0.5, 2),

			'& .MuiListItemButton-root': {
				padding: theme.spacing(0),

				'& .MuiListItemText-root': {
					'&.current-page > .MuiTypography-root': {
						fontWeight: theme.typography.fontWeightBold,
					},

					'& .MuiTypography-root': {
						fontSize: 'inherit',
					},
				},

				'&:hover': {
					backgroundColor: 'transparent',

					'.MuiTypography-root': {
						WebkitTextStroke: `1px ${theme.palette.common.white}`,
					},
				},
			},
		},
	}),
	navItemText: (theme: Theme) => ({
		color: theme.palette.primary.main,
		textTransform: 'capitalize',
		whiteSpace: 'nowrap',

		[theme.breakpoints.down('md')]: {
			color: theme.palette.common.white,
		},
	}),
	UserLogin: {
		display: 'flex',
	},
	VerticalLine: {
		paddingLeft: '.75rem',
		paddingRight: '.75rem',
	},
}
