import { SxProps, Theme } from '@mui/system'

export const styles: Record<string, SxProps<Theme>> = {
	solutionText: {
		color: '#000000DE',
		fontStyle: 'italic',
		margin: 0,
		fontSize: '16px',
	},
}
