import { memo } from 'react'

import Section2 from './Section2'
import type { Props as Section2Props } from './Section2'

const Section2Component = memo(Section2)

export { Section2Props }

export default Section2Component
