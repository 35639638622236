import { TypographyOptions } from '@mui/material/styles/createTypography'

export interface CustomTypographyOptions extends TypographyOptions {
	buttonText?: {
		color: string
		fontSize: string
		textDecoration: string
	}
}

export const typography: CustomTypographyOptions = {
	body1: {
		fontSize: '1.8rem',
		fontWeight: 400,
	},
	body2: {
		fontSize: '1.6rem',
	},
	buttonText: {
		color: 'secondary.main',
		fontSize: '0.938rem',
		textDecoration: 'underline',
	},
	h5: {
		fontSize: '2rem',
		fontWeight: 500,
	},
	h6: {
		fontSize: '1.8rem',
		fontWeight: 500,
	},
	overline: {
		fontSize: '1.4rem',
		letterSpacing: '1px',
	},
	htmlFontSize: 10,
}
