import React, { ReactNode, createContext, useContext, useState } from 'react'

import { Dialog } from '@mui/material'
import { SubmitIdeasProvider } from 'submitideasproviders/SubmitIdeasContext'
import { IdeationThemeProvider } from 'theme'

import { Idea } from 'models/ideaModels'

import { HOME_PAGE, PUBLISHED_GAMES } from 'assets/routes'

const LoginModal = React.lazy(() => import('components/LoginModal'))
const SignUpModal = React.lazy(() => import('components/SignUpModal'))
const LoggedOutModal = React.lazy(() => import('components/LoggedOutModal'))
const ForgotPasswordModal = React.lazy(
	() => import('components/ForgotPasswordModal')
)
const ResetPasswordModal = React.lazy(
	() => import('components/ResetPasswordModal')
)

/* istanbul ignore next */
const EditSubmittedIdeaModal = React.lazy(
	() => import('components/EditSubmittedIdeaModal')
)
/* istanbul ignore next */
const DeleteSubmittedIdeaModal = React.lazy(
	() => import('components/DeleteSubmittedIdeaModal')
)

const DeletePublishedGameModal = React.lazy(
	() => import('components/DeletePublishedGameModal')
)

const CustomGameModal = React.lazy(
	() => import('pages/PublishedGames/components/CustomGameModal')
)
/* istanbul ignore next */
const MiroRedirectModal = React.lazy(
	() => import('components/MiroRedirectModal')
)

const WarningModal = React.lazy(() => import('components/WarningModal'))

const CreateCustomGameModal = React.lazy(
	() => import('components/CreateCustomGameModal')
)

const ShareGameModal = React.lazy(
	() => import('../pages/PublishedGames/components/ShareGameModal')
)

const DeleteAccountModal = React.lazy(
	() => import('components/DeleteAccountModal')
)

export const TEST_ID = 'global-modal'

export const MODAL_TYPES = {
	CUSTOM_GAME_MODAL: 'CUSTOM_GAME_MODAL',
	LOGIN_MODAL: 'LOGIN_MODAL',
	SIGN_UP_MODAL: 'SIGN_UP_MODAL',
	LOGGED_OUT_MODAL: 'LOGGED_OUT_MODAL',
	FORGOT_PASSWORD_MODAL: 'FORGOT_PASSWORD_MODAL',
	RESET_PASSWORD_MODAL: 'RESET_PASSWORD_MODAL',
	EDIT_SUBMITTED_IDEA_MODAL: 'EDIT_SUBMITTED_IDEA_MODAL',
	DELETE_SUBMITTED_IDEA_MODAL: 'DELETE_SUBMITTED_IDEA_MODAL',
	DELETE_PUBLISHED_GAME_MODAL: 'DELETE_PUBLISHED_GAME_MODAL',
	MIRO_REDIRECT_MODAL: 'MIRO_REDIRECT_MODAL',
	WARNING_MODAL: 'WARNING_MODAL',
	CREATE_CUSTOM_GAME_MODAL: 'CREATE_CUSTOM_GAME_MODAL',
	SHARE_MODAL: 'SHARE_MODAL',
	DELETE_ACCOUNT_MODAL: 'DELETE_ACCOUNT_MODAL',
} as const
export type ModalType = (typeof MODAL_TYPES)[keyof typeof MODAL_TYPES]

const MODAL_COMPONENTS = {
	[MODAL_TYPES.CUSTOM_GAME_MODAL]: CustomGameModal,
	[MODAL_TYPES.LOGIN_MODAL]: LoginModal,
	[MODAL_TYPES.SIGN_UP_MODAL]: SignUpModal,
	[MODAL_TYPES.FORGOT_PASSWORD_MODAL]: ForgotPasswordModal,
	[MODAL_TYPES.RESET_PASSWORD_MODAL]: ResetPasswordModal,
	[MODAL_TYPES.LOGGED_OUT_MODAL]: LoggedOutModal,
	[MODAL_TYPES.EDIT_SUBMITTED_IDEA_MODAL]: EditSubmittedIdeaModal,
	[MODAL_TYPES.DELETE_SUBMITTED_IDEA_MODAL]: DeleteSubmittedIdeaModal,
	[MODAL_TYPES.DELETE_PUBLISHED_GAME_MODAL]: DeletePublishedGameModal,
	[MODAL_TYPES.MIRO_REDIRECT_MODAL]: MiroRedirectModal,
	[MODAL_TYPES.WARNING_MODAL]: WarningModal,
	[MODAL_TYPES.CREATE_CUSTOM_GAME_MODAL]: CreateCustomGameModal,
	[MODAL_TYPES.SHARE_MODAL]: ShareGameModal,
	[MODAL_TYPES.DELETE_ACCOUNT_MODAL]: DeleteAccountModal,
}

interface GameState {
	companyProblem?: string
	gameTypeId?: number
	innovationCompany?: string
	innovationTopic?: string
}

type ModalProps = {
	deleteIdea?: (ideaId: string) => void
	editIdea?: (idea: Idea) => void
	editable?: boolean
	gameLink?: string
	gameState?: GameState
	gameTitle?: string
	handleDeleteClick?: VoidFunction
	onClose?: () => void
	viewLink?: string
} & Record<string, unknown>

interface IModalContext {
	hideModal: () => void
	showModal: (modalType: ModalType, modalProps?: object) => void
	store: {
		modalProps?: ModalProps
		modalType?: ModalType | null
	}
}

const initialState: IModalContext = {
	// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
	showModal: (_modalType: ModalType, _modalProps?: object) => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	hideModal: () => {},
	store: {
		modalType: undefined,
		modalProps: {},
	},
}
const ModalContext = createContext(initialState)
export const useModalContext = () => useContext(ModalContext)

function ModalProvider({ children }: { children: ReactNode }) {
	const [store, setStore] = useState<{
		modalProps: ModalProps
		modalType: ModalType | null
	}>({ modalType: null, modalProps: {} })
	const { modalType, modalProps = {} } = store || {}

	const showModal = (modalType: ModalType, modalProps = {}) => {
		setStore({
			...store,
			modalType,
			modalProps,
		})
	}

	const hideModal = () => {
		if (
			(modalType === 'SIGN_UP_MODAL' || modalType === 'LOGIN_MODAL') &&
			window.location.href.includes(PUBLISHED_GAMES + '/')
		) {
			window.location.href = HOME_PAGE
		}
		setStore({
			...store,
			modalType: null,
			modalProps: {},
		})
	}

	const {
		onClose: onCloseProps,
		editIdea: editIdeaProps,
		deleteIdea: deleteIdeaProps,
		...modalPropsRest
	} = modalProps

	const onClose = () => {
		hideModal()
		onCloseProps && onCloseProps()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	const editIdea = (idea: Idea) => {
		const edit = editIdeaProps as (idea: Idea) => void
		edit(idea)
	}

	const deleteIdea = (ideaId: string) => {
		const del = deleteIdeaProps as (ideaId: string) => void
		del(ideaId)
	}

	const paperProps = { sx: { borderRadius: '2rem' } }
	const renderComponent = () => {
		const ModalComponent = modalType ? MODAL_COMPONENTS[modalType] : null

		return (
			<Dialog
				data-testid={TEST_ID}
				open={!!(modalType && ModalComponent)}
				onClose={onClose}
				maxWidth="lg"
				PaperProps={paperProps}
			>
				{/* We need to create a global Loading animation to use */}
				<React.Suspense fallback={<div>Loading...</div>}>
					{(ModalComponent && (
						<ModalComponent
							onCancel={() => {}}
							onProceed={() => {}}
							deleteIdea={deleteIdea}
							handleDeleteClick={() => {}}
							hideModal={hideModal}
							updateIdeation={editIdea}
							editable={modalProps.editable || true}
							{...modalPropsRest}
						/>
					)) ||
						null}
				</React.Suspense>
			</Dialog>
		)
	}

	return (
		<SubmitIdeasProvider>
			<ModalContext.Provider value={{ store, showModal, hideModal }}>
				<IdeationThemeProvider>
					{renderComponent()}
				</IdeationThemeProvider>
				{children}
			</ModalContext.Provider>
		</SubmitIdeasProvider>
	)
}

export default ModalProvider
