import React from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import { brainstormText } from 'assets/brainstormText'

import { GameType } from 'enums/GameTypeEnum'

import { styles } from './PromptBox.styles'
import { PromptBoxData } from './PromptBoxGameData'

export type Props = {
	contentText: string
	'data-testid'?: string
	gameTypeEnum?: GameType
	hidePromptContainerStyle?: boolean
	loading?: boolean
}

export const TEST_ID = 'prompt-box-'

const PromptBox = ({
	contentText = brainstormText.slotMachine.generateScenario,
	loading = false,
	hidePromptContainerStyle,
	gameTypeEnum,
	...props
}: Props) => {
	const gameId = gameTypeEnum?.value || 1
	const promptGameData = PromptBoxData[gameId]

	return (
		<Box
			data-testid={props['data-testid'] ?? 'prompt-container'}
			sx={hidePromptContainerStyle ? null : styles.container}
			{...props}
		>
			<Card sx={styles.card} variant="outlined">
				<CardContent sx={styles.cardContentReset}>
					<Box
						data-testid="prompt-content-container"
						sx={{
							background: promptGameData.background,
							padding: {
								lg: '24px',
								md: '24px',
								sm: '24px',
								xs: '8px 16px 8px 16px',
							},
						}}
					>
						{loading ? (
							<CircularProgress
								sx={styles.circularProgressIcon}
							/>
						) : (
							<Typography sx={styles.contentText} variant="body1">
								{contentText}
							</Typography>
						)}
					</Box>
				</CardContent>
			</Card>
		</Box>
	)
}

export default PromptBox
