import { Box, Typography } from '@mui/material'
import { SxProps } from '@mui/system'

import RectangleButtonComponent from 'components/RectangleButton'

import Wind2 from '../../assets/images/Wind2.svg'

export interface Props {
	buttonOnClick: () => void
	buttonText: string
	subtitle: string
	sxOverrides?: SxProps
}

export default function EmptySubPageContent({
	subtitle,
	buttonText,
	buttonOnClick,
	sxOverrides,
}: Props) {
	return (
		<Box
			sx={{
				textAlign: 'center',
				gap: '3.2rem',
				margin: '12.8rem 10rem',
				sm: { margin: '6.4rem' },
				...sxOverrides,
			}}
		>
			<img src={Wind2 as unknown as string} />
			<Typography sx={{ marginTop: '3.2rem' }}>{subtitle}</Typography>
			<RectangleButtonComponent
				color="secondary"
				text={buttonText}
				onClick={buttonOnClick}
				sx={{ marginTop: '3.2rem' }}
			/>
		</Box>
	)
}
