import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'

import { Game } from 'models/gameModels'

import {
	deleteGame,
	editGame,
	getGame,
	getGames,
	postGames,
} from 'services/games.service'

import useApi from 'hooks/useApi'

import { requestGameLogic } from './utility/Helper'

// ONLY EVER USE EXPORTED GAMECONTEXT FOR TESTING

interface IGameContext {
	clearRequestGame?: () => void
	createGameError?: boolean
	createGameLoading?: boolean
	createGameSuccess?: boolean
	createdGame?: Game
	deleteGameError?: boolean
	deleteGameLoading?: boolean
	deleteGameSuccess?: boolean
	deletedGame?: Game
	editGameError?: boolean
	editGameLoading?: boolean
	editGameSuccess?: boolean
	editedGame?: Game
	game?: Game
	games?: Game[]
	requestCreateGame?: (
		queryObj: Pick<
			Game,
			| 'gameTypeId'
			| 'innovationTopic'
			| 'innovationCompany'
			| 'gameTitle'
			| 'companyProblem'
			| 'endTime'
		>
	) => Promise<void>
	requestDeleteGame?: (gameId: string) => void
	requestEditGame?: (game: Game) => Promise<void>
	requestGame?: (gameId: string) => void
	requestGameError?: boolean
	requestGameLoading?: boolean
	requestGameSuccess?: boolean
	requestGames?: ({
		gameTypeId,
		gameTitle,
	}: {
		gameTitle: string
		gameTypeId: string
	}) => Promise<void>
	requestGamesError?: boolean
	requestGamesLoading?: boolean
	requestGamesSuccess?: boolean
	resetCreateGame?: () => void
	resetEditGame?: () => void
	resetRequestGame?: () => void
	setGame?: (game: Game | null) => void
}

export const GameContext = createContext<IGameContext>({})
export const useGameContext = () => useContext(GameContext)

export type Props = {
	children: React.ReactNode
}

function GameProvider({ children }: Props) {
	const [games, setGames] = useState<Game[] | undefined>()
	const [game, setGame] = useState<Game | null>(null)

	// API handling hook for fetching all games
	const {
		loading: requestGamesLoading,
		success: requestGamesSuccess,
		error: requestGamesError,
		data: requestedGames = [],
		requestApi: requestGames,
	} = useApi(getGames)

	// API handling hook for fetching a specific game by ID
	const {
		loading: requestGameLoading,
		success: requestGameSuccess,
		error: requestGameError,
		data: requestedGame = null,
		requestApi: requestGameIfNecessary,
		resetRequest: resetRequestGame,
		clearRequest: clearRequestGame,
	} = useApi(getGame)

	// API handling hook for creating a game
	const {
		loading: createGameLoading,
		success: createGameSuccess,
		error: createGameError,
		data: createdGame = null,
		requestApi: requestCreateGame,
		resetRequest: resetCreateGame,
	} = useApi(postGames)

	// API handling hook for editing a game
	const {
		loading: editGameLoading,
		success: editGameSuccess,
		error: editGameError,
		data: editedGame = null,
		requestApi: handleEditGame,
		resetRequest: resetEditGame,
	} = useApi(editGame)

	// API handling hook for deleting a game
	const {
		loading: deleteGameLoading,
		success: deleteGameSuccess,
		error: deleteGameError,
		data: deletedGame = null,
		requestApi: handleDeleteGame,
	} = useApi(deleteGame)

	// convenience method to allow user to send back entire updated game object
	// and we handle the magic of the method
	const requestEditGame = useCallback(
		(game: Game) => handleEditGame(game),
		[handleEditGame]
	)

	const requestDeleteGame = useCallback(
		(gameId: string) => handleDeleteGame(gameId),
		[handleDeleteGame]
	)

	// method to request game only if it isn't already in the store
	const requestGame = useCallback(
		(gameId: string) => {
			requestGameLogic(gameId, games, setGame, requestGameIfNecessary)
		},
		[games, setGame, requestGameIfNecessary]
	)

	// whenever a new game is individually requested, set it to current game
	useEffect(() => {
		setGame(requestedGame)
	}, [requestedGame])

	// whenever games are fetched, set to state variable
	useEffect(() => {
		setGames(requestedGames ?? undefined)
	}, [requestedGames])

	return (
		<GameContext.Provider
			value={{
				games,
				requestGames,
				requestGamesLoading,
				requestGamesSuccess,
				requestGamesError,
				game: game ?? undefined,
				requestGame,
				resetRequestGame,
				clearRequestGame,
				requestGameLoading,
				requestGameSuccess,
				requestGameError,
				requestDeleteGame,
				deleteGameError,
				deleteGameLoading,
				deleteGameSuccess,
				deletedGame: deletedGame ?? undefined,
				requestCreateGame,
				createdGame: createdGame ?? undefined,
				createGameLoading,
				createGameSuccess,
				createGameError,
				editedGame: editedGame ?? undefined,
				editGameLoading,
				editGameSuccess,
				editGameError,
				requestEditGame,
				resetEditGame,
				setGame,
				resetCreateGame,
			}}
		>
			{children}
		</GameContext.Provider>
	)
}

export default GameProvider
