import { configure, getLogger } from 'log4js'

import { getLoggingLevelToUse } from './getLoggingLevelToUse'

configure({
	appenders: {
		console: {
			type: 'console',
			layout: { type: 'pattern', pattern: '[%[%d] [%p] -%] %m' }, // use colored layout %[ %] with datetime %d, loglevel %p and message %m
		},
	},
	categories: { default: { appenders: ['console'], level: 'info' } },
})
const logger = getLogger()
logger.level = getLoggingLevelToUse()

export default logger
