export const styles = {
	sectionHeader: {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.6rem',
	},
	sectionHeaderContent: {
		color: 'primary.main',
		display: 'flex',
		flexDirection: 'row',
		gap: '1.2rem',
	},
	sectionHeaders: {
		display: 'flex',
		pb: '2.4rem',
	},
	sectionText: {
		color: 'text.secondary',
		fontStyle: 'italic',
	},
}
