import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import ArrowForward from '@mui/icons-material/ArrowForward'
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'

import { MODAL_TYPES, useModalContext } from 'contexts/ModalProvider'

import useAuth from 'hooks/useAuth'

import { LOCAL_STORAGE_KEYS } from 'assets/constants'

import { GameType } from 'enums/GameTypeEnum'

import { styles } from './GameCard.styles'

export type Props = {
	comingSoonList: string[]
	gameTypeEnum: GameType
}

const GameCard = (props: Props) => {
	const { showModal } = useModalContext()
	const { isAuthenticated, isAuthenticating } = useAuth()
	const navigate = useNavigate()

	const gameCardData = props.gameTypeEnum
	const isComingSoonGame = props.comingSoonList.includes(
		gameCardData.label || ''
	)

	const openLoginModal = useCallback(() => {
		showModal(MODAL_TYPES.LOGIN_MODAL)
	}, [showModal])

	const gameClicked = () => {
		if (isComingSoonGame) return
		if (!isAuthenticating) {
			if (isAuthenticated) {
				window.scrollTo(0, 0)
				navigate('games/' + gameCardData.gameLink)
			} else {
				openLoginModal()
				window.localStorage.setItem(
					LOCAL_STORAGE_KEYS.REDIRECT_PATH,
					`/games/${gameCardData.gameLink}`
				)
			}
		}
	}

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const dataTestIdBase = gameCardData.gameLink

	return (
		<Box
			data-testid={`game-${dataTestIdBase}__container`}
			onClick={gameClicked}
			sx={styles.gameCardContainer}
		>
			<Box
				data-testid={`game-${dataTestIdBase}__use_case_container`}
				sx={{
					...styles.gameCardUseCaseTextContainer,
					background: gameCardData.useCaseBackgroundColor,
				}}
			>
				<Typography
					data-testid={`game-${dataTestIdBase}__use_case_text`}
					sx={styles.gameCardUseCaseText}
				>
					{gameCardData.useCase}
				</Typography>
			</Box>
			<Box
				data-testid={`game-${dataTestIdBase}__content_container`}
				sx={{
					...styles.gameCardContentContainer,
					background: gameCardData.backgroundGradient || '',
				}}
			>
				<Box sx={styles.gameCardMainContentContainer}>
					<Typography
						data-testid={`game-${dataTestIdBase}__game_title`}
						sx={styles.gameCardTitle}
					>
						{gameCardData.label}
					</Typography>
					<Typography
						data-testid={`game-${dataTestIdBase}__game_description`}
						sx={styles.gameCardDescription}
					>
						{gameCardData.description}
					</Typography>
					<Button
						data-testid={`game-${dataTestIdBase}__box_button`}
						size="medium"
						endIcon={
							isComingSoonGame ? null : (
								<ArrowForward
									fontSize="large"
									color="secondary"
									data-testid={`game-${dataTestIdBase}__box_button-arrow`}
								/>
							)
						}
						variant="text"
						color="secondary"
						sx={styles.gameCardButton}
					>
						{isComingSoonGame ? 'Coming Soon!' : 'Play Now'}
					</Button>
				</Box>
				<Box sx={styles.gameCardImageContainer}>
					<img
						src={gameCardData.image}
						style={
							isMobile
								? styles.gameCardImgSmall
								: styles.gameCardImgLarge
						}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export default GameCard
