import { createFeatureDecisions } from 'config/featureDecisions'

export enum LoggingLevel {
	debug = 'debug',
	error = 'error',
	info = 'info',
}
export const isLoggingLevel = (
	testString: string
): testString is LoggingLevel =>
	(Object.values(LoggingLevel) as string[]).includes(testString)

export const getLoggingLevelToUse = (): LoggingLevel => {
	const loggingLevelEnvVar: string =
		process.env.REACT_APP_LOGGING_LEVEL?.toLowerCase() ?? ''

	const requestedLoggingLevel: LoggingLevel = isLoggingLevel(
		loggingLevelEnvVar
	)
		? loggingLevelEnvVar
		: LoggingLevel.info

	const selectedLoggingLevel: LoggingLevel =
		requestedLoggingLevel === LoggingLevel.debug &&
		!createFeatureDecisions().allowDebugLevelLogging()
			? LoggingLevel.info
			: requestedLoggingLevel

	return selectedLoggingLevel
}
