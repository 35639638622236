import { useCallback, useEffect, useState } from 'react'

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	InputAdornment,
	Typography,
} from '@mui/material'
import isEmpty from 'lodash/isEmpty'

import { Idea } from 'models/ideaModels'

import { getPromptResponse } from 'services/completions.service'

import { BaseTextField } from 'components/Form'

import useApi from 'hooks/useApi'

import { getSubmittedIdeasPrompt } from 'prompts/submittedIdeasPrompt'

import { SynthesisPrompt } from 'enums/SynthesisPromptEnum'

import SynthesisResponse from '../SynthesisResponse.tsx/SynthesisResponse'
import { styles } from './SynthesisSection.styles'

export type Props = {
	selectedIdeas: Idea[]
}

const SynthesisSection = ({ selectedIdeas = [] }: Props) => {
	const [aiResponse, setAiResponse] = useState('')
	const [genAIQuestion, setGenAIQuestion] = useState('')

	const { data, requestApi, loading } = useApi(getPromptResponse)

	const clearResponse = useCallback(() => {
		setAiResponse('')
	}, [])

	const onSynthesize = useCallback(() => {
		requestApi(getSubmittedIdeasPrompt(genAIQuestion, selectedIdeas))
	}, [requestApi, genAIQuestion, selectedIdeas])

	useEffect(() => {
		setAiResponse(data)
	}, [data])

	return (
		<Box>
			<Grid>
				<Grid container sx={styles.containerGrid} mb={1}>
					<Grid item sx={styles.textFieldContainer}>
						<BaseTextField
							InputLabelProps={{ style: styles.inputLabel }}
							variant="outlined"
							data-testid={`submitted-ideas-synthesis-gen-ai-question-input`}
							label="Ask AI a question about the submitted ideas..."
							value={genAIQuestion}
							onChangeValue={(val) => setGenAIQuestion(val)}
							required
							multiline
							size="medium"
							sx={{
								'& .MuiInputBase-input': {
									boxSizing: 'content-box',
									height: '100%',
								},
								...styles.textField,
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Button
											color="secondary"
											variant="contained"
											data-testid={`submitted-ideas-synthesis-gen-ai-ask-question-button`}
											aria-label="ask AI"
											onClick={onSynthesize}
											startIcon={<AutoAwesomeIcon />}
											disabled={
												isEmpty(genAIQuestion) ||
												selectedIdeas.length <= 0
											}
										>
											Ask AI
										</Button>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>

				<Grid container mb={1}>
					<Grid item>
						<Typography
							variant="caption"
							color="secondary.light"
							fontSize={'1.2rem'}
						>
							Example actions you can take on your submitted
							ideas:
						</Typography>
					</Grid>
				</Grid>

				<Grid container spacing={3}>
					<Grid item>
						<Button
							color={
								genAIQuestion === SynthesisPrompt.SUMMARIZE
									? 'info'
									: 'secondary'
							}
							variant="outlined"
							data-testid={`submitted-ideas-synthesis-button-preset-prompt-summarize`}
							onClick={() =>
								setGenAIQuestion(SynthesisPrompt.SUMMARIZE)
							}
							size="medium"
						>
							Summarize
						</Button>
					</Grid>
					<Grid item>
						<Button
							color={
								genAIQuestion === SynthesisPrompt.PRIORITIZE
									? 'info'
									: 'secondary'
							}
							variant="outlined"
							data-testid={`submitted-ideas-synthesis-button-preset-prompt-prioritize`}
							onClick={() =>
								setGenAIQuestion(SynthesisPrompt.PRIORITIZE)
							}
							size="medium"
						>
							Prioritize
						</Button>
					</Grid>
					<Grid item>
						<Button
							color={
								genAIQuestion === SynthesisPrompt.SYNTHESIZE
									? 'info'
									: 'secondary'
							}
							variant="outlined"
							data-testid={`submitted-ideas-synthesis-button-preset-prompt-synthesize`}
							onClick={() =>
								setGenAIQuestion(SynthesisPrompt.SYNTHESIZE)
							}
							size="medium"
						>
							Synthesize
						</Button>
					</Grid>
					<Grid item>
						<Button
							color={
								genAIQuestion === SynthesisPrompt.GROUP
									? 'info'
									: 'secondary'
							}
							variant="outlined"
							data-testid={`submitted-ideas-synthesis-button-preset-prompt-group`}
							onClick={() =>
								setGenAIQuestion(SynthesisPrompt.GROUP)
							}
							size="medium"
						>
							Group
						</Button>
					</Grid>
				</Grid>

				{loading || !isEmpty(genAIQuestion) ? (
					<Grid container mb={2} mt={4}>
						<Grid item flexGrow={1}>
							{loading ? (
								<CircularProgress
									sx={styles.circularProgressIcon}
								/>
							) : !isEmpty(aiResponse) ? (
								<SynthesisResponse
									contentText={aiResponse}
									clearResponse={clearResponse}
								/>
							) : null}
						</Grid>
					</Grid>
				) : null}
			</Grid>
		</Box>
	)
}

export default SynthesisSection
