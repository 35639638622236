import { useState } from 'react'

import { GAME_TYPE } from 'enums/GameTypeEnum'

type Conversation = {
	content: string
	role: string
}

type Response = {
	ideas: string[]
	title: string
}

export function ConnectFourModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string>('')
	const [companyName, setCompanyName] = useState<string>('')
	const [companyProblem, setCompanyProblem] = useState<string>('')
	const [currentPrompts, setCurrentPrompts] = useState<string[]>(
		new Array<string>(4).fill('')
	)
	const [currentResponses, setCurrentResponses] = useState<Response[]>([])
	const [gameId, setGameId] = useState<string | null>(null)
	const gameTypeId = GAME_TYPE.LEAP_FROG.value
	const [previousPrompts, setPreviousPrompts] = useState<string[]>([])
	const [responseConversation, setResponseConversation] = useState<
		Conversation[]
	>([])

	const generateNewPromptsDisabled = () => generatePromptsDisabled()
	const generatePromptsDisabled = () => !companyName || !companyProblem

	const setPromptResponse = (prompts: string[]) => {
		setCurrentPrompts(prompts)
		setPreviousPrompts([...new Set([...previousPrompts, ...prompts])])
	}

	const getRandomPromptsQueryObj = () => {
		return { limit: 4, previousPrompts }
	}

	const responseToJSON = () => {
		return {
			companyName,
			companyProblem,
			currentPrompt: currentPrompts.join(', '),
			conversation: responseConversation,
		}
	}

	const buttonDisabled = () => {
		if (
			authorIdeation.length === 0 ||
			companyName.length === 0 ||
			companyProblem === '' ||
			currentPrompts.length === 0
		)
			return true
		else return false
	}

	const aiButtonDisabled = () => {
		if (
			companyName.length === 0 ||
			companyProblem === '' ||
			currentPrompts.length === 0
		)
			return true
		else return false
	}

	const gameToJSON = () => {
		return {
			gameId: gameId !== null ? gameId : undefined,
			gameTypeId,
			innovationTopicManual: companyProblem,
			innovationCompany: companyName,
			authorIdeation,
		}
	}

	const reset = () => {
		resetSoft()
		setCurrentPrompts(new Array<string>(4).fill(''))
	}

	const resetSoft = () => {
		setResponseConversation([])
		setAuthorIdeation('')
		setCurrentResponses([])
	}

	return {
		companyName,
		setCompanyName,
		companyProblem,
		setCompanyProblem,
		currentPrompts,
		authorIdeation,
		setAuthorIdeation,
		responseConversation,
		setResponseConversation,
		currentResponses,
		setCurrentResponses,
		generatePromptsDisabled,
		generateNewPromptsDisabled,
		setPromptResponse,
		setPreviousPrompts,
		responseToJSON,
		buttonDisabled,
		gameToJSON,
		reset,
		aiButtonDisabled,
		resetSoft,
		gameId,
		setGameId,
		getRandomPromptsQueryObj,
	}
}
