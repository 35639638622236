import { sign } from 'aws4'
import { fetchAuthSession } from 'aws-amplify/auth'
import { Method } from 'axios'
import { OutgoingHttpHeaders } from 'http'

import { LOCAL_STORAGE_KEYS } from 'assets/constants'

interface SignedRequest {
	body: string
	headers: Record<string, string>
	host: string
	method: Method
	path: string
	region: string
	service: string
}

export const navigateToRedirectUrl = () => {
	if (window.localStorage.getItem(LOCAL_STORAGE_KEYS.REDIRECT_PATH)) {
		window.location.assign(
			window.localStorage.getItem(
				LOCAL_STORAGE_KEYS.REDIRECT_PATH
			) as string
		)
		window.localStorage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_PATH)
	} else {
		window.location.assign(window.location.origin)
	}
}

export const getSignedRequest = async (
	method: Method,
	path: string,
	body?: string
): Promise<SignedRequest | null> => {
	const functionUrl = process.env.REACT_APP_AWS_API_URL as string
	const { host } = new URL(functionUrl)
	const { tokens, credentials } = await fetchAuthSession()

	let signedRequest: SignedRequest | undefined

	if (credentials) {
		const { accessKeyId, sessionToken, secretAccessKey } = credentials
		const idToken = tokens?.idToken?.toString() ?? ''
		let headers: OutgoingHttpHeaders

		if (body) {
			headers = {
				'Content-Type': 'application/json',
				token: idToken,
			}
		} else {
			headers = {
				token: idToken,
			}
		}
		signedRequest = sign(
			{
				method,
				service: 'lambda',
				region: process.env.REACT_APP_AWS_REGION,
				host,
				path,
				headers,
				body,
			},
			{
				accessKeyId,
				secretAccessKey,
				sessionToken,
			}
		) as SignedRequest

		return signedRequest
	}

	return null
}
