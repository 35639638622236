import { SxProps, Theme } from '@mui/material'

const styles: Record<string, SxProps<Theme>> = {
	circularProgressIcon: {
		color: 'white',
	},
	lowercaseText: {
		textTransform: 'lowercase',
	},
}

export { styles }
