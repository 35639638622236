import { memo } from 'react'

import CustomBreadcrumbs, { TEST_ID } from './BreadCrumbs'
import type { BreadCrumbProps, BreadCrumbsProps } from './BreadCrumbs'

const CustomBreadcrumbsComponent = memo(CustomBreadcrumbs)

export { TEST_ID, BreadCrumbProps, BreadCrumbsProps }

export default CustomBreadcrumbsComponent
