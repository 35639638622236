import { GameType } from 'enums/GameTypeEnum'

export const styles = (
	heroData: GameType
): { [key: string]: React.CSSProperties } => {
	const bgGradient: string = heroData.backgroundGradient
	return {
		mainGrid: {
			paddingTop: '1.5rem',
			paddingBottom: '3rem',
			background: bgGradient,
		},
		heroImg: {
			objectFit: 'contain',
			alignSelf: 'flex-start',
			minHeight: '30rem',
			maxHeight: '45rem',
			height: '100%',
			width: '100%',
		},
	}
}
