import { memo } from 'react'

import ToastAlerts, { TEST_ID } from './ToastAlerts'
import type { Props as ToastAlertsProps } from './ToastAlerts'

const ToastAlertsComponent = memo(ToastAlerts)

export { TEST_ID, ToastAlertsProps }

export default ToastAlertsComponent
