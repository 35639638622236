// ***************************************************
// Configure Feature Flags HERE - Per Environment

export enum FEATURE_FLAGS {
	ALLOW_DEBUG_LEVEL_LOGGING = 'allow-debug-level-logging',
	SHOW_REDESIGNED_GAMEPAGES = 'show-redesigned-gamepages',
}

export type FeatureFlagSet = Record<FEATURE_FLAGS, boolean>

const localFlags: FeatureFlagSet = {
	[FEATURE_FLAGS.ALLOW_DEBUG_LEVEL_LOGGING]: true,
	[FEATURE_FLAGS.SHOW_REDESIGNED_GAMEPAGES]: true,
}

const devFlags: FeatureFlagSet = {
	[FEATURE_FLAGS.ALLOW_DEBUG_LEVEL_LOGGING]: false,
	[FEATURE_FLAGS.SHOW_REDESIGNED_GAMEPAGES]: true,
}

const prodFlags: FeatureFlagSet = {
	[FEATURE_FLAGS.ALLOW_DEBUG_LEVEL_LOGGING]: false,
	[FEATURE_FLAGS.SHOW_REDESIGNED_GAMEPAGES]: true,
}

let features: FeatureFlagSet

if (process.env.NODE_ENV === 'development') {
	// Check for local env
	features = localFlags
} else if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
	// REACT_APP_ENVIRONMENT is currently being set to 'dev' or 'main' by terraform in the ideation-infra repo
	features = devFlags
} else {
	features = prodFlags
}

export default features
