import { memo } from 'react'

import BaseCheckBoxField, {
	CONTAINER_TEST_ID_SUFFIX,
	TEST_ID,
} from './BaseCheckBoxField'
import type { Props as BaseCheckboxFieldProps } from './BaseCheckBoxField'

const BaseCheckBoxFieldComponent = memo(BaseCheckBoxField)

export { TEST_ID, CONTAINER_TEST_ID_SUFFIX, BaseCheckboxFieldProps }

export default BaseCheckBoxFieldComponent
