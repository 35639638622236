import { Theme } from '@mui/material'

// TODO: Move MUI overrides to global Theme
// https://slalom.atlassian.net/browse/BRAIN-1143
export const styles = {
	textFieldContainer: {
		flexGrow: 1,
		marginRight: '20px',
	},
	containerGrid: {
		pb: 2,
		pt: 1,
		pr: 2,
		width: '100%',
	},
	container: {
		mr: 2,
		ml: 4,
	},
	dropDown: (theme: Theme) => ({
		'&.MuiInputBase-root': {
			fontWeight: '400',
			minWidth: '20rem',
			height: '46px',
		},
		'&.MuiOutlinedInput-root': {
			'& .MuiOutlinedInput-notchedOutline': {
				borderRadius: '4px',
				borderWidth: '1px',
			},
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderWidth: '2px',
				borderColor: theme.palette.secondary.main,
			},
			'& .MuiSelect-select': {
				color: theme.palette.secondary.light,
			},
		},
	}),
	menuItem: {
		'&.Mui-focusVisible': {
			backgroundColor: '#F5F5F5',
		},
	},
	chip: {
		'& .MuiChip-label': {
			color: '#000000',
		},
	},
	buttonGroup: {
		width: '100%',
		paddingTop: '1rem',
		justifyContent: 'start',
		display: 'flex',
		flexDirection: 'row',
	},
	buttonGroupButtonContainer: {
		marginRight: '20px',
	},
	buttonGroupText: {
		textTransform: 'none',
	},
	exampleButton: {
		borderRadius: 100,
		marginRight: '2rem',
	},
	circularProgressIcon: (theme: Theme) => ({
		color: theme.palette.secondary.main,
		size: '1rem',
	}),
	loadingContainer: {
		width: '100%',
		textAlign: 'center',
	},
	responseText: (theme: Theme) => ({
		color: theme.palette.text.secondary,
	}),
	textContainer: (theme: Theme) => ({
		border: '1px solid',
		borderColor: theme.palette.secondary.main,
		padding: 2,
	}),
	responseContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		paddingRight: 2,
	},
	wrap: {
		whiteSpace: 'pre-wrap',
		wordBreak: 'keep-all',
	},
	iconButton: {
		borderRadius: '4px',
		padding: '4px 5px 4px 9px',
	},
	iconButtonSvg: {
		fontSize: '28px',
	},
	gridContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	filterGrid: {
		pb: 2,
		pt: 4,
		mb: 2,
		rowGap: 2,
	},
	filterSection: {
		gap: '3rem',
		width: '100%',
	},
	itemCountSection: {
		width: 'auto',
	},
	itemCountText: (theme: Theme) => ({
		alignSelf: 'center',
		color: '#000000DE',
		opacity: 0.87,
		fontSize: theme.typography.body2,
	}),
	searchBarStyle: (theme: Theme) => ({
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.secondary.main,
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.secondary.main,
		},
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.secondary.main,
		},
		backgroundColor: '#FFFFFF',
		borderRadius: '4px',
		height: 39,
		width: 313,
	}),
	searchBox: {
		float: {
			xl: 'right',
			xs: 'left',
		},
		pr: 4,
		display: 'flex',
		alignItems: 'center',
	},
}
