import { memo } from 'react'

import Node from './Node'
import type { Props as NodeProps } from './Node'

const NodeComponent = memo(Node)

export { NodeProps }

export default NodeComponent
