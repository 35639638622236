import { SubmittedIdeaCSV } from 'models/ideaModels'

import { IDEA_HEADER_MAPPING } from 'enums/IdeaEnums'

const STICKY_SPACING = 250

const getStickiesBodyParams = (
	headers: string[],
	idea: SubmittedIdeaCSV,
	xPosition: number,
	yPosition: number
) => {
	return headers.map((header) => {
		xPosition += STICKY_SPACING
		return {
			type: 'sticky_note',
			data: { content: idea[header as keyof SubmittedIdeaCSV] },
			style: { fillColor: 'light_yellow' },
			position: { x: xPosition, y: yPosition },
		}
	})
}

const getHeadersBodyParams = (headers: string[], xPosition: number) => {
	return headers.map((header) => {
		xPosition += STICKY_SPACING
		return {
			type: 'sticky_note',
			data: {
				content: IDEA_HEADER_MAPPING[header as keyof SubmittedIdeaCSV],
			},
			style: { fillColor: 'violet' },
			position: { x: xPosition },
		}
	})
}

export { getStickiesBodyParams, getHeadersBodyParams }
