import { useCallback, useState } from 'react'

import { AxiosResponse } from 'axios'
import logger from 'utils/logger'

const DEFAULT_RESPONSE = {
	loading: false,
	status: undefined,
	success: false,
	error: false,
}

type CallFunction<T, U, R = AxiosResponse<U>> = (...arg: T[]) => Promise<R>
const useApi = <T, U>(
	call: CallFunction<T, U>,
	{ defaultData = null }: { defaultData?: U | null } = {}
) => {
	// we're storing the data as a separate state so that
	// dependencies work properly for other hooks, otherwise
	// we get infinite re-renders for useEffect dependencies sometimes
	const [data, setData] = useState<U | null>(defaultData)
	const [response, setResponse] = useState<{
		error: boolean
		loading: boolean
		status?: number
		success: boolean
	}>(DEFAULT_RESPONSE)

	const resetRequest = useCallback(() => {
		setData(defaultData)
		setResponse((response) => ({
			...response,
			status: undefined,
			success: false,
			error: false,
			loading: false,
		}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const clearRequest = useCallback(() => {
		setData(null)
		setResponse(DEFAULT_RESPONSE)
	}, [])

	const requestApi = useCallback(
		async (...args: Parameters<CallFunction<T, U>>) => {
			setResponse((response) => ({
				...response,
				status: undefined,
				success: false,
				error: false,
				loading: true,
			}))
			if (call) {
				return await call(...args)
					.then(({ status, data }) => {
						if (status === 200) {
							setResponse((response) => ({
								...response,
								loading: false,
								status,
								success: true,
								error: false,
							}))
							setData(data)
						} else {
							setResponse((response) => ({
								...response,
								loading: false,
								status,
								success: true,
								error: true,
							}))
							setData(data)
						}
					})
					.catch((err: Error) => {
						logger.error(err)
						setResponse((response) => ({
							...response,
							loading: false,
							status: undefined,
							success: false,
							error: true,
						}))
						setData(data)
					})
			}
		},
		[call, data]
	)

	return {
		...response,
		clearRequest,
		data,
		requestApi,
		resetRequest,
	}
}

export default useApi
