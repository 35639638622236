import { memo } from 'react'

import FormDateTimePicker, { TEST_ID } from './FormDateTimePicker'
import type { Props as FormDateTimePickerProps } from './FormDateTimePicker'

const FormDateTimePickerComponent = memo(FormDateTimePicker)

export { TEST_ID, FormDateTimePickerProps }

export default FormDateTimePickerComponent
