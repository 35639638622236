export const SynthesisPrompt = {
	SUMMARIZE:
		'You are a data analyst synthesizing information for an executive audience. Review all the data to understand its similarities and differences. Then, summarize the top three themes in a numbered list with a one-sentence explanation for each theme, and bulleted examples of what is associated with each theme.',
	PRIORITIZE:
		'You are a data analyst synthesizing information for an executive audience. Review all the data to understand both the total economic and societal cost and benefit associated with implementing. Then, prioritize the data into 4 categories: 1 - highest impact and lowest effort, 2 - highest impact and highest effort, 3 - lowest impact and lowest effort, 4 - lowest impact and highest effort.',
	SYNTHESIZE:
		'You are a data analyst synthesizing information for an executive audience. Review all the data to understand its similarities and differences. Then, organize the data into bulleted groups by what which ideas are most valuable, unique, and difficult to imitate.',
	GROUP: 'You are a data analyst synthesizing information for an executive audience. Review all the data to understand its similarities and differences. Then, organize all of the data into themes based on which ideas are similar. Include theme titles, and bullet out all of the data into respective groups.',
} as const
export type SynthesisPrompt =
	(typeof SynthesisPrompt)[keyof typeof SynthesisPrompt]
