import { Theme } from '@mui/material'

export const styles = {
	arcLabsLogoContainer: {
		display: 'grid',
		maxHeight: '100px',
	},
	arcLabsLogoLink: (theme: Theme) => ({
		svg: {
			paddingLeft: theme.spacing(5),
		},
	}),
	AppBar: {
		alignItems: 'center',
		backgroundColor: 'background.paper',
		justifyContent: 'space-between',
		zIndex: 3,
	},
	AvatarIcon: {
		cursor: 'pointer',
	},
	BrandingContainer: {
		alignItems: 'center',
		cursor: 'pointer',
		direction: 'row',
		justifyItems: 'space-between',
		margin: '2.5rem 0 2.5rem 0rem',
	},
	ButtonGroupContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: '3.3rem',
		justifyContent: 'flex-end',
	},
	drawer: (theme: Theme) => ({
		display: { xs: 'block', lg: 'none' },

		'& .MuiDrawer-paper': {
			background: 'linear-gradient(180deg, #45657B 0%, #0C4152 100%)',
			boxSizing: 'border-box',
			color: theme.palette.common.white,
			padding: theme.spacing(6.25, 0.125, 14, 6.5),
			width: { md: '380px', xs: '288px' },
		},
	}),
	drawerCloseButton: (theme: Theme) => ({
		display: 'block',
		color: theme.palette.common.white,
		position: 'absolute',
		right: '0',
		top: '0',
	}),
	logoutButton: () => ({
		backgroundColor: 'background.paper',
		borderRadius: '2px !important',
		borderColor: 'secondary.main',
		fontWeight: '400',
		'&:hover': {
			backgroundColor: 'background.paper',
			fontWeight: '700',
		},
	}),
	loginOrSalutations: () => ({
		display: 'inline-block',
	}),
	NavBarGridContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
	},
	navList: (theme: Theme) => ({
		display: 'inline-flex',

		'.MuiListItem-root:last-child': {
			marginRight: theme.spacing(2),
		},

		'& .MuiListItemButton-root': {
			'&:hover': {
				backgroundColor: 'transparent',

				'.MuiTypography-root': {
					WebkitTextStroke: `1px ${theme.palette.secondary.main}`,
				},
			},
		},
	}),
	navListMobile: (theme: Theme) => ({
		padding: '16px 0  16px 0',

		'.MuiTypography-root': {
			color: theme.palette.common.white,
			fontStyle: 'normal',
			fontFamily: 'Inter',
			fontSize: { md: '34px', xs: '25px' },
			fontWeight: '400',
			lineHeight: '123.5%',
		},
		'& .MuiListItem-root': {
			'& .MuiListItemButton-root': {
				padding: theme.spacing(0),

				'& .MuiListItemText-root': {
					'&.current-page > .MuiTypography-root': {
						fontWeight: theme.typography.fontWeightBold,
					},
				},

				'&:hover': {
					backgroundColor: 'transparent',

					'.MuiTypography-root': {
						WebkitTextStroke: `1px ${theme.palette.common.white}`,
					},
				},
			},
		},
	}),
	activeNavItemText: (theme: Theme) => ({
		color: theme.palette.secondary.main,
		textTransform: 'capitalize',
		whiteSpace: 'nowrap',

		[theme.breakpoints.down('md')]: {
			color: theme.palette.common.white,
		},
	}),
	inactiveNavItemText: (theme: Theme) => ({
		color: theme.palette.secondary.light,
		textTransform: 'capitalize',
		whiteSpace: { md: 'normal', lg: 'nowrap' },

		[theme.breakpoints.down('md')]: {
			color: theme.palette.common.white,
		},
	}),
	UserLogin: {
		display: 'flex',
	},
	VerticalLine: {
		paddingLeft: '.75rem',
		paddingRight: '.75rem',
	},
	IdeaStreakContainer: (theme: Theme) => ({
		color: theme.palette.secondary.main,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		marginRight: '1rem',
		'div:last-child': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '0 0.5rem',
			marginLeft: '1rem',
			backgroundColor: '#274A62',
			color: theme.palette.common.white,
			borderRadius: '2px',
		},
	}),
}
