export const styles = {
	mainContainer: {
		display: 'flex',
		'@media (max-width: 600px)': {
			flexDirection: 'column',
			gap: '2rem',
		},
	},
	container: {
		alignItems: 'center',
	},
	cardsContainer: {
		display: 'flex',
		justifyContent: 'center',
		'@media (max-width: 1000px)': {
			alignItems: 'center',
		},
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background: 'linear-gradient(180deg, #C8003C 51%, #FF437B 100%)',
	},
	rectangle: {
		height: '48px',
		width: '208px',
		border: '1px solid #0000003B',
		borderRadius: '4px',
		justifyContent: 'center',
		alignItems: 'center',
	},
	circularProgressIcon: {
		color: 'secondary.main',
	},
	loadingText: {
		paddingLeft: '10px',
	},
	cards: {
		display: 'flex',
	},
	leftArrow: {
		boxSizing: 'border-box',
		width: '170px',
		height: '1px',
		transform: 'rotate(180deg)',
		/* Inside auto layout */
		flex: 'none',
		order: 5,
		flexGrow: 0,
	},
}
