export const styles = {
	gameCardContainer: {
		boxSizing: 'border-box',
		width: {
			sm: '584px',
			xs: '327px',
		},
		height: { sm: '345px', xs: '214px' },
		flexShrink: '0',
		borderRadius: {
			sm: '35px',
			xs: '20px',
		},
		background: '#FFF',
		border: {
			sm: '5px solid #FFF',
			xs: '2px solid #FFF',
		},
		boxShadow: '4px 5px 4px 0px rgba(0, 0, 0, 0.12)',
		'&:hover': {
			boxShadow: '4px 5px 4px 2px rgba(0, 0, 0, 0.24)',
		},
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	gameCardUseCaseTextContainer: {
		width: {
			sm: '574px',
			xs: '323px',
		},
		height: {
			sm: '86px',
			xs: '57px',
		},
		borderTopLeftRadius: {
			sm: '35px',
			xs: '20px',
		},
		borderTopRightRadius: {
			sm: '35px',
			xs: '20px',
		},
	},
	gameCardUseCaseText: {
		fontSize: {
			sm: '30px',
			xs: '20px',
		},
		color: '#FFF',
		fontWeight: {
			sm: '700',
			xs: '500',
		},
		marginTop: {
			sm: '23px',
			xs: '13px',
		},
		marginLeft: {
			sm: '30px',
			xs: '23px',
		},
	},
	gameCardContentContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: {
			sm: '574px',
			xs: '323px',
		},
		height: {
			sm: '249px',
			xs: '153px',
		},
		borderBottomLeftRadius: {
			sm: '35px',
			xs: '20px',
		},
		borderBottomRightRadius: {
			sm: '35px',
			xs: '20px',
		},
	},
	gameCardMainContentContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: {
			sm: '11px',
			xs: '4px',
		},
		justifyContent: 'left',
		height: {
			sm: '249px',
			xs: '153px',
		},
	},
	gameCardTitle: {
		color: '#0C4152',
		fontSize: {
			sm: '25px',
			xs: '14px',
		},
		fontStyle: 'normal',
		fontWeight: {
			sm: '700',
			xs: '500',
		},
		lineHeight: '123.5%',
		letterSpacing: '0.25px',
		paddingLeft: '23px',
		paddingTop: {
			sm: '25px',
			xs: '11px',
		},
	},
	gameCardDescription: {
		color: '#0C4152',
		fontSize: {
			sm: '24px',
			xs: '14px',
		},
		fontStyle: 'normal',
		lineHeight: '123.5%',
		letterSpacing: '0.25px',
		paddingLeft: '23px',
	},
	gameCardButton: {
		':hover': { backgroundColor: 'transparent' },
		fontSize: {
			sm: '20px',
			xs: '15px',
		},
		fontStyle: 'normal',
		fontWeight: {
			sm: '700px',
			xs: '500px',
		},
		lineHeight: '26px',
		letterSpacing: '0.46px',
		textTransform: 'uppercase',
		justifyContent: 'left',
		padding: '0 0 0 23px',
		marginTop: {
			sm: '10px',
			xs: '4px',
		},
	},
	gameCardImageContainer: {
		marginRight: {
			sm: '25px',
			xs: '0px',
		},
	},
	gameCardImgLarge: {
		width: '200px',
		height: '200px',
	},
	gameCardImgSmall: {
		width: '125px',
		height: '125px',
	},
}
