import { memo } from 'react'

import { ButtonWithIcon } from './ButtonWithIcon'
import type { Props as ButtonWithIconProps } from './ButtonWithIcon'

const ButtonWithIconComponent = memo(ButtonWithIcon)

export { ButtonWithIconProps }

export default ButtonWithIconComponent
