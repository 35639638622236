import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import { Box, Container } from '@mui/system'

import { MusicalChairsModel } from 'models/MusicalChairsModel'
import { Game } from 'models/gameModels'

import { useToastContext } from 'contexts/ToastProvider'

import BreadcrumbComponent from 'components/BreadcrumbMenu'

import { defaultErrorMessage } from 'assets/alertText'

import { GAME_STEP } from 'enums/GameStepEnum'
import { GAME_TYPE } from 'enums/GameTypeEnum'

import Hero from '../../../components/Hero/Hero'
import { styles } from './MusicalChairs.styles'

const TEST_ID = 'musical-chairs'

const MusicalChairs = ({ game }: { game?: Game }) => {
	const { businessProcess, setBusinessProcess, companyName, setCompanyName } =
		MusicalChairsModel()

	const { showAlert } = useToastContext()

	const [step, setStep] = useState<GAME_STEP>(GAME_STEP.Step1)
	const [isLoading, setIsLoading] = useState(false)

	const handlePrompts = async () => {
		try {
			setStep(GAME_STEP.Step2)
		} catch (error) {
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<Box>
			<Hero
				step={step}
				gameTypeEnum={GAME_TYPE.MUSICAL_CHAIRS}
				innovationCompany={companyName}
				companyProblem={businessProcess}
				setInnovationCompany={setCompanyName}
				setCompanyProblem={setBusinessProcess}
				isLoading={isLoading}
				createPrompt={handlePrompts}
				hideEdit={!!game?.gameTitle || !!game?.endTime}
			/>
			<BreadcrumbComponent
				gameDescription="for challenging assumptions"
				gameTypeEnum={GAME_TYPE.MUSICAL_CHAIRS}
				game={game}
			/>
			<Grid data-testid={`${TEST_ID}-container`} sx={styles.container} />
			<Container id={`${TEST_ID}-container`}>
				<h1>Musical Chairs redesign coming soon</h1>
			</Container>
		</Box>
	)
}

export default MusicalChairs
