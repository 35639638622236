import { memo } from 'react'

import PrototypeSubmittedIdeaModal from './PrototypeSubmittedIdeaModal'
import type { Props as PrototypeSubmittedIdeaModalProps } from './PrototypeSubmittedIdeaModal'

const PrototypeModalComponent = memo(PrototypeSubmittedIdeaModal)

export { PrototypeSubmittedIdeaModalProps }

export default PrototypeModalComponent
