import React, { ReactNode } from 'react'

import { Button, ButtonProps } from '@mui/material'

export const TEST_ID = 'text-and-icon-button-'

export type Props = {
	color?: string
	'data-testid'?: string
	handleClick: () => void
	icon: ReactNode
	iconPosition: string
	text: string
} & ButtonProps

const TextAndIconButton = ({
	icon,
	text,
	variant,
	color,
	handleClick,
	iconPosition,
	...props
}: Props) => {
	return (
		<>
			<Button
				data-testid={props['data-testid'] ?? TEST_ID}
				color={color}
				variant={variant}
				onClick={handleClick}
				endIcon={iconPosition === 'end' ? icon : null}
				startIcon={iconPosition === 'start' ? icon : null}
			>
				{text}
			</Button>
		</>
	)
}

export default TextAndIconButton
