import React from 'react'

import { Box, Chip } from '@mui/material'

import { BusinessTopic, TopicType } from 'enums/TopicTypeEnum'

export const TEST_ID = 'business-topic-chip'

export interface Props {
	color?: string
	editable: boolean
	innovationTopic: TopicType
	setInnovationTopic: (val: TopicType) => void
}

const TopicChips = ({
	innovationTopic,
	setInnovationTopic,
	editable,
	color,
}: Props) => {
	return (
		<Box display="flex" columnGap="2rem" rowGap="1rem" flexWrap="wrap">
			{Object.values(BusinessTopic).map((value, i: number) => (
				<Chip
					disabled={!editable}
					key={i}
					label={value}
					data-testid={`${TEST_ID}__chip-topic-${i}`}
					variant={innovationTopic === value ? 'filled' : 'outlined'}
					clickable={true}
					onClick={(e) => {
						setInnovationTopic(
							(e.target as HTMLDivElement)
								.textContent as TopicType
						)
					}}
					sx={{
						paddingX: '0.5rem',
						color: 'black',
						'&.MuiChip-root:hover': {
							bgcolor:
								innovationTopic === value
									? color
									: 'transparent',
						},
						backgroundColor:
							innovationTopic === value ? color : 'white',
					}}
				/>
			))}
		</Box>
	)
}

export default TopicChips
