import { SubmittedIdeaCSV } from '../../models/ideaModels'
import {
	getHeadersBodyParams,
	getStickiesBodyParams,
} from '../../utils/miroUtils'

const MIRO_REST_API_URL = 'https://api.miro.com/v2'
const MIRO_REST_API_URL_EXPERIMENTAL = MIRO_REST_API_URL + '-experimental'
const STICKY_SPACING = 250
const HEADERS_TO_EXCLUDE = [
	'updatedTime',
	'gameTypeId',
	'submittedId',
	'innovationTopicManual',
]

const generateFetchOptions = (accessToken: string, body: string) => {
	return {
		method: 'POST',
		headers: {
			accept: 'application/json',
			'content-type': 'application/json',
			authorization: `Bearer ${accessToken}`,
		},
		body,
	}
}

const createBoard = async (accessToken: string) => {
	const name = `Brainstorm Session - ${new Date().toLocaleString()}`

	const boardsUrl = MIRO_REST_API_URL + '/boards'
	const options = generateFetchOptions(accessToken, JSON.stringify({ name }))

	const response = await fetch(boardsUrl, options)
	return response.json()
}

const createStickies = async (
	accessToken: string,
	boardId: string,
	ideas: SubmittedIdeaCSV[],
	headers: string[]
) => {
	const bulkItemsUrl =
		MIRO_REST_API_URL_EXPERIMENTAL + `/boards/${boardId}/items/bulk`

	const selectedHeaders = headers.filter(
		(header) => HEADERS_TO_EXCLUDE.indexOf(header) === -1
	)

	const startingXPosition = -(
		STICKY_SPACING * Math.round(selectedHeaders.length / 2)
	)

	let startingYPosition = STICKY_SPACING

	// 1. Create Column Header Stickies
	const headersBodyParams = getHeadersBodyParams(
		selectedHeaders,
		startingXPosition
	)

	const columnOptions = generateFetchOptions(
		accessToken,
		JSON.stringify(headersBodyParams)
	)

	await fetch(bulkItemsUrl, columnOptions)

	// 2. Create Idea Row Stickies
	await Promise.all(
		ideas.map(async (idea) => {
			const ideasBodyParams = getStickiesBodyParams(
				selectedHeaders,
				idea,
				startingXPosition,
				startingYPosition
			)

			startingYPosition += STICKY_SPACING

			const rowOptions = generateFetchOptions(
				accessToken,
				JSON.stringify(ideasBodyParams)
			)

			await fetch(bulkItemsUrl, rowOptions)
		})
	)
}

const createBoardAndStickies = async (
	accessToken: string,
	ideas: SubmittedIdeaCSV[],
	headers: string[]
) => {
	const board = await createBoard(accessToken)

	await createStickies(accessToken, board.id, ideas, headers)

	return board
}

export { createBoardAndStickies }
