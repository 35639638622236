import { memo } from 'react'

import TopicChips from './TopicChips'
import type { Props as TopicChipsProps } from './TopicChips'

const TopicChipsComponent = memo(TopicChips)

export { TopicChipsProps }

export default TopicChipsComponent
