import React from 'react'

import { palette } from 'theme/palette'

export interface Props {
	disabled?: boolean
}

export const GenAiPromptText = ({ disabled }: Props) => {
	const TEST_ID = 'gen-ai-prompt'
	const color = disabled ? palette.text.disabled : palette.text.primary
	return (
		<>
			{/* eslint-disable max-len */}
			<svg
				data-testid={`${TEST_ID}-text`}
				fill="none"
				height="55"
				viewBox="0 0 51 55"
				width="55"
				xmlns="http://www.w3.org/2000/svg"
			>
				<line
					stroke={color}
					strokeLinecap="round"
					strokeWidth="2"
					x1="11"
					x2="50"
					y1="4"
					y2="4"
				/>
				<line
					stroke={color}
					strokeLinecap="round"
					strokeWidth="2"
					x1="1"
					x2="37"
					y1="11"
					y2="11"
				/>
				<path
					d="M3.23574 1.28681C2.69732 1.28681 2.20983 1.50509 1.85746 1.85746C1.50509 2.20983 1.28681 2.69732 1.28681 3.23574C1.28681 3.77417 1.50509 4.26166 1.85746 4.61402C2.20983 4.96639 2.69732 5.18467 3.23574 5.18467C3.77417 5.18467 4.26166 4.96639 4.61402 4.61402C4.96639 4.26166 5.18467 3.77417 5.18467 3.23574C5.18467 2.69732 4.96639 2.20983 4.61402 1.85746C4.26166 1.50509 3.77417 1.28681 3.23574 1.28681ZM0.947959 0.947959C1.53316 0.36276 2.34183 0 3.23574 0C4.12965 0 4.93729 0.361721 5.52352 0.947959C6.10872 1.53316 6.47148 2.34183 6.47148 3.23574C6.47148 4.12965 6.10976 4.93729 5.52352 5.52352C4.93833 6.10872 4.12965 6.47148 3.23574 6.47148C2.34183 6.47148 1.5342 6.10976 0.947959 5.52352C0.36276 4.93833 0 4.12965 0 3.23574C0 2.34183 0.361721 1.53316 0.947959 0.947959Z"
					fill={color}
				/>
				<line
					stroke={color}
					strokeLinecap="round"
					strokeWidth="2"
					x1="11"
					x2="44"
					y1="19"
					y2="19"
				/>
				<line
					stroke={color}
					strokeLinecap="round"
					strokeWidth="2"
					x1="1"
					x2="50"
					y1="26"
					y2="26"
				/>
				<path
					d="M3.23574 16.2868C2.69732 16.2868 2.20983 16.5051 1.85746 16.8575C1.50509 17.2098 1.28681 17.6973 1.28681 18.2357C1.28681 18.7742 1.50509 19.2617 1.85746 19.614C2.20983 19.9664 2.69732 20.1847 3.23574 20.1847C3.77417 20.1847 4.26166 19.9664 4.61402 19.614C4.96639 19.2617 5.18467 18.7742 5.18467 18.2357C5.18467 17.6973 4.96639 17.2098 4.61402 16.8575C4.26166 16.5051 3.77417 16.2868 3.23574 16.2868ZM0.947959 15.948C1.53316 15.3628 2.34183 15 3.23574 15C4.12965 15 4.93729 15.3617 5.52352 15.948C6.10872 16.5332 6.47148 17.3418 6.47148 18.2357C6.47148 19.1296 6.10976 19.9373 5.52352 20.5235C4.93833 21.1087 4.12965 21.4715 3.23574 21.4715C2.34183 21.4715 1.5342 21.1098 0.947959 20.5235C0.36276 19.9383 0 19.1296 0 18.2357C0 17.3418 0.361721 16.5332 0.947959 15.948Z"
					fill={color}
				/>
				<line
					stroke={color}
					strokeLinecap="round"
					strokeWidth="2"
					x1="11"
					x2="47"
					y1="34"
					y2="34"
				/>
				<line
					stroke={color}
					strokeLinecap="round"
					strokeWidth="2"
					x1="1"
					x2="26"
					y1="41"
					y2="41"
				/>
				<path
					d="M3.23574 31.2868C2.69732 31.2868 2.20983 31.5051 1.85746 31.8575C1.50509 32.2098 1.28681 32.6973 1.28681 33.2357C1.28681 33.7742 1.50509 34.2617 1.85746 34.614C2.20983 34.9664 2.69732 35.1847 3.23574 35.1847C3.77417 35.1847 4.26166 34.9664 4.61402 34.614C4.96639 34.2617 5.18467 33.7742 5.18467 33.2357C5.18467 32.6973 4.96639 32.2098 4.61402 31.8575C4.26166 31.5051 3.77417 31.2868 3.23574 31.2868ZM0.947959 30.948C1.53316 30.3628 2.34183 30 3.23574 30C4.12965 30 4.93729 30.3617 5.52352 30.948C6.10872 31.5332 6.47148 32.3418 6.47148 33.2357C6.47148 34.1296 6.10976 34.9373 5.52352 35.5235C4.93833 36.1087 4.12965 36.4715 3.23574 36.4715C2.34183 36.4715 1.5342 36.1098 0.947959 35.5235C0.36276 34.9383 0 34.1296 0 33.2357C0 32.3418 0.361721 31.5332 0.947959 30.948Z"
					fill={color}
				/>
			</svg>
		</>
	)
}
