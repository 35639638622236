import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Container } from '@mui/material'
import logger from 'utils/logger'
import { ShowWarningModal, UseBlocker } from 'utils/useBlocker'

import { Conversation, SimonSaysModel } from 'models/SimonSaysModel'
import { Game } from 'models/gameModels'

import { useIdeaStreakContext } from 'contexts/IdeaStreakProvider'
import { useModalContext } from 'contexts/ModalProvider'
import { useToastContext } from 'contexts/ToastProvider'

import {
	postProcessImage,
	postSlotMachinePrompt,
	postSlotMachineResponse,
} from 'services/completions.service'
import { getRandomPrompts, postIdea } from 'services/ideas.service'

import BreadCrumbs from 'components/BreadCrumbs'
import CustomGameTitleBar from 'components/CustomGameTitleBar/CustomGameTitleBar'
import Hero from 'components/Hero/Hero'

import { alertTitleTextMap, defaultErrorMessage } from 'assets/alertText'
import { brainstormText } from 'assets/brainstormText'
import { SUBMITTED_IDEAS } from 'assets/routes'

import { GAME_STEP } from 'enums/GameStepEnum'
import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'
import { CustomerJourneyTopicType } from 'enums/TopicTypeEnum'

import Section1 from './components/Section1/Section1'
import Section2 from './components/Section2'
import Section3 from './components/Section3'

export const TEST_ID = 'simon-says'

export const SimonSaysStep = {
	Step1: 0,
	Step2: 1,
} as const
export type SimonSaysStepType =
	(typeof SimonSaysStep)[keyof typeof SimonSaysStep]

let aiResponse = {
	content: '',
	role: 'assistant',
}
const SimonSays = ({ game }: { game?: Game }) => {
	const [submitIdeas, setSubmitIdeas] = useState(false)
	const [ideaPrompt, setIdeaPrompt] = useState(false)
	const {
		customerProblem,
		customerType,
		randomElement,
		innovationCompany,
		setInnovationCompany,
		promptConversation,
		setPromptConversation,
		customerJourney,
		setCustomerJourney,
		authorIdeation,
		setAuthorIdeation,
		innovationTopic,
		setInnovationTopic,
		setPromptResponse,
		responseConversation,
		setResponseConversation,
		currentResponses,
		setCurrentResponses,
		promptToJSON,
		responseToJSON,
		imageToJSON,
		resetSoft,
		resetImage,
		gameToJSON,
		reset,
		generateImageDisabled,
		generateScenarioDisabled,
		imageData,
		setImageData,
		imageAvailable,
		setImageAvailable,
		submitIdeasDisabled,
		setOnlyRandom,
		setCustomerValues,
	} = SimonSaysModel()

	useEffect(() => {
		if (game) {
			setInnovationCompany(game.innovationCompany)
			setInnovationTopic(game.innovationTopic)
			setCustomerJourney(game.companyProblem)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game])

	const { showModal } = useModalContext()
	const fields = [authorIdeation]
	const blocker = UseBlocker(fields, submitIdeas)

	useEffect(() => {
		setIdeaPrompt(authorIdeation.length >= 8)
	}, [authorIdeation])

	useEffect(() => {
		if (!submitIdeas) {
			return ShowWarningModal(blocker, showModal)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blocker])

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (ideaPrompt) {
				event.preventDefault()
			}
		}

		if (ideaPrompt) {
			window.addEventListener('beforeunload', handleBeforeUnload)
		} else {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [ideaPrompt])

	const navigate = useNavigate()

	const [isAiImageLoading, setIsAiImageLoading] = useState(false)
	const { setStreakBegun, updateStreak } = useIdeaStreakContext()
	const { showAlert } = useToastContext()

	const [isLoading, setIsLoading] = useState(false)
	const [isRandomLoading, setIsRandomLoading] = useState(false)
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)
	const [, setIsAiLoading] = useState(false)
	const [step, setStep] = useState<GAME_STEP>(GAME_STEP.Step1)

	const userMessage = {
		content: '',
		role: 'user',
	}

	const updateAIResponse = (content: string) => {
		return {
			content,
			role: 'assistant',
		}
	}

	const resetAllField = () => {
		reset()
	}

	const handleAiResponseClick = async (additionalProps = {}) => {
		setIsAiLoading(true)

		try {
			// setup the conversation
			const prompt = responseToJSON()
			prompt.conversation = [...responseConversation, userMessage]

			const response = await postSlotMachineResponse({
				...prompt,
				...additionalProps,
			})

			if (response.status === 200 && response.data) {
				let msg: string = ''
				if (
					response.data.responses &&
					Array.isArray(response.data.responses)
				) {
					msg = JSON.stringify(response.data)
				}

				if (msg) {
					// add response to state
					setResponseConversation((prevMessages) => [
						...prevMessages,
						userMessage,
						updateAIResponse(msg),
					])

					setCurrentResponses(response.data.responses)
				} else {
					if (showAlert) {
						showAlert(defaultErrorMessage)
					}
				}
			} else {
				throw Error(`response received ${response}`)
			}
		} catch (error) {
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsAiLoading(false)
		}
	}

	const generateAiImage = async () => {
		resetImage()
		setIsAiImageLoading(true)

		try {
			const response = await postProcessImage(imageToJSON())

			setImageData(response.data)
			setImageAvailable(true)
		} finally {
			setIsAiImageLoading(false)
		}
	}

	const viewSubmittedIdeas = () => {
		window.scrollTo(0, 0)
		navigate(SUBMITTED_IDEAS)
	}

	const refreshRandom = async () => {
		setIsRandomLoading(true)

		try {
			const prompt = promptToJSON()

			const response = await getRandomPrompts({
				limit: 1,
				previousPrompts: prompt.previousRandomElements,
			})

			if (response.status === 200 && response.data) {
				// setup the page correctly
				setOnlyRandom(
					response.data.prompts,
					response.data.resetPreviousPrompts
				)
			}
		} catch (error) {
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsRandomLoading(false)
		}
	}

	const refreshPrompt = async () => {
		if (
			step === GAME_STEP.Step2 &&
			!submitIdeas &&
			!!authorIdeation.length
		) {
			return ShowWarningModal(
				{
					state: 'blocked',
					location: {
						pathname: '',
						search: '',
						hash: '',
						state: null,
						key: 'kxdgy6c2',
					},
					reset: () => logger.debug('reset'),
					proceed: () => createPrompt(),
				},
				showModal
			)
		}

		createPrompt()
	}

	const createPrompt = async () => {
		setIsLoading(true)

		resetSoft()

		try {
			// setup the conversation
			const prompt = promptToJSON()
			prompt.conversation = [...promptConversation, userMessage]

			setStep(GAME_STEP.Step2)
			window.scrollTo(0, 0)
			const response = await postSlotMachinePrompt(prompt)

			if (response.status === 200 && response.data) {
				handleAiResponseClick({
					customerProblem: response.data.customerProblem,
					customerType: response.data.customerType,
					randomElement: response.data.randomElement,
				})

				const msg = JSON.stringify(response.data)
				// add response to state
				aiResponse = {
					content: msg,
					role: 'assistant',
				}

				setPromptConversation((prevMessages: Conversation[]) => [
					...prevMessages,
					userMessage,
					aiResponse,
				])

				// setup the page correctly
				setPromptResponse(response.data)
			}
		} catch (error) {
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsLoading(false)
		}
	}

	const handleSubmitIdeas = async () => {
		setIsSubmitButtonLoading(true)
		setIsLoading(true)
		setSubmitIdeas(true)

		try {
			const response = await postIdea(gameToJSON())

			if (showAlert) {
				showAlert({
					severity:
						response.status === 200
							? ToastSeverity.SUCCESS
							: ToastSeverity.ERROR,
				})
			}
		} catch (error) {
			if (showAlert) {
				showAlert({
					title: alertTitleTextMap.submitIdeaError.title,
					bodyText: alertTitleTextMap.submitIdeaError.text,
					severity: ToastSeverity.ERROR,
				})
			}
		} finally {
			setIsLoading(false)
			setIsSubmitButtonLoading(false)
			setStreakBegun(true)
			updateStreak()
			// Load new prompt
			createPrompt()
		}
	}

	const handleManualPromptChange = (
		customerType: string,
		customerProblem: string
	) => {
		setCustomerValues(customerType, customerProblem)
		handleAiResponseClick({
			customerProblem,
			customerType,
			randomElement,
		})
		const msg = JSON.stringify({
			customerProblem,
			customerType,
			randomElement,
			resetPreviousElements: false,
		})

		aiResponse = {
			content: msg,
			role: 'user',
		}

		setPromptConversation((prevMessages: Conversation[]) => [
			...prevMessages,
			userMessage,
			aiResponse,
		])
	}

	return (
		<Box>
			<Hero
				step={step}
				gameTypeEnum={GAME_TYPE.SIMON_SAYS}
				innovationTopic={innovationTopic as CustomerJourneyTopicType}
				innovationCompany={innovationCompany}
				companyProblem={customerJourney}
				isLoading={isLoading}
				setCompanyProblem={setCustomerJourney}
				setInnovationCompany={setInnovationCompany}
				setInnovationTopic={setInnovationTopic}
				createPrompt={createPrompt}
				hideEdit={Boolean(game && (game.gameTitle || game.endTime))}
			/>
			<Container
				data-testid={`${TEST_ID}-container`}
				maxWidth={step === GAME_STEP.Step1 ? 'md' : 'lg'}
				sx={{
					paddingTop: '3rem',
					paddingBottom: '3rem',
					paddingX: '2rem',
				}}
			>
				<Box paddingBottom={'2rem'}>
					<BreadCrumbs
						breadcrumbs={[
							{
								label: '1. INNOVATION TOPIC',
								active: step === SimonSaysStep.Step1,
								onClick:
									step !== SimonSaysStep.Step1
										? () => setStep(SimonSaysStep.Step1)
										: undefined,
							},
							{
								label: '2. GENAI PROMPTS & 3. IDEATION',
								active: step !== SimonSaysStep.Step1,
							},
						]}
					/>
				</Box>
				{game && (game.gameTitle || game.endTime) && (
					<CustomGameTitleBar game={game} />
				)}
				{step === GAME_STEP.Step1 && (
					<Section1
						isLoading={isLoading}
						innovationCompany={innovationCompany}
						setInnovationCompany={setInnovationCompany}
						customerJourney={customerJourney}
						setCustomerJourney={setCustomerJourney}
						generateScenarioDisabled={generateScenarioDisabled}
						resetAllField={resetAllField}
						createPrompt={createPrompt}
						editable={
							game
								? game.gameTitle === '' && game.endTime === ''
								: true
						}
					/>
				)}
				{step === GAME_STEP.Step2 && (
					<>
						<Section2
							isAiImageLoading={isAiImageLoading}
							imageData={imageData}
							imageAvailable={imageAvailable}
							loading={isLoading}
							customerProblem={customerProblem}
							customerType={customerType}
							manualChangeCustomerPrompts={
								handleManualPromptChange
							}
							randomElement={randomElement}
							currentResponses={currentResponses}
							createPrompt={refreshPrompt}
							generateAiImage={generateAiImage}
							generateImageDisabled={generateImageDisabled}
							resetImage={resetImage}
							refreshRandom={refreshRandom}
							isRandomLoading={isRandomLoading}
							popperContent={brainstormText.simonSays.popper}
						/>
						<Section3
							loading={isSubmitButtonLoading}
							submitIdeas={handleSubmitIdeas}
							authorIdeation={authorIdeation}
							setAuthorIdeation={setAuthorIdeation}
							viewSubmittedIdeas={viewSubmittedIdeas}
							submitIdeasDisabled={submitIdeasDisabled}
							cautionText={
								game?.gameTitle || game?.endTime
									? brainstormText.global.alternateWarningText
									: brainstormText.global.warningText
							}
						/>
					</>
				)}
			</Container>
		</Box>
	)
}

export default SimonSays
