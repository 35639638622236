import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat.js'

dayjs.extend(advancedFormat)

export function formatDate(date: string) {
	return dayjs(new Date(date)).format('MMMM Do, h:mma')
}

export function formatAsUSADateTime(date: string) {
	return dayjs(new Date(date)).format('MM/DD/YYYY h:mma')
}
