export const styles = {
	error: {
		border: 'none',
		backgroundColor: '#F44336',
		padding: '14px 16px',
		'.MuiAlert-message': {
			paddingTop: '0',
			paddingBottom: '0',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
		},
		'.MuiAlert-message > *': {
			color: 'white',
			fontSize: '14px',
			marginBottom: '0',
		},
		'.MuiAlert-icon > *': {
			color: 'white',
		},
		'.MuiAlert-action': {
			display: 'none',
		},
	},
	success: {
		border: 'none',
		backgroundColor: '#4CAF50',
		padding: '14px 16px',
		'.MuiAlert-message': {
			paddingTop: '0',
			paddingBottom: '0',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
		},
		'.MuiAlert-message > *': {
			color: 'white',
			fontSize: '14px',
			marginBottom: '0',
		},
		'.MuiAlert-icon > *': {
			color: 'white',
		},
		'.MuiAlert-action': {
			display: 'none',
		},
	},
	icon: {
		fontSize: 24,
		padding: 'none',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
}
