import React from 'react'

import { Grid } from '@mui/material'
import { formatAsUSADateTime } from 'utils/formatDate'

import { Game } from 'models/gameModels'

export interface Props {
	game: Game
}

const CustomGameTitleBar: React.FC<Props> = ({ game }) => {
	return (
		<Grid
			data-testid={'custom-game-title-bar'}
			container
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			columnSpacing={2}
			sx={{
				fontSize: 16,
				color: 'black',
			}}
		>
			{game.gameTitle && (
				<Grid
					item
					xs="auto"
					sx={{
						p: 0,
					}}
				>
					{game.gameTitle}
				</Grid>
			)}
			<Grid item xs="auto" sx={{ borderRight: '1px solid black' }}>
				&nbsp;
			</Grid>
			{game.endTime && (
				<Grid item xs="auto">
					Submission Deadline:&nbsp;
					{formatAsUSADateTime(game.endTime)}
				</Grid>
			)}
		</Grid>
	)
}

export default CustomGameTitleBar
