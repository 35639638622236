import { memo } from 'react'

import BusinessTopicChips from './BusinessTopicChips'
import type { Props as BusinessTopicChipsProps } from './BusinessTopicChips'

const BusinessTopicChipsComponent = memo(BusinessTopicChips)

export { BusinessTopicChipsProps }

export default BusinessTopicChipsComponent
