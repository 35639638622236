import { useCallback, useEffect, useMemo, useState } from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import {
	Box,
	Checkbox,
	Chip,
	Divider,
	IconButton,
	Typography,
} from '@mui/material'
import { formatDate } from 'utils/formatDate'

import { Idea } from 'models/ideaModels'

import { MODAL_TYPES, useModalContext } from 'contexts/ModalProvider'

import { FavoriteIconGradient } from 'components/Icon/FavoriteIconGradient'
import PrototypeSubmittedIdeaModal from 'components/PrototypeSubmittedIdeaModal'
import useDialog from 'components/PrototypeSubmittedIdeaModal/useDialogHook'
import TextAndIconButton from 'components/TextAndIconButton'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import { styles } from './IdeaRow.styles'

export const TEST_ID = 'idea-row-'

export const generateFullTestId = ({ submittedId }: { submittedId?: string }) =>
	`${TEST_ID}${submittedId || ''}`

export type Props = {
	deleteIdea: (idea: string) => Promise<void>
	editIdea: (idea: Idea) => Promise<void>
	idea: Idea
	isLoading: boolean
	onSelect: (ideaId: string, isSelected: boolean) => void
}

const IdeaRow = ({
	idea,
	isLoading,
	editIdea,
	deleteIdea,
	onSelect,
}: Props) => {
	const [isLoadingIdea, setIsLoadingIdea] = useState(isLoading)
	const { open, handleOpen, handleClose } = useDialog()

	const toggleFavorite = useCallback(() => {
		setIsLoadingIdea(true)
		editIdea({ ...idea, isFavorite: !idea.isFavorite })
	}, [idea, editIdea])

	useEffect(() => {
		if (!isLoading) setIsLoadingIdea(false)
	}, [isLoading])

	const { showModal } = useModalContext()

	const handleEditButtonClick = () => {
		showModal(MODAL_TYPES.EDIT_SUBMITTED_IDEA_MODAL, { idea, editIdea })
	}

	const handleDeleteButtonClick = () => {
		showModal(MODAL_TYPES.DELETE_SUBMITTED_IDEA_MODAL, { idea, deleteIdea })
	}

	const gameTypeBg = useMemo(() => {
		return Object.values(GAME_TYPE).find(
			(gameType) => gameType.label === idea.gameType
		)?.backgroundGradient
	}, [idea.gameType])

	const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onSelect(idea.submittedId, event.target.checked)
	}

	return (
		<Box data-testid="main__idea-row">
			<Divider flexItem sx={{ my: '4rem' }} />
			<Box
				flexDirection="column"
				sx={{ my: '4rem' }}
				data-testid={`${generateFullTestId(idea)}__container`}
			>
				<Box my={'2rem'} sx={styles.ideaRowHeader}>
					<Checkbox
						data-testid={`${generateFullTestId(idea)}__checkbox`}
						sx={styles.ideaCheckbox}
						onChange={handleSelectChange}
					/>
					<Typography
						variant="overline"
						color="secondary.light"
						fontSize={'1.2rem'}
						data-testid={`${generateFullTestId(idea)}__head-right-date`}
					>
						Submitted: {formatDate(idea.createdDate.toString())}
					</Typography>
					<Divider orientation="vertical" variant="middle" flexItem />
					<IconButton
						onClick={
							(!isLoadingIdea && toggleFavorite) || undefined
						}
					>
						{idea.isFavorite ? (
							<FavoriteIconGradient
								fontSize="inherit"
								data-testid={`${generateFullTestId(idea)}__favorite-icon-filled`}
							/>
						) : (
							<FavoriteBorderIcon
								fontSize="inherit"
								sx={styles.actionIcon}
								data-testid={`${generateFullTestId(idea)}__favorite-icon`}
							/>
						)}
					</IconButton>
					<Divider orientation="vertical" variant="middle" flexItem />
					<IconButton onClick={handleEditButtonClick}>
						<EditIcon
							fontSize="inherit"
							sx={styles.actionIcon}
							data-testid={`${generateFullTestId(idea)}__edit-icon`}
						/>
					</IconButton>
					<Divider orientation="vertical" variant="middle" flexItem />
					<IconButton onClick={handleDeleteButtonClick}>
						<DeleteIcon
							fontSize="inherit"
							sx={styles.actionIcon}
							data-testid={`${generateFullTestId(idea)}__delete-icon`}
						/>
					</IconButton>
					<Divider orientation="vertical" variant="middle" flexItem />
					<Box sx={styles.prototypeButton}>
						<TextAndIconButton
							icon={<ArrowForwardIcon />}
							color="secondary"
							text={'Test Your Ideas'}
							variant="text"
							handleClick={handleOpen}
							iconPosition="end"
						/>
					</Box>
					<PrototypeSubmittedIdeaModal
						idea={idea}
						open={open}
						handleClose={handleClose}
					/>
				</Box>
				<Box
					my={'2rem'}
					ml={'4rem'}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: '2rem',
					}}
				>
					<Chip
						size="small"
						label={`${idea.firstName} ${idea.lastName}`}
						sx={styles.basicChip}
					/>
					<Divider orientation="vertical" flexItem />
					<Chip
						size="small"
						label={idea.gameType}
						sx={{
							color: '#000000',
							background: gameTypeBg || '#EBEBEB',
						}}
						data-testid={`${generateFullTestId(idea)}__head-right-gametype`}
					/>
					<Chip
						size="small"
						label={idea.innovationCompany}
						sx={styles.basicChip}
					/>
					{idea.innovationTopic && (
						<Chip
							size="small"
							label={idea.innovationTopic}
							sx={styles.basicChip}
						/>
					)}
					{idea.innovationTopicManual && (
						<Chip
							size="small"
							label={idea.innovationTopicManual}
							sx={styles.basicChip}
						/>
					)}
				</Box>
				<Box ml={'4rem'}>
					<Typography
						variant={'body2'}
						color={'secondary.light'}
						dangerouslySetInnerHTML={{
							__html: idea.authorIdeation,
						}}
						data-testid={`${generateFullTestId(idea)}__body-text`}
					></Typography>
				</Box>
			</Box>
		</Box>
	)
}

export default IdeaRow
