/* istanbul ignore file */
import { memo } from 'react'

import BattleShipsPage, { TEST_ID } from './BattleShips'

const BattleShips = memo(BattleShipsPage)

export { TEST_ID }

export default BattleShips
