export const styles = {
	root: {
		marginBottom: '100px',
	},
	btnContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		gap: '32px',
		my: '40px',
	},

	TextContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
	},
	TextField: {
		color: '#000000',
		opacity: '0.38',
		fontWeight: 'regular',
		fontSize: '3.4rem',
		width: '75%',
	},

	GoHome: {
		fontWeight: 'medium',
		fontSize: '15px',
	},

	ContactAnAdmin: {
		fontWeight: 'medium',
		fontSize: '15px',
	},

	IconContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		pb: '2.5rem',
		pt: '5rem',
		img: {
			width: '75%',
			height: 'auto',
			objectFit: 'contain',
		},
	},
}
