export const styles = {
	progressContainer: {
		display: 'flex',
		width: '100%',
		height: '100vh',
		justifyContent: 'center',
		alignItems: 'center',
	},
	circularProgressIcon: {
		color: 'secondary.main',
		size: '2.8rem',
	},
}
