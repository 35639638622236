import React from 'react'

import CheckIcon from '@mui/icons-material/Check'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Alert, AlertTitle, Snackbar, Typography } from '@mui/material'

import { ToastContents } from 'contexts/ToastProvider'

import { alertTitleTextMap } from 'assets/alertText'

import { styles } from './ToastAlerts.styles'

const TOAST_DISPLAY_TIME = 6000 // 4 seconds

export const TEST_ID = 'toast-alerts'

export interface Props {
	handleCloseAlert: () => void
	open: boolean
}

const ToastAlerts = ({
	objectType,
	severity,
	title,
	bodyText,
	handleCloseAlert,
	open,
	gameName,
}: ToastContents & Props) => (
	<Snackbar
		anchorOrigin={{
			horizontal: 'center',
			vertical: 'bottom',
		}}
		autoHideDuration={TOAST_DISPLAY_TIME}
		onClose={handleCloseAlert}
		open={open}
	>
		<Alert
			iconMapping={{
				error: <WarningAmberIcon sx={styles.icon} />,
				success: <CheckIcon sx={styles.icon} />,
			}}
			onClose={handleCloseAlert}
			severity={severity}
			sx={styles[severity]}
		>
			{/* Toast title */}
			{title ? (
				<AlertTitle data-testid={`${TEST_ID}-toast-title`}>
					{title}
				</AlertTitle>
			) : (
				<AlertTitle data-testid={`${TEST_ID}-${severity}-title`}>
					{gameName ? `"${gameName}" ` : ''}
					{objectType === null || objectType === undefined
						? alertTitleTextMap.defaultError.title
						: alertTitleTextMap[objectType!].title}
				</AlertTitle>
			)}

			{/* Toast body text */}
			{bodyText ? (
				<Typography data-testid={`${TEST_ID}-toast-body`}>
					{bodyText}
				</Typography>
			) : (
				<Typography data-testid={`${TEST_ID}-${severity}-body`}>
					{objectType === null || objectType === undefined
						? alertTitleTextMap.defaultError.text
						: alertTitleTextMap[objectType!].text}
				</Typography>
			)}
		</Alert>
	</Snackbar>
)

export default ToastAlerts
