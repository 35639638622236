import { Typography } from '@mui/material'

import { styles } from '../InfoPopper.styles'

type BulletItem = {
	details: string
	title: string
}

type Props = {
	bulletItems: BulletItem[]
}

export default function BulletedList({ bulletItems }: Props) {
	return (
		<Typography component="div">
			<Typography sx={styles.popperFont}>
				<ul>
					{bulletItems.map((item, index) => (
						<li key={index}>
							<b>{item.title}</b>: {item.details}
						</li>
					))}
				</ul>
			</Typography>
		</Typography>
	)
}
