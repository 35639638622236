/*
    Source: ./ArcLogo.svg 
    Optimized by https://react-svgr.com/
*/
import React from 'react'

type ArcLabsLogoType = {
	height: string
	viewBox: string
	width: string
}

export const ArcLabsLogo = (props: ArcLabsLogoType) => (
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 100 100"
		preserveAspectRatio="xMidYMid meet"
		fill="currentColor"
	>
		<path
			d="M30.433 15.893h1.069v11.582h-1.07V15.893ZM40.964 19.112h1.049v8.353h-1.05V26.03c-.909 1.103-2.058 1.634-3.357 1.634-2.428 0-4.266-2.025-4.266-4.402 0-2.376 1.878-4.372 4.296-4.372 1.35 0 2.488.582 3.328 1.745v-1.524Zm-6.525 4.182c0 1.804 1.409 3.399 3.277 3.399 1.709 0 3.328-1.294 3.328-3.37 0-1.935-1.44-3.399-3.308-3.399-1.868 0-3.287 1.575-3.287 3.37h-.01ZM45.58 27.465h-1.07V15.883h1.07v4.653c.91-1.103 2.038-1.655 3.337-1.655 2.428 0 4.277 2.026 4.277 4.402 0 2.377-1.879 4.392-4.307 4.392-1.349 0-2.458-.601-3.307-1.764v1.554Zm6.525-4.202c0-1.805-1.43-3.399-3.288-3.399-1.708 0-3.307 1.274-3.307 3.37 0 1.935 1.429 3.409 3.307 3.409 1.879 0 3.288-1.585 3.288-3.38ZM55.013 25.72c.529.602 1.108.933 1.738.933.82 0 1.539-.602 1.539-1.384 0-.491-.28-.943-.69-1.163-1.908-1.073-2.887-1.524-2.887-2.988 0-1.324 1.019-2.216 2.288-2.216.91 0 1.599.44 2.308 1.133l-.69.712c-.5-.492-.999-.803-1.678-.803-.68 0-1.26.492-1.26 1.113 0 .422.27.893.64 1.114 1.909 1.113 2.978 1.544 2.978 3.048 0 1.303-1 2.466-2.508 2.466-.93 0-1.868-.42-2.458-1.193l.67-.772h.01ZM43.342 5.054V3.84h-1.899V14.27h1.899V9.015h.03a3.315 3.315 0 0 1 3.307-3.28c.81 0 1.549.291 2.128.783V4.292a5.198 5.198 0 0 0-2.128-.462 5.174 5.174 0 0 0-3.337 1.224ZM57.29 10.469a3.3 3.3 0 0 1-2.987 1.895 3.313 3.313 0 0 1-3.307-3.31v-.04A3.304 3.304 0 0 1 57.29 7.63h2.018c-.62-2.185-2.618-3.8-4.996-3.8a5.191 5.191 0 0 0-4.257 2.227 5.167 5.167 0 0 0-.939 2.958v.04a5.173 5.173 0 0 0 .95 2.988 5.19 5.19 0 0 0 4.256 2.226c2.378 0 4.386-1.614 5.006-3.8H57.29ZM35.638 3.83c-2.867 0-5.205 2.347-5.205 5.225 0 2.877 2.338 5.224 5.205 5.224 1.26 0 2.398-.471 3.308-1.223v1.223h1.898V9.345h-.03c0-.1.03-.19.03-.28 0-2.878-2.338-5.225-5.206-5.225v-.01Zm0 8.534a3.317 3.317 0 0 1-3.307-3.32 3.31 3.31 0 0 1 3.307-3.318 3.31 3.31 0 0 1 3.308 3.319 3.31 3.31 0 0 1-3.308 3.319Z"
			{...props}
		/>
		<path
			d="M16.134 2.908c2.718 0 5.246.852 7.324 2.306a13.377 13.377 0 0 1 2.878 2.748V3.85a15.872 15.872 0 0 0-2.878-1.965A15.576 15.576 0 0 0 16.134.06c-5.306 0-9.992 2.647-12.84 6.698V0H.418v31.616h2.878v-6.718c2.847 4.05 7.543 6.698 12.84 6.698 2.647 0 5.135-.662 7.323-1.825a15.711 15.711 0 0 0 2.878-1.965V15.853h-.01c0-2.757-1.09-5.254-2.868-7.1a10.136 10.136 0 0 0-7.334-3.138c-5.625 0-10.202 4.593-10.202 10.238 0 5.646 4.577 10.238 10.202 10.238 2.878 0 5.476-1.203 7.334-3.139v3.49a12.74 12.74 0 0 1-7.324 2.306c-6.824 0-12.42-5.354-12.84-12.093v-1.654c.43-6.738 6.026-12.093 12.84-12.093Zm0 20.325c-4.057 0-7.354-3.309-7.354-7.38 0-4.07 3.297-7.38 7.354-7.38s7.074 3.038 7.334 6.869v1.013c-.26 3.83-3.447 6.868-7.334 6.868v.01Z"
			{...props}
		/>
	</svg>
)
