import React from 'react'
import {
	Control,
	FieldValues,
	RegisterOptions,
	useController,
} from 'react-hook-form'

import isString from 'lodash/isString'

import BaseDropdown from 'components/Form/BaseDropdown'
import type { BaseDropdownProps } from 'components/Form/BaseDropdown'

export const TEST_ID = 'default-test-id-form-dropdown'

export type Props = {
	control: Control<FieldValues>
	'data-testid'?: string
	name: string
	rules?: RegisterOptions<FieldValues>
} & Omit<BaseDropdownProps, 'value' | 'ref'>

const FormDropdown = ({
	name,
	control,
	required,
	disabled = false,
	readOnly,
	rules = {},
	'data-testid': dataTestid,
	...props
}: Props) => {
	const {
		field: fieldProps,
		fieldState: { error, isTouched },
	} = useController({
		name,
		control,
		// rules,
		rules: {
			required: required
				? isString(required)
					? required
					: 'This field is required'
				: undefined,
			...rules,
		},
		disabled,
	})

	return (
		<BaseDropdown
			helperText={
				!readOnly && error && isTouched ? error.message : undefined
			}
			error={!readOnly && error && isTouched}
			readOnly={readOnly}
			{...fieldProps}
			{...props}
			data-testid={dataTestid || TEST_ID}
		/>
	)
}

export default FormDropdown
