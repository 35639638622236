/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, createContext, useContext, useReducer } from 'react'

export const SubmitIdeasContext = createContext({
	submitIdeas: false,
	submitIdeasClicked: false,
	// Unable to get any of the disable comments to work.
	// tsc seems to be happy to ignore unused, underscored params
	setSubmitIdeas: (_value: boolean) => {},
	setSubmitIdeasClicked: (_value: boolean) => {},
})
SubmitIdeasContext.displayName = 'SubmitIdeasContext'

export enum ActionType {
	SET_SUBMIT_IDEAS = 'SET_SUBMIT_IDEAS',
	SET_SUBMIT_IDEAS_CLICKED = 'SET_SUBMIT_IDEAS_CLICKED',
}

interface SubmitIdeasState {
	submitIdeas: boolean
	submitIdeasClicked: boolean
}

interface SubmitIdeasAction {
	type: ActionType
	value: boolean
}
export const submitIdeasReducer = (
	state: SubmitIdeasState,
	action: SubmitIdeasAction
) => {
	switch (action.type) {
		case ActionType.SET_SUBMIT_IDEAS:
			return {
				...state,
				submitIdeas: action.value,
			}
		case ActionType.SET_SUBMIT_IDEAS_CLICKED:
			return {
				...state,
				submitIdeasClicked: action.value,
			}
		default:
			return state
	}
}

const SubmitIdeasProvider = ({ children }: { children: ReactNode }) => {
	const [submitIdeasState, dispatch] = useReducer(submitIdeasReducer, {
		submitIdeas: false,
		submitIdeasClicked: false,
	})
	const setSubmitIdeas = (value: boolean) => {
		dispatch({ type: ActionType.SET_SUBMIT_IDEAS, value })
	}
	const setSubmitIdeasClicked = (value: boolean) => {
		dispatch({ type: ActionType.SET_SUBMIT_IDEAS_CLICKED, value })
	}
	return (
		<SubmitIdeasContext.Provider
			value={{
				...submitIdeasState,
				setSubmitIdeas,
				setSubmitIdeasClicked,
			}}
		>
			{children}
		</SubmitIdeasContext.Provider>
	)
}
export const useSubmitIdeasContext = () => useContext(SubmitIdeasContext)
export { SubmitIdeasProvider }
