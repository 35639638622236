import { memo } from 'react'

import SectionHeader from './SectionHeader'
import type { Props as SectionHeaderProps } from './SectionHeader'

const SectionHeaderComponent = memo(SectionHeader)

export { SectionHeaderProps }

export default SectionHeaderComponent
