import React from 'react'

import { createFeatureDecisions } from 'config/featureDecisions'

import ArcNavBarV1 from './v1'
import ArcNavBarV2 from './v2'

function ArcNavBar() {
	if (createFeatureDecisions().enableRedesignedGamePages()) {
		return <ArcNavBarV2 />
	} else {
		return <ArcNavBarV1 />
	}
}

export default ArcNavBar
