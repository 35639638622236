import { memo } from 'react'

import RichTextField from './RichTextField'
import type { Props as RichTextFieldProps } from './RichTextField'

const RichTextFieldComponent = memo(RichTextField)

export { RichTextFieldProps }

export default RichTextFieldComponent
