export interface PromptBoxGameData {
	background: string
	title: string
}

export interface PromptBoxGameDataCollection {
	[key: number]: PromptBoxGameData
}

export const PromptBoxData: PromptBoxGameDataCollection = {
	1: {
		title: 'Simon Says',
		background: '#EDE7F6CC',
	},
	3: {
		title: 'Battle Ships',
		background: '#FFE9C9',
	},
	4: {
		title: 'Dress Up',
		background: '#E7F8FF',
	},
	5: {
		title: 'Leap Frog',
		background: '#E4F4D8',
	},
	6: {
		title: 'Round Robin',
		background: '#FFE8EE',
	},
}
