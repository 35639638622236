import React, { useEffect, useState } from 'react'

import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import logger from 'utils/logger'

import { ConnectFourModel } from 'models/ConnectFourModel'
import { Game } from 'models/gameModels'

import { useToastContext } from 'contexts/ToastProvider'

import { postConnectFourResponse } from 'services/completions.service'
import { getRandomPrompts, postIdea } from 'services/ideas.service'

import BreadcrumbComponent from 'components/BreadcrumbMenu'
import ButtonWithIconComponent from 'components/ButtonWithIcon'
import { BaseTextField } from 'components/Form'
import RichTextField from 'components/Form/RichTextField/RichTextField'
import InformationModal from 'components/InformationModal/InformationModal'
import PromptBox from 'components/PromptBox/PromptBox'
import RectangleButtonComponent from 'components/RectangleButton'
import SectionHeader from 'components/SectionHeader'
import SubmitIdeasButton from 'components/SubmitIdeasButton'

import { defaultErrorMessage } from 'assets/alertText'
import { brainstormText } from 'assets/brainstormText'
import { GenAiPromptText } from 'assets/images/GenAiPromptText'
import hideIcon from 'assets/images/hideIcon.svg'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { styles } from './ConnectFour.styles'

const userMessage = {
	content: '',
	role: 'user',
}

export const TEST_ID = 'connect-four'

const ConnectFour = ({ game }: { game?: Game }) => {
	const {
		setCompanyName,
		setCompanyProblem,
		currentPrompts,
		authorIdeation,
		setAuthorIdeation,
		responseConversation,
		setResponseConversation,
		currentResponses,
		setCurrentResponses,
		generatePromptsDisabled,
		generateNewPromptsDisabled,
		setPromptResponse,
		responseToJSON,
		buttonDisabled,
		gameToJSON,
		reset,
		aiButtonDisabled,
		resetSoft,
		setGameId,
		getRandomPromptsQueryObj,
		setPreviousPrompts,
	} = ConnectFourModel()

	useEffect(() => {
		if (game) {
			setCompanyName(game.innovationCompany)
			setCompanyProblem(game.innovationTopic)
			setGameId(game.gameId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game])

	const { showAlert } = useToastContext()
	const theme = useTheme()
	const platform = useMediaQuery(theme.breakpoints.up('sm'))
		? 'Desktop'
		: 'Mobile'

	const [isLoading, setIsLoading] = useState(false)
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)
	const [isAiLoading, setIsAiLoading] = useState(false)
	const [aiExampleVisible, setAiExampleVisible] = useState(false)

	const handleSubmitIdeas = async () => {
		setIsSubmitButtonLoading(true)
		setIsLoading(true)

		try {
			await postIdea(gameToJSON())

			if (showAlert) {
				showAlert({ severity: ToastSeverity.SUCCESS })
			}
		} catch (error) {
			logger.error(error)
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsLoading(false)
			setIsSubmitButtonLoading(false)

			// need to reset the model
			reset()

			window.scrollTo({
				top: 0,
			})
			await handleRandomPrompts()
		}
	}

	const handleRandomPrompts = async () => {
		try {
			resetSoft()
			setAiExampleVisible(false)
			setIsLoading(true)

			const response = await getRandomPrompts(getRandomPromptsQueryObj())

			if (response.status === 200 && response.data?.prompts) {
				handleAiResponseClick({
					currentPrompt: response.data?.prompts.join(', '),
					isUserInitiated: false,
				})
				if (response.data?.resetPreviousPrompts) {
					setPreviousPrompts([])
				}
				setPromptResponse(response.data.prompts)
			} else {
				throw Error(`response received ${response.data}`)
			}
		} catch (error) {
			logger.error(error)

			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsLoading(false)
		}
	}

	const updateAIResponse = (content: string) => {
		return {
			content,
			role: 'assistant',
		}
	}

	const buildCurrentResponse = (
		response: Record<string, { ideas: string[]; key: string }[]>
	) => {
		// create a json object from the response
		const items: { ideas: string[]; title: string }[] = []

		response.responses.forEach((entry) => {
			const item = {
				title: entry.key,
				ideas: [...entry.ideas],
			}

			items.push(item)
		})

		return items
	}
	const handleAiResponseClick = async ({
		currentPrompt = '',
		isUserInitiated = true,
	} = {}) => {
		try {
			if (!aiButtonDisabled() && !isLoading && !isAiLoading) {
				if (isUserInitiated && !aiExampleVisible) {
					setAiExampleVisible(true)
					return
				}

				setIsAiLoading(true)

				// setup the conversation
				const prompt = responseToJSON()
				if (!isUserInitiated) {
					prompt.conversation = [userMessage]
				} else {
					prompt.conversation = [...responseConversation, userMessage]
				}

				if (currentPrompt) {
					prompt.currentPrompt = currentPrompt
				}

				const response = await postConnectFourResponse(prompt)

				if (response.status === 200 && response.data) {
					const msg = JSON.stringify(response.data)

					// add response to state
					if (!isUserInitiated) {
						setResponseConversation([
							userMessage,
							updateAIResponse(msg),
						])
					} else {
						setResponseConversation((prevMessages) => [
							...prevMessages,
							userMessage,
							updateAIResponse(msg),
						])
					}

					setCurrentResponses(buildCurrentResponse(response.data))
				} else {
					throw Error(`response received ${response.data}`)
				}
			}
		} catch (error) {
			logger.error(error)
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsAiLoading(false)
		}
	}

	const handleUserResponse = async (user: string) => {
		setAuthorIdeation(user)
	}

	const clearAiResponse = async () => {
		setCurrentResponses([])
	}

	const buildResponseItem = (
		solution: { ideas: string[]; title: string }[]
	) => {
		return (
			<ul data-testid="gen-ai-response-list">
				{solution.map((el, i) => (
					<React.Fragment key={`ai-solution-${i}-list-container`}>
						<Typography
							component="li"
							key={`ai-solution-${i}`}
							sx={styles.solutionText}
							variant="body1"
						>
							{el.title}
						</Typography>
						<ol data-testid="gen-ai-sub-response-list">
							{el.ideas.map((subel, x) => (
								<Typography
									component="li"
									key={`ai-sub-solution-${x}`}
									sx={styles.solutionText}
									variant="body1"
								>
									{subel}
								</Typography>
							))}
						</ol>
					</React.Fragment>
				))}
			</ul>
		)
	}

	const handleCompanyNameChange = (value: string) => {
		setCompanyName(value)
		reset()
	}

	const handleCompanyProblemChange = (value: string) => {
		setCompanyProblem(value)
		reset()
	}

	return (
		<Box>
			<BreadcrumbComponent
				gameDescription="for problem statement responses"
				gameTypeEnum={GAME_TYPE.CONNECT_FOUR}
				game={game}
			/>
			<Container>
				<Box data-testid={`${TEST_ID}__container`}>
					<Grid container mt={2} mb={5}>
						<Grid item data-testid={`${TEST_ID}__header-1`}>
							<SectionHeader
								number={'1'}
								title={'Innovation Topic'}
							>
								{brainstormText.connectFour.ideationOne}
							</SectionHeader>
						</Grid>
						<Grid
							data-testid={`${TEST_ID}__topic`}
							container
							item
							flexDirection="column"
							sx={{
								flexDirection: { xs: 'column', sm: 'row' },
							}}
							rowSpacing={3}
						>
							<Grid
								container
								item
								justifyContent="space-evenly"
								sm={5}
								lg={4}
							>
								<Grid item xs={10} sm={9}>
									<BaseTextField
										sx={{ width: '100%' }}
										onChangeValue={handleCompanyNameChange}
										data-testid={`${TEST_ID}__company-name-input-field`}
										label="Enter Company Name Here"
										value={
											game
												? game.innovationCompany
												: undefined
										}
										readOnly={!!game}
										required
									/>
								</Grid>
								<Grid
									container
									item
									xs={2}
									justifyContent="center"
								>
									<Typography
										sx={styles.sectionText}
										variant="body2"
									>
										and
									</Typography>
								</Grid>
							</Grid>
							<Grid container item sm={7} lg={6}>
								<Grid item xs={10}>
									<BaseTextField
										onChangeValue={
											handleCompanyProblemChange
										}
										data-testid={`${TEST_ID}__company-problem-input-field`}
										label="Enter Brief Company Problem Description"
										value={
											game
												? game.innovationTopic
												: undefined
										}
										readOnly={!!game}
										required
										helperText="summarize the problem in a 5-10 word phrase"
									/>
								</Grid>
							</Grid>

							<Grid item>
								<RectangleButtonComponent
									disabled={
										generatePromptsDisabled() || isLoading
									}
									text={'Generate Prompts'}
									data-testid={`${TEST_ID}__topic-button`}
									color="secondary"
									onClickAction={handleRandomPrompts}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item sx={styles.section}>
						<Box sx={styles.randomPromptContainer}>
							<SectionHeader
								data-testid={`${TEST_ID}__header-2`}
								number={'2'}
								title={'Random Prompt Inspiration'}
							>
								{brainstormText.connectFour.ideationTwo}
							</SectionHeader>
							<Box sx={styles.infoIconContainer}>
								<InformationModal defaultIcon={false} />
							</Box>
						</Box>
						<Box sx={styles.containerai}>
							{currentPrompts?.map((d, i) => (
								<Grid item key={`${TEST_ID}__prompt-box_${i}`}>
									<PromptBox
										contentText={
											d.length > 0
												? d
												: brainstormText.connectFour
														.generateScenario
										}
										data-testid={`${TEST_ID}__prompt-box_${i}`}
										loading={isLoading}
									/>
								</Grid>
							))}
						</Box>
						<Box>
							<ButtonWithIconComponent
								disabled={
									generateNewPromptsDisabled() || isLoading
								}
								iconSvg={refreshIcon}
								text="New Random Prompts"
								onClickAction={handleRandomPrompts}
								data-testid={`${TEST_ID}__prompt-box_main-refresh`}
							/>
						</Box>
					</Grid>
					<Grid
						data-testid={`${TEST_ID}__ideation-container`}
						item
						container
						sx={{
							width: '100%',
						}}
					>
						<Grid item xs={12} data-testid={`${TEST_ID}__header-3`}>
							<SectionHeader number={'3'} title={'Ideation'}>
								{brainstormText.connectFour.ideationThree}
							</SectionHeader>
						</Grid>
						<Grid
							item
							container
							xs={12}
							sx={{
								flexDirection: {
									xs: 'column',
									sm: 'row',
								},
							}}
						>
							<Grid
								container
								spacing={{
									xs: 1,
									lg: 4,
									md: 4,
									sm: 4,
								}}
							>
								<Grid
									item
									md={6}
									sx={{
										...styles.userResponseInput,
										height:
											platform === 'Mobile'
												? '27rem'
												: null,
									}}
									order={{ sm: 2, xs: 2, md: 1, lg: 1 }}
								>
									<RichTextField
										value={authorIdeation}
										onChange={handleUserResponse}
									/>
								</Grid>
								<Grid
									item
									container
									md={6}
									order={{ xs: 1, sm: 1, md: 2, lg: 2 }}
								>
									<Grid item xs={6}>
										<Box sx={{ width: '18rem' }}>
											<ButtonWithIconComponent
												data-testid={`${TEST_ID}__ai-response-refresh`}
												disabled={
													aiButtonDisabled() ||
													isLoading ||
													isAiLoading
												}
												iconSvg={refreshIcon}
												text="New AI Response"
												onClickAction={
													handleAiResponseClick
												}
											/>
										</Box>
									</Grid>
									{aiExampleVisible &&
									currentResponses &&
									currentResponses.length > 0 ? (
										<Grid item xs={6}>
											<Box
												display="flex"
												justifyContent="flex-end"
											>
												<ButtonWithIconComponent
													disabled={
														aiButtonDisabled() ||
														isLoading ||
														isAiLoading
													}
													iconSvg={hideIcon}
													text="Clear AI Response"
													onClickAction={
														clearAiResponse
													}
												/>
											</Box>
										</Grid>
									) : null}
									<Grid
										item
										lg={12}
										md={12}
										data-testid="gen-ai-response-container"
										sx={{
											...styles.genAiResponseContainer,
											width: '100%',
										}}
									>
										{aiExampleVisible && isAiLoading ? (
											<Box
												sx={
													styles.genAiUnSelectableContent
												}
											>
												<CircularProgress color="secondary" />
											</Box>
										) : (
											<>
												{!aiExampleVisible ||
												currentResponses ===
													undefined ||
												currentResponses.length ===
													0 ? (
													<>
														{
															<Box
																data-testid="gen-ai-preview-content"
																sx={
																	styles.genAiPreviewContent
																}
																onClick={() =>
																	handleAiResponseClick()
																}
															>
																<Box
																	data-testid="generate-response-prompt"
																	sx={
																		styles.genAiPreviewImg
																	}
																>
																	<GenAiPromptText />
																	<Typography
																		sx={
																			styles.genAiPreviewText
																		}
																		variant="body1"
																	>
																		Generate
																		example
																		scenario
																		response
																	</Typography>
																</Box>
															</Box>
														}
													</>
												) : (
													<Box
														component="ol"
														sx={
															styles.genAiResponseContent
														}
													>
														{buildResponseItem(
															currentResponses
														)}
													</Box>
												)}
											</>
										)}
									</Grid>
								</Grid>
								<Grid
									data-testid={`${TEST_ID}__submit-container`}
									item
									order={3}
									sx={{
										width:
											platform === 'Mobile'
												? '100%'
												: '50%',
									}}
								>
									<Grid sx={styles.submitContainer}>
										<Box>
											<SubmitIdeasButton
												disabled={
													buttonDisabled() ||
													isLoading ||
													isAiLoading
												}
												data-testid={`${TEST_ID}__submit-button`}
												handleSubmit={handleSubmitIdeas}
												sx={styles.submitButton}
												isSubmitButtonLoading={
													isSubmitButtonLoading
												}
												isCustomGame={!!game}
												gameTypeId={
													GAME_TYPE.CONNECT_FOUR.value
												}
											/>
										</Box>
										<Box>
											<Typography
												sx={styles.sectionText}
												variant="body2"
											>
												{
													brainstormText.slotMachine
														.submittedIdeas
												}
											</Typography>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</Box>
	)
}

export default ConnectFour
