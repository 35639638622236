import { useState } from 'react'

import { brainstormText } from 'assets/brainstormText'

import { GAME_TYPE } from 'enums/GameTypeEnum'

export type Conversation = {
	content: string
	role: string
}

export function BattleShipModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string[]>(['', '', ''])
	const [companyName, setCompanyName] = useState<string>('')
	const [companyProblem, setCompanyProblem] = useState<string>('')
	const [currentPrompt, setCurrentPrompt] = useState<string>('')
	const [currentResponses, setCurrentResponses] = useState<string[][]>([
		[],
		[],
		[],
	])
	const [gameId, setGameId] = useState<string | null>(null)
	const [promptConversation, setPromptConversation] = useState<
		Conversation[]
	>([])
	const [responseConversation, setResponseConversation] = useState<
		Conversation[][]
	>([[], [], []])
	const [termsAgreed, setTermsAgreed] = useState(false)
	const [topic, setTopic] = useState<string>('')

	const gameTypeId = GAME_TYPE.BATTLE_SHIPS.value

	const promptObject = () => {
		return {
			gameTypeId,
			gameScenario: 'prompt',
			companyName,
			topic,
			companyProblem,
			conversation: promptConversation,
		}
	}

	const generateAiDisabled = () => {
		if (
			generateScenarioDisabled() ||
			currentPrompt === '' ||
			currentPrompt.length === 0
		)
			return true
		else return false
	}

	const generateScenarioDisabled = () => !companyName.length || !topic

	const generateNewScenarioDisabled = () =>
		generateScenarioDisabled() || !currentPrompt

	const generateExampleDisabled = (i: number) => {
		if (i === 1 && !currentResponses[0]) return true
		if (i === 2 && !currentResponses[1]) return true
		return false
	}

	const resetSoft = () => {
		setCurrentPrompt('')
		setResponseConversation([[], [], []])
		setAuthorIdeation(['', '', ''])
		setCurrentResponses([[], [], []])
	}

	const reset = () => {
		resetSoft()
		setPromptConversation([])
	}

	const requestObject = (i: number) => {
		return generateRequest(
			i,
			gameTypeId,
			companyName,
			topic,
			currentPrompt,
			responseConversation,
			currentResponses
		)
	}

	const returnIdeation = () => {
		let returnString = ''

		authorIdeation.forEach((ideation, idx) => {
			if (ideation.length > 0) {
				returnString += returnString.length > 0 ? '<br>' : ''
				returnString += `<b><i>${brainstormText.battleShips.ideationTitles[idx]}</i></b>`
				returnString += `\n${ideation}`
			}
		})
		return returnString
	}

	const postObject = () => {
		return {
			gameId: gameId ?? undefined,
			gameTypeId,
			innovationTopic: topic,
			innovationCompany: companyName,
			authorIdeation: returnIdeation(),
		}
	}

	const submitIdeasDisabled = () => {
		if (
			generateScenarioDisabled() ||
			authorIdeation.some(
				(solution) => solution === null || solution.length === 0
			) ||
			(gameId && !termsAgreed)
		)
			return true
		else return false
	}

	return {
		companyName,
		setCompanyName,
		topic,
		setTopic,
		companyProblem,
		setCompanyProblem,
		promptConversation,
		setPromptConversation,
		currentPrompt,
		setCurrentPrompt,
		responseConversation,
		setResponseConversation,
		currentResponses,
		setCurrentResponses,
		authorIdeation,
		setAuthorIdeation,
		generateScenarioDisabled,
		generateNewScenarioDisabled,
		promptObject,
		resetSoft,
		requestObject,
		postObject,
		submitIdeasDisabled,
		generateAiDisabled,
		reset,
		gameId,
		setGameId,
		generateExampleDisabled,
		termsAgreed,
		setTermsAgreed,
	}
}

export const generateRequest = (
	i: number,
	gameTypeId: number,
	companyName: string,
	topic: string,
	currentPrompt: string,
	responseConversation: Conversation[][],
	currentResponses: string[][]
) => {
	let gameScenario = 'response'
	if (i === 1) gameScenario = 'secondResponse'
	else if (i === 2) gameScenario = 'thirdResponse'

	const req = {
		gameTypeId,
		gameScenario,
		companyName,
		topic,
		companyDisruption: currentPrompt,
		conversation: responseConversation[i] ?? [],
		aiScenarioResponse: '',
	}

	if (i === 2) {
		req.aiScenarioResponse = currentResponses[1]?.join(' ') || ''
	} else {
		// delete req.aiScenarioResponse
		req.aiScenarioResponse = ''
	}

	return req
}
