import { memo } from 'react'

import IdeaRow, { TEST_ID, generateFullTestId } from './IdeaRow'
import type { Props as IdeaRowProps } from './IdeaRow'

const IdeaRowComponent = memo(IdeaRow)

export { TEST_ID, generateFullTestId, IdeaRowProps }

export default IdeaRowComponent
