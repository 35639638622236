// File: /components/SpeechToTextButton.tsx
import { Editor } from '@tiptap/react'
import React from 'react'
import SpeechRecognition, {
	useSpeechRecognition,
} from 'react-speech-recognition'

import classNames from 'classnames'

import AudioTranscriptionIcon from 'assets/images/AudioTranscription.svg'
import AudioTranscriptionRecordingIcon from 'assets/images/AudioTranscriptionRecording.svg'

import './styles.css'

export interface Props {
	editor: Editor | null
}

const AudioTranscriptionButton: React.FC<Props> = ({ editor }) => {
	const { transcript, resetTranscript } = useSpeechRecognition()
	const [isTranscribing, setTranscribing] = React.useState(false)

	if (
		!SpeechRecognition.browserSupportsSpeechRecognition ||
		editor === null
	) {
		return (
			<span data-testid="audio-transcription-not-supported">
				Browser does not support speech recognition.
			</span>
		)
	}

	const handleListen = () => {
		if (isTranscribing) {
			SpeechRecognition.stopListening()
			setTranscribing(false)
			if (editor) {
				editor.chain().focus().insertContent(transcript).run()
				resetTranscript()
			}
		} else {
			resetTranscript()
			setTranscribing(true)
			SpeechRecognition.startListening()
		}
	}

	return (
		<div className="audio-transcription-wrapper">
			<button
				className={classNames('menu-button', {
					'is-active': isTranscribing,
				})}
				onClick={handleListen}
				aria-label="audio-transcription-icon"
				data-testid="audio-transcription-icon"
			>
				{isTranscribing ? (
					<div>
						<img
							src={
								AudioTranscriptionRecordingIcon as unknown as string
							}
							alt="transcription icon on"
						/>
					</div>
				) : (
					<img
						src={AudioTranscriptionIcon as unknown as string}
						alt="transcription icon off"
					/>
				)}
			</button>
			{isTranscribing && (
				<span className={`transcribing-text`}>Transcribing...</span>
			)}
		</div>
	)
}

export default AudioTranscriptionButton
